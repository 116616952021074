import { Dispatch } from 'react';
import { Label, Price } from '../../components/wrld/form/types';

export type FormLocationAddress = {
  street: string,
  streetNo: string,
  city: string,
  zip: string,
  countryCode: string,
};

export type FormLocationContact = {
  company: string,
  name: string,
  phone: string,
  email: string,
};

export type FormSavedLocation = {
  id: string,
  meta?: { // pro rekapitulaci
    city: string,
    countryCode: string,
  }
};

export enum FormUnitItemType {
  BOX = 'box',
  PALLET = 'pallet',
  EUR_PALLET = 'eur_pallet',
}

export type FormUnitItem = {
  type: FormUnitItemType,
  weight?: number,
  dimensions: {
    length?: number,
    width?: number,
    height?: number,
  },
  count: number,
  stackable: boolean,
};

export type FormCODService = {
  amount?: number,
  currency?: string,
  vs?: string,
  price: Price,
};

export type FormDeliveryNoteService = {
  price: Price,
};

export type FormFloorDeliveryService = {
  price: Price,
};

export type FormInsuranceService = {
  amount?: number,
  currency?: string,
  price: Price,
};

export type FormUnitCargo = {
  units: FormUnitItem[],
  services: {
    cashOnDelivery?: FormCODService,
    deliveryNote?: FormDeliveryNoteService,
    floorDelivery?: FormFloorDeliveryService,
    insurance?: FormInsuranceService,
  },
};

export type FormLtfCargo = {
  // to be defined
};

export type FormLtlCargo = {
  // to be defined
};

export type FormProvider = {
  token: string,
  name: string,
  price: Price,
};

export type FormLocationBase = {
  id?: string,
  address: FormLocationAddress,
  contact: FormLocationContact,
};

export type LocationAccessibility = {
  forklift: boolean,
  tailgate: boolean,
};

export type FormLocation = FormLocationBase & LocationAccessibility;

export type ShipmentFormData = {
  pickup: {
    date?: Date,
    timeFrom?: Date,
    timeTo?: Date,
  },
  origin: null | FormSavedLocation | (FormLocation & { string: string }),
  destination: null | FormSavedLocation | FormLocation,
  cargo: null | FormUnitCargo | FormLtlCargo | FormLtfCargo,
  provider: null | FormProvider,
  // optional
  name?: string,
  labels?: string[],
  note?: string,
};

export type UseShipmentFormProps = {
  initialValues: ShipmentFormData,
  initialMeta: MetaFormData,
};

export type Error = {
  id: string,
  message: string,
};

export type Errors = Error[];

export type UseShipmentFormResult = {
  state: ShipmentFormData,
  dispatch: Dispatch<FormActions>,
  meta: MetaFormData,
  totalPrice: Price,
  errors: Errors,
  quoting: UnitQuoting | null,
};

export type UnitQuoting = {
  loading: boolean,
  providers?: QuotedUnitProvider[],
};

export type ActionType = 'SET_VALUE' | 'NULL_VALUE';

export type Action = {
  type: ActionType,
  payload: {
    key: string,
    field?: string,
    type?: string,
    value: unknown,
  },
};

/*
 * HOW TO ADD A TYPE FOR (FORM) REDUCER ACTION
 *
 * 1 ) create new const with an arbitrary name
 * 2 ) add input parameters (that go to the dispatch function) and return them "as const"
 * 2b) id is compulsory!
 * 3 ) expand type FormActions (below) with the new const
 * 4 ) add the new const to the switch statement in the reducer
 * 5 ) linter will now suggest correct types in each switch case
 *
 */

export const updatePickup = (
  date: Date,
  field: 'date' | 'timeFrom' | 'timeTo',
  type: 'SET_SINGLE_VALUE',
  id: string | null,
) => ({
  key: 'pickup',
  type,
  field,
  value: date as Date | undefined,
  id,
} as const);

export const updateAddress = (
  type: 'SET_VALUE',
  key: 'origin' | 'destination',
  payload: FormSavedLocation | FormLocation | null,
  id: string | null,
) => ({
  type,
  key,
  payload,
  id,
} as const);

export const updateCargo = (
  type: 'SET_VALUE',
  key: 'cargo',
  payload: FormUnitCargo | FormLtlCargo | FormLtfCargo | null,
  id: string | null,
) => ({
  type,
  key,
  payload,
  id,
} as const);

export const updateProvider = (
  type: 'SET_VALUE',
  key: 'provider',
  payload: FormProvider | null,
  id: string | null,
) => ({
  type,
  key,
  payload,
  id,
} as const);

export const updateUnitService = (
  type: 'SET_VALUE',
  key: 'unitServices',
  field: keyof FormUnitCargo['services'],
  id: string | null,
  payload?: FormUnitCargo['services'][keyof FormUnitCargo['services']],
) => ({
  type,
  key,
  field,
  payload,
  id,
} as const);

export const updateUnitServiceInsuranceField = (
  type: 'SET_VALUE',
  key: 'unitServicesField',
  service: 'insurance',
  field: keyof FormInsuranceService,
  payload: FormInsuranceService[keyof FormInsuranceService],
  id: string | null,
) => ({
  type,
  key,
  service,
  field,
  payload,
  id,
} as const);

export const updateUnitServiceCODField = (
  type: 'SET_VALUE',
  key: 'unitServicesField',
  service: 'cashOnDelivery',
  field: keyof FormCODService,
  payload: FormCODService[keyof FormCODService],
  id: string | null,
) => ({
  type,
  key,
  service,
  field,
  payload,
  id,
} as const);

export const updateSingleUnitCargo = (
  type: 'SET_UNIT_CARGO',
  key: 'cargo',
  payload: FormUnitItem,
  id: string | null,
) => ({
  type,
  key,
  payload,
  id,
} as const);

export const updateSingleUnitCargoField = (
  type: 'SET_UNIT_CARGO',
  key: 'cargo_item',
  field: keyof FormUnitItem,
  index: number,
  payload: number | boolean | FormUnitItemType,
  id: string | null,
) => ({
  type,
  key,
  field,
  index,
  payload,
  id,
} as const);

export const updateSingleUnitCargoFieldDimension = (
  type: 'SET_UNIT_CARGO',
  key: 'cargo_dimension',
  field: 'length' | 'width' | 'height',
  index: number,
  payload: number,
  id: string | null,
) => ({
  type,
  key,
  field,
  index,
  payload,
  id,
} as const);

export const duplicateCargoLine = (
  type: 'SET_UNIT_CARGO',
  key: 'cargo_duplicate',
  index: number,
  id: string | null,
) => ({
  type,
  key,
  index,
  id,
} as const);

export const removeCargoLine = (
  type: 'SET_UNIT_CARGO',
  key: 'cargo_remove',
  index: number,
  id: string | null,
) => ({
  type,
  key,
  index,
  id,
} as const);

export const updateAddressField = (
  type: 'SET_SINGLE_VALUE',
  key: 'origin' | 'destination',
  part: 'address' | 'contact',
  field: keyof FormLocationAddress | keyof FormLocationContact,
  payload: string | null,
  id: string | null,
) => ({
  type,
  key,
  part,
  field,
  payload,
  id,
} as const);

export const updateRootField = (
  type: 'SET_SINGLE_VALUE',
  key: 'root',
  field: keyof ShipmentFormData,
  payload: string | Label[],
  id: string | null,
) => ({
  type,
  key,
  field,
  payload,
  id,
} as const);

export const toggleSingleAccessibilityValue = (
  type: 'TOGGLE_SINGLE_ACCESSIBILITY_VALUE',
  address: 'origin' | 'destination',
  field: keyof LocationAccessibility,
  value: boolean,
  id: string | null,
) => ({
  value,
  type,
  address,
  field,
  id,
} as const);

export type FormActions = ReturnType<typeof updatePickup>
| ReturnType<typeof updateAddressField>
| ReturnType<typeof updateRootField>
| ReturnType<typeof updateCargo>
| ReturnType<typeof updateSingleUnitCargo>
| ReturnType<typeof updateSingleUnitCargoField>
| ReturnType<typeof removeCargoLine>
| ReturnType<typeof updateUnitService>
| ReturnType<typeof updateUnitServiceInsuranceField>
| ReturnType<typeof updateUnitServiceCODField>
| ReturnType<typeof updateProvider>
| ReturnType<typeof duplicateCargoLine>
| ReturnType<typeof updateSingleUnitCargoFieldDimension>
| ReturnType<typeof updateAddress>
| ReturnType<typeof toggleSingleAccessibilityValue>;

export type MetaFormData = {
  show: {
    providers: boolean,
    services: boolean,
    misc: boolean,
  },
  filled: {
    origin: boolean,
    pickup: boolean,
    destination: boolean,
    cargo: boolean,
    provider: boolean,
    services: boolean,
    misc: boolean,
  },
  progress: {
    width: number;
    message: string;
    hasError: boolean;
  }
};

export type QuotedUnitProvider = {
  name: string,
  price: Price,
  title: string,
  disabled: boolean,
};
