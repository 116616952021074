import React from 'react';

export type LabelsSegmentProps = {
  labels: string[],
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const LabelsSegment = (props: LabelsSegmentProps & { className?: string }): React.ReactElement => {
  const { labels, className } = props;
  return (
    <>
      {labels.map((label) => (
        <p
          key={label}
          className={classNames(
            className || '',
            'px-2 mr-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800',
          )}
        >
          {label}
        </p>
      ))}
    </>
  );
};

LabelsSegment.defaultProps = {
  className: '',
};

export default LabelsSegment;
