import React, { Dispatch, useState } from 'react';
import { ArrowLeftIcon, MapIcon, XIcon } from '@heroicons/react/outline';
import { RadioGroup } from '@headlessui/react';
import RadioSwitcher from './control/RadioSwitcher';
import LocationAutocompleteField from './control/LocationAutocompleteField';
import CountrySelectList from './control/CountrySelectList';
import Field from './control/Field';
import Accessibility from './address/Accessibility';
import {
  Errors, FormActions, FormLocation, ShipmentFormData,
} from '../../../hooks/forms/types.d.t';

type MyAddress = {
  id: string,
  name: string,
  contact: string,
  address?: {
    city: string,
    countryCode: string,
  }
};

const myAddresses: MyAddress[] = [
  {
    id: '1',
    name: 'Purkynova 127/8 Brno, CZE',
    contact: 'Petr Novak',
    address: {
      city: 'Brno',
      countryCode: 'CZE',
    },
  },
  {
    id: '2',
    name: 'Nerudova 538/2 Brno, CZE',
    contact: 'Jiri Novak',
    address: {
      city: 'Brno',
      countryCode: 'CZE',
    },
  },
  {
    id: '3',
    name: 'Nadrazni 1 Brno, CZE',
    contact: 'Tomas Zahradnik',
    address: {
      city: 'Brno',
      countryCode: 'CZE',
    },
  },
];

type FormAddressProps = {
  type: 'origin' | 'destination',
  dispatch: Dispatch<FormActions>,
  state: ShipmentFormData,
  errors: Errors,
  filterId?: string,
};

// TODO sjednotit types a data flow

const FormAddress = ({
  type, dispatch, filterId, state, errors,
}: FormAddressProps): React.ReactElement => {
  const [showCustomAddress, setShowCustomAddress] = useState(false);
  const [showManualAddress, setShowManualAddress] = useState(false);

  const defaultAddress: FormLocation = {
    address: {
      street: '',
      streetNo: '',
      city: '',
      zip: '',
      countryCode: '',
    },
    contact: {
      company: '',
      name: '',
      phone: '',
      email: '',
    },
    forklift: false,
    tailgate: false,
  };

  const handleOwnAddress = (): void => {
    // eslint-disable-next-line no-new
    new Promise(((resolve) => {
      setShowCustomAddress(true);
      dispatch({
        id: null,
        type: 'SET_VALUE',
        key: type,
        payload: defaultAddress,
      });
      resolve(null);
    })).then(() => {
      const element = document.getElementById(`${type}-address-autocomplete`);
      element?.focus();
    });
  };

  const handleBackToMyLocations = (): void => {
    setShowCustomAddress(false);
    dispatch({
      id: null, // TODO this might need to be handled (validated)
      type: 'SET_VALUE',
      key: type,
      payload: null,
    });
  };

  const selectedAddress = state[type] as FormLocation;

  return (
    <>
      {!showCustomAddress && (
        <div id="my-locations" className="col-span-6 md:col-span-3 space-y-2">
          <label htmlFor="my-locations" className="block text-sm font-medium text-gray-700">
            Vaše lokace
          </label>
          {myAddresses.length < 4 ? (
            <RadioSwitcher
              onChange={(option) => {
                const address = myAddresses.find((a) => a.id === option.id);
                dispatch({
                  id: null,
                  type: 'SET_VALUE',
                  key: type,
                  payload: {
                    id: option.id,
                    meta: {
                      city: address?.address?.city || '',
                      countryCode: address?.address?.countryCode || '',
                    },
                  },
                });
              }}
              wrapperClass="mt-4 grid grid-cols-1 gap-y-2"
              filterOptions={filterId ? (option) => option.id !== filterId : undefined}
              itemClass="hover:bg-gray-100 p-3 flex-grow block w-full min-w-0 border rounded-md sm:text-sm border-gray-300"
              options={myAddresses.map((address) => ({
                id: address.id,
                value: address.name,
                slot: (
                  <>
                    <RadioGroup.Label as="span" className="block text-sm font-semibold text-gray-900">
                      {address.name}
                      <span className="font-normal">
                        {' '}
                        - sklad
                      </span>
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {address.contact}
                      <span className="ml-2 text-xs text-gray-400">(+420 608 124 844, jan.novak@example.com)</span>
                    </RadioGroup.Description>
                  </>
                ),
              }))}
            />
          ) : null /* TODO pridat select na adresu vlastnich lokaci */ }
          <div
            role="button"
            tabIndex={0}
            className="cursor-pointer bg-gray-50 hover:bg-gray-200 p-3 flex-grow block w-full min-w-0 border rounded-md sm:text-sm border-gray-300"
            onClick={handleOwnAddress}
          >
            Jiná adresa
          </div>
        </div>
      )}

      {showCustomAddress && (
        <>
          <div id="custom-locations" className="col-span-6 md:col-span-3 space-y-2">
            <span role="button" tabIndex={0} onClick={handleBackToMyLocations} className="flex items-center">
              <ArrowLeftIcon className="inline h-4 w-4" />
              <div className="pl-2">Vaše lokace</div>
            </span>
            <label htmlFor="custom-locations" className="block text-sm font-medium text-gray-700">
              Jiná adresa
            </label>
            <div>
              <LocationAutocompleteField
                id={`${type}-address-autocomplete`}
                onChange={(value) => {
                  dispatch({
                    id: null,
                    type: 'SET_VALUE',
                    key: type,
                    payload: value as FormLocation,
                  });
                }}
              />
            </div>
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t" />
              </div>
              <div className="relative flex items-center justify-between">
                <span className="pr-3 bg-white text-lg font-medium text-gray-900" />
                <button
                  type="button"
                  className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setShowManualAddress((prev) => !prev)}
                >
                  Zadat adresu manuálně
                  {' '}
                  {showManualAddress ? <XIcon className="inline h-4 w-4 ml-2" />
                    : <MapIcon className="inline h-4 w-4 ml-2" />}
                </button>
              </div>
            </div>
            {showManualAddress && (
              <>
                <div className="pb-10">
                  <div className="col-span-6 grid grid-cols-6 gap-6">
                    <Field
                      errors={errors}
                      isRequired
                      wrapperClass="col-span-4"
                      label="Ulice"
                      name={`${type}.street`}
                      id={`${type}.street`}
                      autoComplete="street"
                      value={selectedAddress.address.street}
                      onChange={(value) => dispatch({
                        id: `${type}.street`,
                        type: 'SET_SINGLE_VALUE',
                        key: type,
                        part: 'address',
                        field: 'street',
                        payload: value,
                      })}
                    />

                    <Field
                      errors={errors}
                      isRequired
                      wrapperClass="col-span-2"
                      label="Číslo popisné"
                      name={`${type}.streetNo`}
                      id={`${type}.streetNo`}
                      autoComplete="streetNo"
                      value={selectedAddress.address.streetNo}
                      onChange={(value) => dispatch({
                        id: `${type}.streetNo`,
                        type: 'SET_SINGLE_VALUE',
                        key: type,
                        part: 'address',
                        field: 'streetNo',
                        payload: value,
                      })}
                    />

                    <Field
                      errors={errors}
                      isRequired
                      wrapperClass="col-span-6 sm:col-span-6 lg:col-span-2"
                      label="Město"
                      name={`${type}.city`}
                      id={`${type}.city`}
                      autoComplete="city"
                      value={selectedAddress.address.city}
                      onChange={(value) => dispatch({
                        id: `${type}.city`,
                        type: 'SET_SINGLE_VALUE',
                        key: type,
                        part: 'address',
                        field: 'city',
                        payload: value,
                      })}
                    />

                    <Field
                      errors={errors}
                      isRequired
                      wrapperClass="col-span-6 sm:col-span-3 lg:col-span-2"
                      label="PSČ"
                      name={`${type}.zip`}
                      id={`${type}.zip`}
                      autoComplete="zip"
                      value={selectedAddress.address.zip}
                      onChange={(value) => dispatch({
                        id: `${type}.zip`,
                        type: 'SET_SINGLE_VALUE',
                        key: type,
                        part: 'address',
                        field: 'zip',
                        payload: value,
                      })}
                    />

                    <CountrySelectList
                      errors={errors}
                      isRequired
                      wrapperClass="col-span-6 sm:col-span-3 lg:col-span-2"
                      label="Stát"
                      name={`${type}.countryCode`}
                      id={`${type}.countryCode`}
                      placeholder="Vyberte stát"
                      value={selectedAddress.address.countryCode}
                      onChange={(value) => dispatch({
                        id: `${type}.countryCode`,
                        type: 'SET_SINGLE_VALUE',
                        key: type,
                        part: 'address',
                        field: 'countryCode',
                        payload: value,
                      })}
                    />
                  </div>
                </div>
                <div className="pb-10 border-b">
                  <Accessibility
                    dispatch={dispatch}
                    state={selectedAddress}
                    type={type}
                  />
                </div>
              </>
            )}
            <div className="col-span-6 grid grid-cols-6 gap-6">
              <div className="col-span-6 grid grid-cols-2 gap-6">
                <Field
                  errors={errors}
                  wrapperClass="col-span-2 sm:col-span-1"
                  label="Firma"
                  name={`${type}.company`}
                  id={`${type}.company`}
                  autoComplete="company"
                  value={selectedAddress.contact.company}
                  onChange={(value) => dispatch({
                    id: `${type}.company`,
                    type: 'SET_SINGLE_VALUE',
                    key: type,
                    part: 'contact',
                    field: 'company',
                    payload: value,
                  })}
                />
              </div>

              <div className="col-span-6 grid grid-cols-2 gap-6">
                <Field
                  errors={errors}
                  isRequired
                  wrapperClass="col-span-2 sm:col-span-1"
                  label="Jméno a příjmení"
                  name={`${type}.name`}
                  id={`${type}.name`}
                  autoComplete="name"
                  value={selectedAddress.contact.name}
                  onChange={(value) => dispatch({
                    id: `${type}.name`,
                    type: 'SET_SINGLE_VALUE',
                    key: type,
                    part: 'contact',
                    field: 'name',
                    payload: value,
                  })}
                />
              </div>

              <Field
                errors={errors}
                isRequired
                wrapperClass="col-span-6 sm:col-span-3"
                label="Telefon"
                name={`${type}.phone`}
                id={`${type}.phone`}
                autoComplete="phone"
                value={selectedAddress.contact.phone}
                onChange={(value) => dispatch({
                  id: `${type}.phone`,
                  type: 'SET_SINGLE_VALUE',
                  key: type,
                  part: 'contact',
                  field: 'phone',
                  payload: value,
                })}
              />

              <Field
                errors={errors}
                isRequired
                wrapperClass="col-span-6 sm:col-span-3"
                label="E-mail"
                name={`${type}.email`}
                id={`${type}.email`}
                type="email"
                autoComplete="email"
                value={selectedAddress.contact.email}
                onChange={(value) => dispatch({
                  id: `${type}.email`,
                  type: 'SET_SINGLE_VALUE',
                  key: type,
                  part: 'contact',
                  field: 'email',
                  payload: value,
                })}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

FormAddress.defaultProps = {
  filterId: undefined,
};

export default FormAddress;
