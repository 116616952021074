import React from 'react';

import { format } from 'date-fns';
import { cs } from 'date-fns/locale';
import { CheckIcon, XIcon } from '@heroicons/react/solid';

import { Stop } from '../types/shipment';

type EquipmentLabelProps = {
  enabled: boolean,
};

const EquipmentLabel = ({
  children, enabled,
}: React.PropsWithChildren<EquipmentLabelProps>): React.ReactElement => ((enabled) ? (
  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-50 text-green-600">
    <CheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600" aria-hidden="true" />
    {children}
  </span>
) : (
  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
    <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-800" aria-hidden="true" />
    {children}
  </span>
));

export type ShipmentLocationSectionProps = {
  stop?: Stop | null,
  variant: 'pickup' | 'delivery',
};

const ShipmentLocationSection = ({
  stop, variant,
}: ShipmentLocationSectionProps): React.ReactElement => {
  const displayAddress = stop ? `
  ${stop?.location.address.street} ${stop?.location.address.streetNo},
  ${stop?.location.address.postal} ${stop?.location.address.city}, 
  ${stop?.location.address.country}` : undefined;

  const at = stop ? {
    date: {
      from: format(stop.at.from, 'do LLL', { locale: cs }),
      to: format(stop.at.to, 'do LLL', { locale: cs }),
    },
    time: {
      from: format(stop.at.from, 'p', { locale: cs }),
      to: format(stop.at.to, 'p', { locale: cs }),
    },
  } : undefined;

  let addressname;
  if (stop?.location.company) {
    addressname = stop.location.company;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <h1>{(variant === 'pickup') ? 'Nakládka' : 'Vykládka'}</h1>
        {/* <button */}
        {/*  type="button" */}
        {/* eslint-disable-next-line max-len */}
        {/*  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" */}
        {/* > */}
        {/*  Upravit */}
        {/* </button> */}
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          {(variant === 'pickup') ? 'Čas nakládky' : 'Čas vykládky'}
        </dt>
        {(at) ? (
          <dd className="mt-1 text-sm text-gray-900">
            {(at.date.from === at.date.to)
              ? (`${at.date.from} (${at.time.from}-${at.time.to})`)
              : (`${at.date.from} ${at.time.from} - ${at.date.to} ${at.time.to}`)}
          </dd>
        ) : (
          <div>undefined</div>
        )}

      </div>
      <div className="sm:col-span-1 h-24">
        <dt className="text-sm font-medium text-gray-500">Adresa</dt>
        {(addressname)
          ? <dd className="mt-1 text-sm text-gray-900">{addressname}</dd>
          : null}
        <dd className="mt-1 text-sm text-gray-900">{displayAddress}</dd>
        <dd className="mt-1 text-sm text-gray-900">
          <div className="flex flex-row space-x-3 pt-1">
            <EquipmentLabel enabled={stop?.location.requiredEquipment.forklift === true}>
              Vozík
            </EquipmentLabel>
            <EquipmentLabel enabled={stop?.location.requiredEquipment.taillift === true}>
              Čelo
            </EquipmentLabel>
          </div>

        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Kontakt</dt>
        {stop?.location?.contact.name
          ? <dd className="mt-1 text-sm text-gray-900">{stop?.location.contact.name}</dd>
          : <dd className="mt-1 text-sm italic text-red-700">Chybné jméno</dd>}
        {stop?.location?.contact.email
          ? <dd className="mt-1 text-sm text-gray-900">{stop?.location.contact.email}</dd>
          : <dd className="mt-1 text-sm italic text-red-700">Chybný email</dd>}
        {stop?.location?.contact.phone
          ? <dd className="mt-1 text-sm text-gray-900">{stop?.location.contact.phone}</dd>
          : <dd className="mt-1 text-sm italic text-red-700">Chybné telefonní číslo</dd>}
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Poznámka</dt>
        {stop?.location?.note
          ? <dd className="mt-1 text-sm text-gray-900">{stop?.location.note}</dd>
          : <dd className="mt-1 text-sm italic text-gray-400">(prázdné)</dd>}
      </div>
    </div>
  );
};

ShipmentLocationSection.defaultProps = {
  stop: null,
};

export default ShipmentLocationSection;
