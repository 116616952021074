import React from 'react';
import { CheckIcon, ExclamationIcon, TruckIcon } from '@heroicons/react/solid';

type TimelineIconType = {
  status?: string,
  onClick?: () => void,
  isLoader?: boolean,
  isFiller?: boolean,
  isToday?: boolean,
};

type TimeLineIconSettingsType = {
  IconName: (props: React.SVGProps<SVGSVGElement>) => JSX.Element,
  color: string,
  fillable: boolean,
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const EmptyElement = () => <></>;

const TimelineIcon = ({
  status, isLoader, isFiller, isToday, onClick,
}: TimelineIconType) => {
  const settings: TimeLineIconSettingsType = {
    IconName: EmptyElement,
    color: 'replace-white',
    fillable: false,
  };

  switch (status) {
    case 'delivered':
      settings.IconName = TruckIcon;
      settings.color = 'replace-green-700';
      break;
    case 'delivering':
      settings.IconName = CheckIcon;
      settings.color = 'replace-green-700';
      break;
    case 'warning':
      settings.IconName = ExclamationIcon;
      settings.color = 'replace-orange-400';
      break;
    case 'error':
      settings.IconName = ExclamationIcon;
      settings.color = 'replace-red-700';
      break;
    case 'none':
      break;
    default:
      settings.fillable = true;
    //
  }

  return (
    <div>
      <span
        className={classNames(
          'h-12 w-12 rounded-2xl flex items-center justify-center',
          settings.color.replace('replace', 'bg'),
          'border-2',
          settings.color.replace('replace', 'border'),
        )}
      >
        <settings.IconName className={`h-5 w-5 ${settings.fillable ? settings.color.replace('replace', 'fill') : 'text-white'}`} aria-hidden="true" />
      </span>
    </div>
  );
};

TimelineIcon.defaultProps = {
  status: null,
  onClick: () => {},
  isFiller: false,
  isToday: false,
  isLoader: false,
};

export default TimelineIcon;
