import { Location, LocationOverview } from './location';
import { SelectedProvider, SelectedProviderOverview } from './provider';

export enum UnitKind {
  Box,
  CustomPallet,
  EurPallet,
}

export type Dimensions = {
  length: number,
  width: number,
  height: number,
};

export type Unit = {
  kind: UnitKind,
  weight: number,
  volume: number,
  dimensions: Dimensions,
};

export type Cargo = {
  totalWeight: number,
  totalVolume: number,
  units: Unit[],
};

export type TimeRange = {
  from: Date,
  to: Date,
};

export type Stop = {
  location: Location,
  at: TimeRange,
};

export type StopOverview = {
  location: LocationOverview,
  at: TimeRange,
};

export type Shipment = {
  id: string,
  name?: string,
  reference: string,

  pickup: Stop,
  delivery: Stop,

  cargo: Cargo,
  selectedProvider?: SelectedProvider,

  createdBy: string,
  createdAt: Date,
};

export type ShipmentOverview = {
  id: string,
  name?: string,
  reference: string,

  pickup: StopOverview,
  delivery: StopOverview,

  selectedProvider?: SelectedProviderOverview,

  totalWeight: number,
  totalVolume: number,
  totalUnits: number,
};
