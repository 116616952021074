import React from 'react';

type TextDividerProps = {
  text: string;
  shade?: number;
  textShade?: number;
};

const TextDivider = ({ text, shade = 300, textShade = 500 }: TextDividerProps): React.ReactElement => (
  <div className="relative">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className={`w-full border-t border-gray-${shade}`} />
    </div>
    <div className="relative flex justify-start">
      <span className={`pr-2 text-sm text-gray-${textShade}`}>{text}</span>
    </div>
  </div>
);

TextDivider.defaultProps = {
  shade: 300,
  textShade: 500,
};

export default TextDivider;
