import React, { Dispatch } from 'react';
import { FormActions } from '../../../../hooks/forms/types.d.t';

type FtlProps = {
  dispatch: Dispatch<FormActions>,
};

const Ftl = ({ dispatch }: FtlProps): React.ReactElement => {
  // eslint-disable-next-line no-console
  console.log('ftl', dispatch);
  return (
    <div>FTL To be implemented</div>
  );
};

export default Ftl;
