/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ProviderStatus {
  BOOKED = "BOOKED",
  CANCELED = "CANCELED",
  DELIVERED = "DELIVERED",
  ON_THE_WAY = "ON_THE_WAY",
  PREPARED = "PREPARED",
}

export enum ShipmentStatus {
  BOOKED = "BOOKED",
  BOOKING_REQUESTED = "BOOKING_REQUESTED",
  CANCELED = "CANCELED",
  CREATED = "CREATED",
  DELIVERED = "DELIVERED",
  ON_THE_WAY = "ON_THE_WAY",
  PREPARED = "PREPARED",
}

export interface AddressFilter {
  street?: string | null;
  city?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
}

export interface DateRangeFilter {
  from: string;
  days: number;
}

export interface SearchShipmentsFilters {
  status?: ShipmentStatus | null;
  departure?: DateRangeFilter | null;
  origin?: AddressFilter | null;
  destinations?: AddressFilter | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
