import React from 'react';
import { FormUnitCargo } from '../../../../hooks/forms/types.d.t';

type UnitServicesProps = {
  cargo: FormUnitCargo;
};

const UnitServices = ({ cargo: { services } }: UnitServicesProps): React.ReactElement => {
  const {
    cashOnDelivery, deliveryNote, floorDelivery, insurance,
  } = services;
  return (
    <>
      {cashOnDelivery && (
      <div className="flex justify-between">
        <div>Dobírka</div>
        <div>{(cashOnDelivery.price?.amount)}</div>
      </div>
      )}
      {deliveryNote && (
        <div className="flex justify-between">
          <div>Dodací listy</div>
          <div>{(deliveryNote.price?.amount)}</div>
        </div>
      )}
      {floorDelivery && (
        <div className="flex justify-between">
          <div>Výnos do patra</div>
          <div>{(floorDelivery.price?.amount)}</div>
        </div>
      )}
      {insurance && insurance.amount && insurance.currency && (
        <div className="flex justify-between">
          <div>
            {`Připojištění (${insurance.amount} ${insurance.currency})`}
          </div>
          <div>{insurance.price ? (insurance.price?.amount) : '-- CZK'}</div>
        </div>
      )}
    </>
  );
};

export default UnitServices;
