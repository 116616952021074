import React, { Dispatch } from 'react';
import { FormActions } from '../../../../hooks/forms/types.d.t';

type LtlProps = {
  dispatch: Dispatch<FormActions>,
};

const Ltl = ({ dispatch }: LtlProps): React.ReactElement => {
  // eslint-disable-next-line no-console
  console.log('ftl', dispatch);
  return (
    <div>LTL To be implemented</div>
  );
};

export default Ltl;
