import { Popover } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { ActiveFilters, getActiveValues, useFilterContext } from '../../../hooks/filters/useFilters';

export type SelectableFilterPopoverProps = {
  children: JSX.Element,
  name: string,
  label: string,
  count: number
};

export type SelectableFilterPopoverSelectedProps = {
  count: number
};

const Selected = ({ count }: SelectableFilterPopoverSelectedProps) => {
  if (count <= 0) return null;
  return (
    <div className="bg-white text-xs py-0 my-0 px-1.5 mx-1 rounded-md">
      +
      {count}
    </div>
  );
};

const Chevron = ({ count, open }: {
  count: number, open: boolean
}): React.ReactElement => {
  if (count === 0) {
    if (open) {
      return <ChevronUpIcon color="gray" height="16" />;
    }
    return <ChevronDownIcon color="gray" height="16" />;
  }
  return <></>;
};

const SelectableFilterPopover = ({
  children, label, name, count,
}: SelectableFilterPopoverProps): React.ReactElement => {
  const { state, dispatch } = useFilterContext();
  const activeFilters = getActiveValues(state);
  const active = activeFilters.filter((filter) => filter.name === name);

  const close = ({
    name: filterName, originalValue,
  }: ActiveFilters): void => {
    dispatch({
      name: filterName,
      payload: originalValue,
    });
  };

  const closeAll = (values: ActiveFilters[]): void => {
    values.forEach((value) => close(value));
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button>
            <div
              className={`border ${open ? 'border-black' : 'border-gray-400'} ${count > 0 ? 'bg-blue-100 border-blue-400' : 'bg-white'} text-sm px-3 py-2 rounded-full font-medium text-gray-700 tracking-wider flex items-center cursor-pointer`}
            >
              <div>{label}</div>
              <Selected count={count - 1} />
              <Chevron count={count} open={open} />
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              {count > 0 && <span role="cell" className="p-0 m-0 ml-2 rounded-full hover:bg-blue-200" onClick={(e) => { e.preventDefault(); closeAll(active); }}><XIcon className="text-blue-400" height="20" /></span>}
            </div>
          </Popover.Button>

          <Popover.Panel className="absolute m-auto rounded-md border border-gray-200 shadow-2xl bg-white mt-2 z-10">
            {children}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default SelectableFilterPopover;
