import React from 'react';
import { SimpleLocationType } from '../../../types/list';

export type AddressSegmentProps = {
  pickup: SimpleLocationType,
  delivery: SimpleLocationType,
  showPickup?: boolean,
  showDelivery?: boolean,
};

export type AddressRowProps = {
  address: SimpleLocationType,
  isPickup: boolean,
  hidden?: boolean
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const AddressRow = ({ address, isPickup, hidden = false }: AddressRowProps): React.ReactElement => (
  <li>
    <div className="relative pb-1">
      {isPickup && !hidden ? (
        <span
          className="absolute top-3 left-1 -ml-px h-full w-0.5 bg-gray-400"
          aria-hidden="true"
        />
      ) : null}
      <div className="relative flex space-x-3">
        {!hidden && (
          <div>
            <span
              className={classNames(
                !isPickup ? 'bg-white' : 'bg-gray-400',
                address ? 'ring-gray-400' : 'ring-red-400',
                !address && isPickup ? 'bg-red-400' : '',
                'h-2 w-2 mt-1 md:mt-1.5 rounded-full flex items-center justify-center ring-2',
              )}
            />
          </div>
        )}
        <div className="min-w-0 flex-1 flex justify-between space-x-4">
          <div>
            <div className={classNames(
              address ? '' : 'text-red-400',
              hidden ? 'flex flex-col' : 'flex flex-col md:block',
              'text-sm truncate',
            )}
            >
              <span className="text-xs md:text-sm">{address.name}</span>
              {' '}
              <span className="text-xs text-gray-500">{address.address}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
);

AddressRow.defaultProps = {
  hidden: false,
};

const AddressSegment = ({
  pickup, delivery, showDelivery = true, showPickup = true,
}: AddressSegmentProps): React.ReactElement => {
  const hidden = !showDelivery || !showPickup;
  return (
    <div>
      <div className="flow-root">
        <ul className="-mb-2">
          {showPickup && <AddressRow address={pickup} isPickup hidden={hidden} />}
          {showDelivery && <AddressRow address={delivery} isPickup={false} hidden={hidden} />}
        </ul>
      </div>
    </div>
  );
};

AddressSegment.defaultProps = {
  showPickup: false,
  showDelivery: false,
};

export default AddressSegment;
