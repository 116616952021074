import { AddressAutocompleteFilterValue } from '../../components/wrld/types/form';

type UseAddressAutocompleteResult = {
  res: AddressAutocompleteFilterValue[]
};

function useAddressAutocomplete(searchValue: string): UseAddressAutocompleteResult {
  let res: AddressAutocompleteFilterValue[] = [];

  if (searchValue.length > 0) {
    res = [
      {
        id: 'assfasfasf',
        isValid: true,
        value: {
          city: 'Praha',
          street: 'Ulice',
          streetNo: '158',
          zipCode: '441424',
        },
      },
      {
        id: 'assfasfasfasdasd',
        isValid: true,
        value: {
          city: 'Praha',
          street: 'Ulice',
          streetNo: '158',
          zipCode: '441424',
        },
      },
      {
        id: 'assfasfasf4324',
        isValid: true,
        value: {
          city: 'Praha',
          street: 'Ulice',
          streetNo: '158',
          zipCode: '441424',
        },
      },
    ] as AddressAutocompleteFilterValue[];
  }

  return <UseAddressAutocompleteResult>{
    res,
  };
}

export default useAddressAutocomplete;
