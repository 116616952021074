import React from 'react';
import { Unit, UnitKind } from '../types/shipment';
import { ProviderStatus } from '../types/provider';
import ProviderStatusBadge from './provider/ProviderStatusBadge';

const unitKindMap: Record<UnitKind, string> = {
  [UnitKind.Box]: 'Balík',
  [UnitKind.CustomPallet]: 'Vlastní paleta',
  [UnitKind.EurPallet]: 'EUR paleta',
};

export type ShipmentUnitCardProps = {
  unit: Unit,
  idx: number,
  total: number,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShipmentUnitCard = ({ unit, idx, total }: ShipmentUnitCardProps): React.ReactElement => (
  <div className="flex items-center flex-row w-full grid grid-cols-12">
    <div className="col-span-1">
      <span className="text-md">{`${idx}`}</span>
      <span className="text-xs text-gray-300">{`/${total}`}</span>
    </div>
    <div className="col-span-2">{unitKindMap[unit.kind]}</div>
    <div className="col-span-2">{`${unit.weight} kg`}</div>
    <div className="col-span-3">{`${unit.dimensions.length}x${unit.dimensions.width}x${unit.dimensions.height} cm`}</div>
    <div className="col-span-3 text-center">
      <ProviderStatusBadge status={ProviderStatus.OnTheWay} />
    </div>
    <div className="col-span-1 text-center">
      <button
        type="button"
        className="inline-flex items-center px-2 py-1 border border-transparent rounded-md hover:shadow-sm text-sm hover:font-medium hover:text-white text-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        štítek
      </button>
    </div>
  </div>
);

export default ShipmentUnitCard;
