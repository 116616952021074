import React, { useLayoutEffect, useState } from 'react';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { ArrowDownIcon } from '@heroicons/react/solid';
import UnitCargo from './UnitCargo';
import UnitServices from './UnitServices';
import { Price } from '../types';
import {
  Errors, FormLocation,
  FormSavedLocation, FormUnitCargo,
  MetaFormData,
  ShipmentFormData,
} from '../../../../hooks/forms/types.d.t';

type RecapitulationProps = {
  state: ShipmentFormData,
  meta: MetaFormData,
  errors: Errors,
  totalPrice: Price,
};

const Divider = (): React.ReactElement => (
  <div className="relative py-3">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
  </div>
);

const Recapitulation = ({
  state, meta, errors, totalPrice,
}: RecapitulationProps): React.ReactElement => {
  let cargoType;
  if (state.cargo) {
    if (Object.prototype.hasOwnProperty.call(state.cargo, 'units')) {
      cargoType = 'units';
    }
  }

  type RecapitulationPlace = {
    city?: string,
    countryCode?: string,
  };

  const [origin, setOrigin] = useState<RecapitulationPlace>({});
  const [destination, setDestination] = useState<RecapitulationPlace>({});

  useLayoutEffect(() => {
    if (state.origin) {
      if (Object.prototype.hasOwnProperty.call(state.origin, 'meta')) {
        const a = state.origin as FormSavedLocation;
        setOrigin({
          city: a.meta?.city,
          countryCode: a.meta?.countryCode,
        });
      } else {
        const b = state.origin as FormLocation;
        setOrigin({
          city: b.address.city,
          countryCode: b.address.countryCode,
        });
      }
    }
    if (state.destination) {
      if (Object.prototype.hasOwnProperty.call(state.destination, 'meta')) {
        const a = state.destination as FormSavedLocation;
        setDestination({
          city: a.meta?.city,
          countryCode: a.meta?.countryCode,
        });
      } else {
        const b = state.destination as FormLocation;
        setDestination({
          city: b.address.city,
          countryCode: b.address.countryCode,
        });
      }
    }
  }, [state.origin, state.destination]);

  const hasFullAddress = origin.city
    && origin.countryCode
    && destination.city
    && destination.countryCode;

  // todo remove - its just for developing purposes
  const [deleteMe, setDeleteMe] = React.useState(true);

  return (
    <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3 text-sm">
      <section className="hidden lg:block space-y-1 fixed bottom-0 lg:sticky lg:top-4 lg:z-50">
        <h3 className="font-medium">Rekapitulace objednávky:</h3>
        <div>
          {state.pickup.date && (
            <span>
              Datum:
              {' '}
              {moment(state.pickup.date).format('DD.MM.YYYY')}
            </span>
          )}
          {state.pickup.timeFrom && state.pickup.timeTo && (
            <span className="ml-2 text-xs text-gray-400">
              {`(${moment(state.pickup.timeFrom).format('HH:mm')} - ${moment(state.pickup.timeTo).format('HH:mm')})`}
            </span>
          )}
        </div>

        <div className="py-2" />

        {hasFullAddress && (
          <>
            <div className="text-center">
              <div>
                <span className="font-semibold">{origin.city}</span>
                {', '}
                <span>{origin.countryCode}</span>
              </div>
              <div className="flex justify-center">
                <ArrowDownIcon className="w-6 h-6 py-1" />
              </div>
              <div>
                <span className="font-semibold">{destination.city}</span>
                {', '}
                <span>{destination.countryCode}</span>
              </div>
            </div>
            <div className="py-2" />
          </>
        )}

        {cargoType === 'units' && state.cargo && (
          <UnitCargo cargo={state.cargo as FormUnitCargo} />
        )}

        <div className="py-1" />

        {state.provider && (
        <div className="flex justify-between">
          <div className="flex items-center">
            {state.provider.name}
            logo
          </div>
          <div>{(state.provider.price?.amount)}</div>
        </div>
        )}

        {cargoType === 'units' && state.cargo && (
          <UnitServices cargo={state.cargo as FormUnitCargo} />
        )}

        <Divider />

        <div className="flex justify-between items-baseline">
          <div data-tip data-for="price" className="border-b border-dotted pb-0 mb-0 leading-none border-gray-500">Celkem</div>
          <div className="text-lg font-medium">
            {totalPrice?.amount}
            {' '}
            {totalPrice?.currency}
          </div>
        </div>

        <ReactTooltip effect="solid" id="price" place="bottom">
          <div>
            <div className="font-semibold mb-4">Rozklad ceny:</div>
            <div className="flex justify-between space-x-3">
              <div>Základ ceny:</div>
              <div>690 CZK</div>
            </div>
            <div className="flex justify-between space-x-3">
              <div>Palivový příplatek:</div>
              <div>233.01 CZK</div>
            </div>
            <div className="flex justify-between space-x-3">
              <div>Mýtný příplatek:</div>
              <div>97.12 CZK</div>
            </div>
            <div className="mt-2 flex justify-between space-x-3">
              <div>Dobrírka:</div>
              <div>50.00 CZK</div>
            </div>
            <div className="flex justify-between space-x-3">
              <div>Připojištění</div>
              <div>83.33 CZK</div>
            </div>
            <div className="mt-2 flex justify-between space-x-3 items-baseline">
              <div>Celkem:</div>
              <div className="font-semibold text-lg">1 083.33 CZK</div>
            </div>
          </div>
        </ReactTooltip>

        {deleteMe && (
        <pre data-tip="test 2" className="hidden fixed bottom-5 right-10 bg-gray-300 rounded-md p-2 h-96 overflow-scroll">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div onClick={() => setDeleteMe(false)} className="bg-gray-200 text-center">click to close</div>
          {JSON.stringify(state, null, 2)}
          {JSON.stringify(meta, null, 2)}
        </pre>
        )}

        <pre data-tip="test 2" className="hidden fixed bottom-5 right-10 bg-gray-300 rounded-md p-2 h-36 overflow-scroll">
          {JSON.stringify(errors, null, 2)}
        </pre>
      </section>
      <section className="block lg:hidden fixed bottom-0 left-0 bg-white border-t w-full px-4 py-2">
        <div className="flex justify-between">
          <div className="font-semibold">Rekapitulace objednávky:</div>
          <div>
            {state.pickup.date && (
              <span>
                Datum:
                {' '}
                {moment(state.pickup.date).format('DD.MM.YYYY')}
              </span>
            )}
            <span className="ml-2 text-xs text-gray-400">
              {state.pickup.timeFrom && state.pickup.timeTo && (
                <>
                  {`(${moment(state.pickup.timeFrom).format('HH:mm')} - ${moment(state.pickup.timeTo).format('HH:mm')})`}
                </>
              )}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-baseline">
          <div className="font-semibold">Celková cena:</div>
          <div className="text-lg font-semibold">
            {totalPrice?.amount}
            {' '}
            {totalPrice?.currency}
          </div>
        </div>
      </section>
    </aside>
  );
};

export default Recapitulation;
