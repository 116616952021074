import React from 'react';
import { ProviderStatus } from '../../types/provider';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type StatusItem = {
  displayName: string,
  textColor: string,
  bgColor: string,
};

const statusMap: Record<ProviderStatus, StatusItem> = {
  [ProviderStatus.Prepared]: { displayName: 'Připraveno', bgColor: 'bg-white', textColor: 'text-gray-800' },
  [ProviderStatus.BookingRequested]: { displayName: 'Připraveno', bgColor: 'bg-white', textColor: 'text-gray-800' },
  [ProviderStatus.Booked]: { displayName: 'Rezervováno', bgColor: 'bg-yellow-100', textColor: 'text-yellow-800' },
  [ProviderStatus.OnTheWay]: { displayName: 'Na cestě', bgColor: 'bg-blue-100', textColor: 'text-blue-800' },
  [ProviderStatus.Delivered]: { displayName: 'Doručeno', bgColor: 'bg-green-100', textColor: 'text-green-800' },
  [ProviderStatus.Canceled]: { displayName: 'Zrušeno', bgColor: 'bg-red-100', textColor: 'text-red-800' },
};

export type ProviderStatusBadgeProps = {
  status: ProviderStatus,
};

const baseCss = 'inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium';

const ProviderStatusBadge = ({ status }: ProviderStatusBadgeProps): React.ReactElement => {
  const { displayName, textColor, bgColor } = statusMap[status];
  return (
    <span className={classNames(baseCss, textColor, bgColor)}>
      {displayName}
    </span>
  );
};

export default ProviderStatusBadge;
