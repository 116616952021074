import {
  ApolloError, ApolloQueryResult, gql, NetworkStatus, useQuery,
} from '@apollo/client';
import { FetchMoreOptions, FetchMoreQueryOptions } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useEffect } from 'react';
import {
  shipments as getShipments,
  shipments_shipments_shipments_edges,
  shipmentsVariables,
} from './__generated__/shipments';
import { Address } from './__generated__/Address';
import {
  ShipmentOverviewCard,
  SimpleLocationType,
  SimpleProviderType,
} from '../../components/wrld/types/list';

export type UseShipmentsResult = {
  shipments: ShipmentOverviewCard[],
  isLoading: boolean,
  error?: ApolloError,
  endCursor: string | null | undefined,
  hasNextPage: boolean,
  fetchMore: any,
  networkStatus: NetworkStatus,
};

export type UseShipmentProps = shipmentsVariables;

const GET_OVERVIEW_SHIPMENTS_STOP_FRAGMENT = gql`fragment Address on Stop {
    at {
        from
        to
    }
    location {
        id
        companyName
        contact {
            name
            phone
            email
        }
        address {
            street
            streetNo
            city
            postalCode
            countryCode
            coordinates {
                lat
                lng
            }
            note
        }
    }
}`;

const GET_OVERVIEW_SHIPMENTS = gql`
    query shipments(
        $filters: SearchShipmentsFilters
    ) {
        shipments(
            filters: $filters
        ) {
            shipments {
                pageInfo {
                    hasPreviousPage
                    hasNextPage
                    startCursor
                    endCursor
                }
                edges {
                    cursor
                    node {
                        id
                        name
                        reference
                        pickup {
                            ...Address
                        }
                        delivery {
                            ...Address
                        }
                        provider {
                            price {
                                total
                                currency
                            }
                        }
                    }
                }
            }
        }
    }
    ${GET_OVERVIEW_SHIPMENTS_STOP_FRAGMENT}
`;

function getProviderFromEdge(edge: shipments_shipments_shipments_edges): SimpleProviderType {
  const defaultProvider = {
    name: 'Unknown',
    isWereldo: false,
  };

  if (!edge?.node) {
    return defaultProvider;
  }

  return {
    name: 'DHL',
    isWereldo: true,
  };
}

function getAddressFromEdge(address: Address | null): SimpleLocationType {
  const defaultLocation = {
    name: 'Chybí kontakt a adresa',
    address: '',
  };
  if (!address) {
    return defaultLocation;
  }
  const { companyName } = address.location;
  const { name: personName } = address.location.contact;
  const displayName = companyName || personName;

  const {
    street, city, countryCode,
  } = address.location.address;

  const addressSegment = [
    (street || ''),
    (city || ''),
    (countryCode || ''),
  ].filter((item) => item !== '');

  const displayAddress = addressSegment.length === 0 ? null : addressSegment.join(' • ');

  if (!displayName && !displayAddress) {
    return defaultLocation;
  }
  return {
    name: displayName || 'Chybí kontakt',
    address: displayAddress || 'Chybí adresa',
  };
}

export default function useShipments({
  filters = null,
}: UseShipmentProps): UseShipmentsResult {
  const {
    data: shipments,
    loading: isLoading,
    error,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<getShipments, shipmentsVariables>(GET_OVERVIEW_SHIPMENTS, {
    variables: {
      filters,
    },
    notifyOnNetworkStatusChange: false, // TODO enable for skeleton loader
  });

  useEffect(() => {
    refetch({
      filters,
    });
  }, [JSON.stringify(filters)]);

  const data: ShipmentOverviewCard[] = [];
  let endCursor;
  let hasNextPage = false;
  if (shipments && !error) {
    shipments.shipments.shipments.edges?.forEach((edge) => {
      if (edge) {
        data.push({
          id: edge?.node.id,
          name: edge?.node.name as string,
          reference: edge?.node.name as string,
          date: {
            from: edge?.node.pickup.at.from as string,
            to: edge?.node.pickup.at.to as string,
          },
          status: 'created',
          provider: getProviderFromEdge(edge),
          price: {
            total: edge?.node.provider?.price.total as number,
            currency: edge?.node.provider?.price.currency as string,
          },
          cargo: {
            pallets: 1,
            boxes: 1,
          },
          pickup: getAddressFromEdge(edge.node.pickup as Address),
          delivery: getAddressFromEdge(edge.node.delivery as Address),
        });
      }
    });
    endCursor = shipments.shipments.shipments.pageInfo.endCursor;
    hasNextPage = shipments.shipments.shipments.pageInfo.hasNextPage;
  }

  return {
    shipments: data,
    isLoading,
    error,
    endCursor,
    hasNextPage,
    networkStatus,
    fetchMore,
  };
}
