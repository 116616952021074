import React, {
  createContext, Dispatch, useContext, useReducer,
} from 'react';
import { SearchShipmentsFilters, ShipmentStatus } from '../../__generated__/globalTypes';
import {
  AddressAutocompleteFilterValue,
  EnumCheckboxFilterValue,
  SearchFilters,
} from '../../components/wrld/types/form';

export type ActiveFilters = {
  name: string,
  value: string,
  originalValue?: Payload,
};
type FilterContextProps = {
  children: React.ReactNode,
  data: UseFiltersResult,
};
export type UseFiltersResult = {
  state: State,
  dispatch: Dispatch<Action>,
  filters: SearchFilters,
};

type State = {
  status?: EnumCheckboxFilterValue[],
  providers?: EnumCheckboxFilterValue[],
  pickup?: AddressAutocompleteFilterValue,
  delivery?: AddressAutocompleteFilterValue,
};

type Payload = EnumCheckboxFilterValue | AddressAutocompleteFilterValue;

export type Action = {
  name: string,
  payload?: Payload,
};

export function filterReducer(state: State, action: Action): State {
  const filter = action.name as keyof State;

  switch (filter) {
    case 'status':
    case 'providers':
      if (state[filter]?.includes(action.payload as EnumCheckboxFilterValue)) {
        const originalState = state[filter];
        const payload = action.payload as EnumCheckboxFilterValue;
        return {
          ...state,
          [filter]:
            originalState?.filter((value) => value.name !== payload.name),
        };
      }
      return {
        ...state,
        [action.name]:
          state[filter]
            ? [...state[filter] as EnumCheckboxFilterValue[], action.payload]
            : [action.payload],
      };
    case 'pickup':
    case 'delivery':
      if (state[filter] === action.payload) {
        return {
          ...state,
          [filter]: undefined,
        };
      }
      return {
        ...state,
        [filter]: action.payload,
      };
    default:
      return state;
  }
}

export type FilterContextType = {
  dispatch: Dispatch<Action>,
  state: State,
  filters: SearchFilters,
};

export const FilterContext = createContext<FilterContextType>({
  filters: { },
  dispatch: {} as Dispatch<Action>,
  state: {},
});
export const useFilterContext = (): FilterContextType => useContext(FilterContext);

export function getInitialValues(filters: SearchFilters): State {
  const defaultValues: State = {};
  Object.keys(filters).forEach((kind) => {
    const filter = kind as keyof State;
    switch (filter) {
      case 'status':
      case 'providers':
        defaultValues[filter] = filters[filter]?.defaultValue || [];
        break;
      case 'pickup':
      case 'delivery':
        defaultValues[filter] = filters[filter]?.defaultValue;
        break;
      default:
        break;
    }
  });
  return defaultValues;
}

export function getActiveValues(state: State): ActiveFilters[] {
  const activeFilters: ActiveFilters[] = [];
  Object.keys(state).forEach((kind) => {
    const filter = kind as keyof State;
    switch (filter) {
      case 'status':
      case 'providers':
        state[filter]?.forEach((value) => {
          const val = {
            value: value.name,
            name: filter as string,
            originalValue: value,
          };
          if (!activeFilters.includes(val)) {
            activeFilters.push(val);
          }
        });
        break;
      case 'pickup':
      case 'delivery':
        if (state[filter]) {
          const val = {
            value: `${state[filter]?.value.street} ${state[filter]?.value.city}`,
            name: filter as string,
            originalValue: state[filter],
          };
          if (!activeFilters.includes(val)) {
            activeFilters.push(val);
          }
        }
        break;
      default:
        break;
    }
  });
  return activeFilters;
}

export function getQueryFilters(data: State): SearchShipmentsFilters {
  const variables: SearchShipmentsFilters = { };
  Object.keys(data).forEach((kind) => {
    const filter = kind as keyof State;
    switch (filter) {
      case 'status':
        if (data && data.status && data.status[0]) {
          variables.status = data.status[0].name as ShipmentStatus;
        }
        break;
      case 'providers':

        break;
      case 'pickup':
        break;
      case 'delivery':

        break;
      default:
        break;
    }
  });
  return variables;
}

export default function useFilters(filters: SearchFilters, initialValues: State): UseFiltersResult {
  const [state, dispatch] = useReducer(filterReducer, initialValues);

  return {
    state,
    dispatch,
    filters,
  };
}

export const ContextProvider = ({ children, data }: FilterContextProps): React.ReactElement => (
  <FilterContext.Provider value={data}>
    {children}
  </FilterContext.Provider>
);
