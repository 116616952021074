import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { InformationCircleIcon } from '@heroicons/react/solid';
import ReactTooltip from 'react-tooltip';
import ProviderSection from './provider/ProviderSection';
import QuoteCard from './QuoteCard';
import { Price, SelectedProvider } from '../types/provider';
import displayPrice from './provider/displayPrice';
import Modal from '../general/Modal';
import TextDivider from '../general/TextDivider';

export type PriceSectionProps = {
  price: Price,
  variant: 'small' | 'large',
};

// TODO localize currency
export const PriceSection = ({ price, variant }: PriceSectionProps): React.ReactElement => ((variant === 'small') ? (
  <span className="font-semibold text-2xl">
    {displayPrice(price.total, price.currency)}
  </span>
) : (
  <div className="flex flex-col">

    <div className="my-3">
      <TextDivider text="Základ ceny" shade={100} textShade={400} />

      <div className="flex flex-row justify-between">
        <span>Základní cena</span>
        <span className="tracking-widest">{displayPrice(price.base, price.currency)}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Palivový příplatek</span>
        <span className="tracking-widest">{displayPrice(price.fuel, price.currency)}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Mýtný příplatek</span>
        <span className="tracking-widest">{displayPrice(price.tolls, price.currency)}</span>
      </div>
    </div>

    <div className="my-3">
      <TextDivider text="Služby" shade={100} textShade={400} />

      <div className="flex flex-row justify-between">
        <span />
        <span className="italic text-gray-400">(žádné)</span>
      </div>
    </div>

    {/* TODO: divider */}
    <div className="flex flex-row justify-between">
      <span className="font-semibold">Celkem</span>
      <span className="tracking-widest">{displayPrice(price.total, price.currency)}</span>
    </div>
  </div>
));

export interface SelectedProviderSectionProps {
  provider?: SelectedProvider,
}

const SelectedProviderSection = ({ provider }: SelectedProviderSectionProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const changeState = () => setIsOpen(!isOpen);

  return (
    <>
      {(provider) ? (
        <div className="flex flex-row justify-between items-center md:p-4 md:py-6">
          <div className="flex flex-row space-x-3 items-center">
            {provider.segments.map((seg, idx) => (
              <Fragment key={`provider-${idx}`}>
                <ProviderSection provider={seg} variant="small" />
                {(provider.segments.length !== idx + 1) ? (<span>/</span>) : null}
              </Fragment>
            ))}
            <div
              role="button"
              tabIndex={0}
              className="text-sm text-gray-400 cursor-pointer underline decoration-1 underline-offset-4 decoration-dashed decoration-gray-400 hover:text-gray-700 hover:decoration-gray-700"
              onClick={changeState}
            >
              zobrazit detail
            </div>
          </div>

          <div
            tabIndex={0}
            role="textbox"
            className="flex items-center space-x-2"
            data-tip
            data-for="detail"
            onClick={changeState}
          >
            <div className="underline decoration-1 underline-offset-4 decoration-dashed decoration-gray-400 hover:decoration-gray-700">
              <PriceSection price={provider.price} variant="small" />
            </div>
            <InformationCircleIcon
              height={18}
              className="text-gray-400 cursor-help hover:text-gray-700"
            />
          </div>
          <ReactTooltip effect="solid" id="detail" place="top">Zobrazit detail</ReactTooltip>
        </div>
      ) : (
        <div className="flex flex-row justify-between animate-pulse md:p-4">
          <div className="w-2/4 bg-gray-300 h-6 rounded-md" />
          <div className="w-1/4 bg-gray-300 h-6 rounded-md" />
        </div>
      )}

      <Modal open={isOpen} onClose={changeState}>
        {(provider) ? (
          <>
            <div className="grid grid-cols-1 gap-16 sm:grid-cols-2">
              <div className="flex flex-col gap-5">
                {provider.segments.map((seg, idx) => (
                  <ProviderSection key={`provider-segment-${idx}`} provider={seg} variant="large" />
                ))}
              </div>
              <div>
                <PriceSection price={provider.price} variant="large" />
              </div>
            </div>
            {(provider.quotes.length !== 0) ? (
              <div className="pt-5">
                <h3>Ostatní nabídky</h3>
                <div className="bg-white border overflow-hidden sm:rounded-md">
                  <ul className="divide-y divide-gray-200">
                    {provider.quotes.map((quote) => (
                      <li key={quote.idx} className="py-4 flex">
                        <QuoteCard quote={quote} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </Modal>
    </>
  );
};

SelectedProviderSection.defaultProps = {
  provider: null,
};

export default SelectedProviderSection;
