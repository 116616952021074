import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { Errors, Error } from '../../../../hooks/forms/types.d.t';

type FieldProps = {
  id: string;
  type?: string;
  name: string;
  label?: string;
  errors: Errors;
  description?: string;
  placeholder?: string;
  wrapperClass?: string;
  autoComplete?: string;
  cornerHint?: string,
  min?: number,
  value?: string | number;
  defaultValue?: string | number;
  checked?: boolean;
  isTextarea?: boolean;
  isRequired?: boolean;
  textareaRows?: number;
  inputClass?: string;
  onChange?: (value: string) => void;
  onCheck?: (value: boolean) => void;
  onNumberChange?: (value: number) => void;
  children?: JSX.Element;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Field = ({
  id,
  type,
  name,
  label,
  errors,
  autoComplete,
  wrapperClass,
  placeholder,
  description,
  cornerHint,
  value,
  min,
  defaultValue,
  isTextarea,
  textareaRows,
  onChange,
  inputClass,
  onCheck,
  onNumberChange,
  checked,
  children,
  isRequired,
}: FieldProps): React.ReactElement => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.persist();
    if (isTextarea) {
      if (onChange) {
        onChange(e.target.value);
      }
    } else if (type === 'checkbox') {
      const target = e.target as HTMLInputElement;
      if (onCheck) {
        onCheck(target.checked);
      }
    } else if (type === 'number') {
      const target = e.target as HTMLInputElement;
      const n = target.value ? parseInt(target.value, 10) : 0;
      if (onNumberChange) {
        onNumberChange(n);
      }
    } else if (onChange) {
      onChange(e.target.value);
    }
  };

  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    setError(errors.find((e) => e.id === id));
  }, [errors]);
  const hasError = touched && error;

  return (
    <div className={wrapperClass}>
      {label && (
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <span
          className={classNames(
            cornerHint && !isRequired ? 'text-sm text-gray-500' : 'text-red-600 leading-4',
          )}
          id={`${id}-hint`}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {cornerHint && !isRequired ? cornerHint : (isRequired ? '*' : '')}
        </span>
      </div>
      )}
      <div className="mt-1 relative rounded-md shadow-sm flex">
        {!isTextarea ? (
          <input
            type={type}
            name={name}
            id={id}
            min={min}
            autoComplete={autoComplete}
            placeholder={placeholder}
            value={value || defaultValue || ''}
            checked={checked || false}
            onChange={handleChange}
            onBlur={() => setTouched(true)}
            required={isRequired}
            className={classNames(
              hasError ? 'border-red-300 text-red-900 placeholder-red-300' : '',
              hasError && type !== 'checkbox' ? 'pr-10' : '',
              children ? 'pr-12' : '',
              inputClass || 'focus:ring-blue-500 focus:border-blue-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
            )}
          />
        ) : (
          <textarea
            name={name}
            id={id}
            autoComplete={autoComplete}
            placeholder={placeholder}
            value={value || defaultValue || ''}
            onChange={handleChange}
            onBlur={() => setTouched(true)}
            required={isRequired}
            className={classNames(
              hasError ? 'pr-10 border-red-300 text-red-900 placeholder-red-300' : '',
              'shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md',
            )}
            rows={textareaRows}
          />
        )}
        {children}
        {hasError && !children && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {description && !error && (
        <p className="mt-1 text-sm text-gray-500" id={`${id}-description`}>
          {description}
        </p>
      )}
      {hasError && (
        <p className="mt-1 text-sm text-red-600" id={`${id}-error`}>
          {error.message}
        </p>
      )}
    </div>
  );
};

Field.defaultProps = {
  autoComplete: undefined,
  wrapperClass: '',
  placeholder: undefined,
  description: undefined,
  isRequired: undefined,
  label: undefined,
  cornerHint: undefined,
  type: 'text',
  value: undefined,
  defaultValue: undefined,
  checked: undefined,
  isTextarea: false,
  inputClass: 'focus:ring-blue-500 focus:border-blue-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
  textareaRows: 3,
  min: undefined,
  onChange: undefined,
  onCheck: undefined,
  onNumberChange: undefined,
  children: undefined,
};

export default Field;
