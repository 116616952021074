import { Currency } from '../../types/provider';
import { humanizeNumber } from '../../../../utils/formatters';

const currencyMap: Record<Currency, string> = {
  [Currency.CZK]: 'Kč',
  [Currency.EUR]: 'EUR',
};

const displayPrice = (value: number, currency: Currency): string => `${humanizeNumber(value, 2)} ${currencyMap[currency]}`;

export default displayPrice;
