import React from 'react';

type NumberFieldProps = {
  id: string;
  name: string;
  label?: string;
  wrapperClass?: string;
  cornerHint?: string,
  value?: number;
  min?: number,
  max?: number,
  error?: string,
  onChange?: (value: number) => void;
  increment?: () => void;
  decrement?: () => void;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const NumberField = ({
  id,
  name,
  label,
  wrapperClass,
  cornerHint,
  value,
  min,
  error,
  max,
  increment,
  decrement,
  onChange,
}: NumberFieldProps): React.ReactElement => {
  const hasError = error;
  return (
    <div className={classNames(
      wrapperClass || '',
      'wrld-number-input',
    )}
    >
      <div className="flex justify-between">
        {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        )}
        <span className="text-sm text-gray-500" id={`${id}-hint`}>
          {cornerHint}
        </span>
      </div>
      <div className="mt-1 relative rounded-md shadow-sm flex">
        <input
          type="number"
          min={min}
          max={max}
          name={name}
          id={id}
          autoComplete="off"
          placeholder={undefined}
          value={value}
          onChange={onChange ? (e) => onChange(parseInt(e.target.value, 10)) : undefined}
          className={classNames(
            hasError ? 'border-red-300 text-red-900 placeholder-red-300' : '',
            'pr-6 focus:ring-blue-500 focus:border-blue-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
          )}
        />
        <div className="h-auto float-left relative leading-3 font-semibold">
          <button
            onClick={increment}
            type="button"
            className="hover:bg-gray-100 rounded-tr q-button border-l border-b absolute h-1/2 top-0 w-5 text-center"
          >
            +
          </button>
          <button
            onClick={decrement}
            type="button"
            className="hover:bg-gray-100 rounded-br q-button border-l absolute h-1/2 bottom-0 w-5 text-center"
          >
            -
          </button>
        </div>
      </div>
    </div>
  );
};

NumberField.defaultProps = {
  wrapperClass: '',
  cornerHint: undefined,
  value: undefined,
  min: 0,
  max: 500,
  error: undefined,
  label: undefined,
  increment: undefined,
  decrement: undefined,
  onChange: undefined,
};

export default NumberField;
