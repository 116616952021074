import React, { useState } from 'react';
import DateSegment from './segments/DateSegment';
import EndSegment from './segments/EndSegment';
import DescriptionSegment from './segments/DescriptionSegment';
import AddressSegment from './segments/AddressSegment';
import { ShipmentOverviewCard } from '../../types/list';

// TODO:
//  kind: 'outbount' | 'inbound'
//  displayDate: boolean

export type ShipmentCardProps = {
  shipment: ShipmentOverviewCard,
  onClick?: () => void,
  cardHover?: JSX.Element,
  showDate?: boolean,
  showPickup?: boolean,
  showDelivery?: boolean,
};

const ShipmentCard = ({
  shipment,
  showDate,
  showPickup,
  showDelivery,
  onClick = (): void => {},
  cardHover = <></>,
}: ShipmentCardProps): React.ReactElement => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    name,
    reference,
    date,
    pickup,
    delivery,
    cargo,
    // labels,
    price,
  } = shipment;
  const displayName = name || reference;

  return (
    <div
      role="cell"
      className="cursor-pointer"
      onClick={() => onClick()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="px-3 md:px-4 py-2 mb-1.5 border flex md:rounded bg-white hover:bg-gray-50">
        <div className="grid grid-cols-12 w-full">
          <div className="col-span-4 md:col-span-5 flex flex-col md:flex-row">
            <div className="flex">
              logo
              <DateSegment show={showDate} date={date} kind="departure" />
            </div>
            <DescriptionSegment displayName={displayName} reference={reference} labels={[]} />
          </div>
          <div className="col-span-8 md:col-span-7 flex items-center justify-between">
            <AddressSegment
              showPickup={showPickup}
              showDelivery={showDelivery}
              pickup={pickup}
              delivery={delivery}
            />
            <EndSegment
              price={price}
              cargo={cargo}
              hoverElement={cardHover}
              isHovered={isHovered}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ShipmentCard.defaultProps = {
  cardHover: <></>,
  onClick: (): void => {},
  showDate: true,
  showPickup: true,
  showDelivery: false,
};

export default ShipmentCard;
