import React, { Dispatch, useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import ContentLoader from 'react-content-loader';
import { FormActions, QuotedUnitProvider } from '../../../hooks/forms/types.d.t';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type FormProvidersProps = {
  dispatch: Dispatch<FormActions>,
  loading?: boolean,
  providers?: QuotedUnitProvider[],
};

const ProviderRowLoader = (props: any): React.ReactElement => (
  <ContentLoader
    speed={1}
    viewBox="0 0 907 74"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: '100%', borderTop: '1px solid #e5e7eb' }}
    {...props}
  >
    <circle cx="24" cy="37" r="9" />
    <circle cx="65" cy="38" r="20" />
    <rect x="95" y="31" rx="0" ry="0" width="125" height="15" />
    <rect x="352" y="31" rx="0" ry="0" width="260" height="15" />
    <rect x="808" y="31" rx="0" ry="0" width="80" height="15" />
  </ContentLoader>
);

const FormProviders = ({ dispatch, loading, providers }: FormProvidersProps): React.ReactElement => {
  const [selected, setSelected] = useState<QuotedUnitProvider | null>(null);

  const handleSelect = (item: QuotedUnitProvider): void => {
    setSelected(item);
    dispatch({
      id: null,
      type: 'SET_VALUE',
      key: 'provider',
      payload: {
        token: `${item.name}abc`, // TODO token
        name: item.name,
        price: item.price,
      },
    });
  };

  const [mockUpLoading, setMockUpLoading] = useState(false);
  useEffect(() => {
    if (mockUpLoading) {
      setTimeout(() => {
        setMockUpLoading(false);
      }, 4000);
    }
  }, [mockUpLoading]);

  if (loading || mockUpLoading) {
    return (
      <>
        {Array(3).fill(null).map((_, i) => i).map((i) => <ProviderRowLoader key={`form-providers-skeleton-loader-${i}`} />)}
      </>
    );
  }

  return (
    <RadioGroup value={selected} onChange={handleSelect}>
      <RadioGroup.Label className="sr-only">Pricing plans</RadioGroup.Label>
      <div className="relative bg-white rounded-md -space-y-px">
        {providers && providers.map((provider, providerIdx) => (
          <RadioGroup.Option
            key={provider.name}
            value={provider}
            className={({ checked }) => classNames(
              providerIdx === 0 ? '' : '',
              !provider.disabled ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
              providerIdx === providers.length - 1 ? 'rounded-bl-md rounded-br-md border-b-0' : '',
              checked ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
              'relative border border-r-0 border-l-0 p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none items-center',
            )}
            disabled={provider.disabled}
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center text-sm">
                  <span
                    className={classNames(
                      checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center',
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <RadioGroup.Label
                    as="div"
                    className={classNames(
                      checked ? 'text-blue-900' : 'text-gray-900',
                      'ml-3 font-medium',
                      'flex items-center',
                    )}
                  >
                    {/* TODO smazat */}
                    {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                    <div onMouseOver={() => setMockUpLoading((prev) => !prev)}>
                      logo
                    </div>
                    {provider.name}
                  </RadioGroup.Label>
                </div>
                <RadioGroup.Description
                  className={classNames(
                    checked ? 'text-blue-700' : 'text-gray-500',
                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center',
                  )}
                >
                  {provider.title}
                </RadioGroup.Description>
                <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">
                  {!provider.disabled && (
                  <span className={classNames(checked ? 'text-blue-900' : 'text-gray-900', 'font-medium')}>
                    {(provider.price?.amount)}
                  </span>
                  )}
                </RadioGroup.Description>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

FormProviders.defaultProps = {
  loading: false,
  providers: [],
};

export default FormProviders;
