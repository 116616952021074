import React from 'react';
import {
  ArrowUpIcon,
  CheckIcon, ExclamationIcon, TruckIcon,
} from '@heroicons/react/solid';
import TimelineIcon from '../../components/wrld/timeline/TimelineIcon';

const timeline = [
  {
    id: 1,
    content: 'Applied to',
    target: 'Front End Developer',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    isLoader: false,
    isFiller: false,
    isToday: false,
  },
  {
    id: 2,
    content: 'Advanced to phone screening by',
    target: 'Bethany Blake',
    href: '#',
    date: 'Sep 22',
    datetime: '2020-09-22',
  },
  {
    id: 3,
    content: 'Completed phone screening with',
    target: 'Martha Gardner',
    href: '#',
    date: 'Sep 28',
    datetime: '2020-09-28',
    status: 'error',
  },
  {
    id: 4,
    content: 'Advanced to interview by',
    target: 'Bethany Blake',
    href: '#',
    date: 'Sep 30',
    datetime: '2020-09-30',
    status: 'warning',
  },
  {
    id: 5,
    content: 'Completed interview with',
    target: 'Katherine Snyder',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    status: 'warning',
  },
  {
    id: 6,
    content: 'Completed interview with',
    target: 'Katherine Snyder',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    status: 'delivering',
  },
  {
    id: 7,
    content: 'Completed interview with',
    target: 'Katherine Snyder',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    status: 'delivered',
  },
];
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ShipmentsTimeline = () => (
  <div className="min-h-full pt-24 pb-10">
    <div className="max-w-7xl mx-auto px-6 lg:px-8">
      <header>
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Denní přehled</h1>
        </div>
      </header>
      <div className="flow-root">
        <ul className="-mb-8">
          {timeline.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span className="absolute top-8 left-33 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex items-center space-x-3">
                  <div className="w-24 max-w-xxs text-right">
                    today 25.12.
                  </div>
                  <TimelineIcon
                    status={event.status}
                    isFiller={event.isFiller}
                    isToday={event.isToday}
                    isLoader={event.isLoader}
                  />
                  <div className="w-full flex-1 pt-1.5 bg-gray-200 rounded-md">
                    <div className="">
                      test
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default ShipmentsTimeline;
