import React, { Dispatch, useState } from 'react';
import ServiceTemplate from './ServiceTemplate';
import { ServiceItem } from '../types';
import Field from '../control/Field';
import {
  Errors,
  FormActions,
  FormUnitCargo,
  ShipmentFormData,
} from '../../../../hooks/forms/types.d.t';

const insuranceOptions: ServiceItem[] = [
  {
    id: 1,
    title: 'Bez připojištění',
    description: 'Nepřeji si zboží připojistit.',
    value: false,
    price: null,
  },
  {
    id: 2,
    title: 'Připojištění',
    description: 'Nechte si zásilku připojstit. Cena se odvíjí od zadané částky.',
    value: true,
    price: null,
  },
];

type InsuranceProps = {
  dispatch: Dispatch<FormActions>,
  state: ShipmentFormData,
  errors: Errors,
};

const Insurance = ({ dispatch, state, errors }: InsuranceProps): React.ReactElement => {
  const [
    selectedInsuranceOption,
    setSelectedInsuranceOption,
  ] = useState(insuranceOptions[0]);

  const setState = (item: ServiceItem): void => {
    setSelectedInsuranceOption(item);
    dispatch({
      id: null,
      type: 'SET_VALUE',
      key: 'unitServices',
      field: 'insurance',
      payload: insuranceOptions.indexOf(item) === 0 ? undefined : {
        price: item.price,
        currency: 'CZK',
      },
    });
  };

  const cargo = state.cargo as FormUnitCargo;

  return (
    <>
      <ServiceTemplate
        setSelectedItem={setState}
        selectedItem={selectedInsuranceOption}
        items={insuranceOptions}
        title="Připojištění"
        iconName="agreement"
      />
      {insuranceOptions.indexOf(selectedInsuranceOption) === 1 && (
      <div className="w-full">
        <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-4">
          <div className="hidden col-span-1 md:block" />
          <Field
            isRequired
            errors={errors}
            wrapperClass="col-span-2 mx-2 mb-4 md:mr-6"
            id="unit-insurance-amount"
            name="unit-insurance-amount"
            label="Hodnota"
            placeholder="0.00"
            type="number"
            value={cargo.services.insurance?.amount}
            onNumberChange={(value) => {
              dispatch({
                id: 'unit-insurance-amount',
                type: 'SET_VALUE',
                key: 'unitServicesField',
                service: 'insurance',
                field: 'amount',
                payload: value,
              });
            }}
          >
            <div className="absolute inset-y-0 right-0 flex items-center">
              <label htmlFor="currency" className="sr-only">
                Měna
              </label>
              <select
                id="currency"
                name="currency"
                className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                value={cargo.services.insurance?.currency || 'CZK'}
                onChange={(e) => {
                  dispatch({
                    id: null,
                    type: 'SET_VALUE',
                    key: 'unitServicesField',
                    service: 'insurance',
                    field: 'currency',
                    payload: e.target.value,
                  });
                }}
              >
                <option value="CZK">Kč</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </Field>
        </div>
      </div>
      )}
    </>
  );
};

export default Insurance;
