import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  CalendarIcon, BanIcon, PrinterIcon, ChevronDownIcon,
} from '@heroicons/react/solid';

// import { format } from 'date-fns';

import { ProviderStatus } from '../types/provider';
import ProviderStatusBadge from './provider/ProviderStatusBadge';
import Button from '../Button';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export type CreatedProps = {
  name?: string,
  at: Date,
};

// TODO add `at` props
const CreatedBy = ({ name, at }: CreatedProps): React.ReactElement => (
  <div className="flex text-sm text-gray-500">
    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
    {`Vytvořil ${name} před ${at.toString()}`}
  </div>
);

CreatedBy.defaultProps = {
  name: 'unknown',
};

export type ShipmentHeadingProps = {
  name?: string,
  reference?: string,
  status?: ProviderStatus,
  created?: CreatedProps,

  cancel: () => void,
  getLabels: () => void,
};

const ShipmentHeading = ({
  name, reference, status, created, cancel, getLabels,
}: ShipmentHeadingProps) => (
  <div className="lg:flex lg:items-center lg:justify-between">
    <div className="flex-1 min-w-0">
      <div className="flex flex-col gap-2">

        <div className="flex flex-row space-x-3">
          {(name && reference) ? (
            <h2 className="text-2xl font-bold text-gray-800 sm:text-3xl sm:truncate">
              {name}
              <span className="text-sm font-medium text-gray-400 sm:text-md sm:truncate ml-3">
                {`#${reference}`}
              </span>
            </h2>
          ) : (
            <div className="w-96 bg-gray-300 h-6 rounded-md animate-pulse" />
          )}

        </div>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
          {(created) ? (
            <>
              <ProviderStatusBadge status={status || ProviderStatus.Prepared} />
              <CreatedBy name={created.name} at={created.at} />
            </>
          ) : (
            <>
              <div className="w-24 bg-gray-300 h-6 rounded-md animate-pulse" />
              <div className="w-32 bg-gray-300 h-6 rounded-md animate-pulse" />
            </>
          )}

        </div>
      </div>
    </div>
    <div className="mt-5 flex lg:mt-0 lg:ml-4">
      <span className="hidden sm:block ml-3">
        <Button variant="white" onClick={cancel} Icon={BanIcon}>Zrušit</Button>
      </span>
      <span className="sm:ml-3">
        <Button onClick={getLabels} Icon={PrinterIcon}>Vytisknout štítky</Button>
      </span>

      {/* Dropdown */}
      <Menu as="span" className="ml-3 relative sm:hidden">
        <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          More
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#sdf"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                >
                  Cancel
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#sdf"
                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                >
                  Clone
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>

    </div>
  </div>
);

ShipmentHeading.defaultProps = {
  name: 'unknown',
  reference: 'unknown',
  status: ProviderStatus.OnTheWay,
  created: {
    name: 'unknown',
    at: new Date(),
  },
};

export default ShipmentHeading;
