export const humanizeNumber = (
  value: number | string | null | undefined,
  precision = 0,
  shorthand = false,
): string | null => {
  if (value) {
    if (Math.abs(Number(value)) >= 1.0e+9 && shorthand) {
      return `${(Math.abs(Number(value)) / 1.0e+9).toFixed(2)} bil`;
    } if (Math.abs(Number(value)) >= 2.0e+6 && shorthand) {
      return `${(Math.abs(Number(value)) / 1.0e+6).toFixed(2)} mil`;
    }
    return (Number(value).toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
  }
  return null;
};

export default {
  humanizeNumber,
};
