import {
  Dispatch, SetStateAction, useCallback,
  useEffect, useLayoutEffect, useReducer, useState,
} from 'react';
import {
  Errors,
  FormActions,
  MetaFormData,
  QuotedUnitProvider,
  UseShipmentFormProps,
  UseShipmentFormResult,
  FormUnitCargo, ShipmentFormData,
} from './types.d.t';
import { Price } from '../../components/wrld/form/types';

export function formReducer(state: ShipmentFormData, action: FormActions): ShipmentFormData {
  switch (action.type) {
    case 'SET_SINGLE_VALUE':
      switch (action.key) {
        case 'pickup':
          return {
            ...state,
            pickup: {
              ...state.pickup,
              [action.field]: action.value,
            },
          };
        case 'origin':
        case 'destination':
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              [action.part]: {
                ...state[action.key]?.[action.part],
                [action.field]: action.payload,
              },
            },
          };
        case 'root':
          return {
            ...state,
            [action.field]: action.payload,
          };
        default:
          return state;
      }
    case 'SET_UNIT_CARGO':
      switch (action.key) {
        case 'cargo':
          const cargo = state.cargo as FormUnitCargo;
          const currentCargo = cargo.units as FormUnitCargo['units'];
          return {
            ...state,
            cargo: {
              ...state.cargo,
              units: [...currentCargo, action.payload],
            },
          };
        case 'cargo_item':
          const itemCargo = state.cargo as FormUnitCargo;
          const currentItemCargo = itemCargo.units as FormUnitCargo['units'];
          return {
            ...state,
            cargo: {
              ...state.cargo,
              units: currentItemCargo.map(
                (unit, index) => (
                  index === action.index
                    ? {
                      ...unit,
                      [action.field]: action.payload,
                    }
                    : unit
                ),
              ),
            },
          };
        case 'cargo_dimension':
          const dimensionsCargo = state.cargo as FormUnitCargo;
          const currentDimensionsCargo = dimensionsCargo.units as FormUnitCargo['units'];
          return {
            ...state,
            cargo: {
              ...state.cargo,
              units: currentDimensionsCargo.map(
                (unit, index) => (
                  index === action.index
                    ? {
                      ...unit,
                      dimensions: {
                        ...unit.dimensions,
                        [action.field]: action.payload,
                      },
                    }
                    : unit
                ),
              ),
            },
          };
        case 'cargo_duplicate':
          const duplicableCargo = state.cargo as FormUnitCargo;
          const currentDuplicable = duplicableCargo.units as FormUnitCargo['units'];
          const duplicatedUnit = currentDuplicable[action.index];
          return {
            ...state,
            cargo: {
              ...state.cargo,
              units: [
                ...currentDuplicable.slice(0, action.index),
                duplicatedUnit,
                ...currentDuplicable.slice(action.index),
              ],
            },
          };
        case 'cargo_remove':
          const removableCargo = state.cargo as FormUnitCargo;
          const currentRemovableCargo = removableCargo.units as FormUnitCargo['units'];
          if (currentRemovableCargo.length > 1) {
            return {
              ...state,
              cargo: {
                ...state.cargo,
                units: currentRemovableCargo.filter(
                  (unit, index) => index !== action.index,
                ),
              },
            };
          }
          return state;
        default:
          return state;
      }
    case 'SET_VALUE':
      switch (action.key) {
        case 'provider':
          return {
            ...state,
            provider: action.payload,
          };
        case 'origin':
        case 'destination':
          // is full address or just id for existing location
          return {
            ...state,
            [action.key]: action.payload,
          };
        case 'cargo':
          return {
            ...state,
            cargo: action.payload,
          };
        case 'unitServices':
          const unitCargo = state.cargo as FormUnitCargo;
          return {
            ...state,
            cargo: {
              ...unitCargo,
              services: {
                ...unitCargo.services,
                [action.field]: action.payload,
              },
            },
          };
        case 'unitServicesField':
          switch (action.service) {
            case 'insurance':
              const InsuranceCargo = state.cargo as FormUnitCargo;
              return {
                ...state,
                cargo: {
                  ...InsuranceCargo,
                  services: {
                    ...InsuranceCargo.services,
                    [action.service]: {
                      ...InsuranceCargo.services[action.service],
                      [action.field]: action.payload,
                    },
                  },
                },
              };
            case 'cashOnDelivery':
              const CODCargo = state.cargo as FormUnitCargo;
              return {
                ...state,
                cargo: {
                  ...CODCargo,
                  services: {
                    ...CODCargo.services,
                    [action.service]: {
                      ...CODCargo.services[action.service],
                      [action.field]: action.payload,
                    },
                  },
                },
              };
            default:
              return state;
          }
        default:
          return state;
      }
    case 'TOGGLE_SINGLE_ACCESSIBILITY_VALUE':
      return {
        ...state,
        [action.address]: {
          ...state[action.address],
          [action.field]: action.value,
        },
      };
    default:
      return state;
  }
}

const mockUpProviders: QuotedUnitProvider[] = [
  {
    name: 'DHL Freight',
    price: {
      amount: 290,
      currency: 'CZK',
    },
    title: 'Lorem impsum dolor de selam',
    disabled: false,
  },
  {
    name: 'DHL Express',
    price: {
      amount: 8695,
      currency: 'CZK',
    },
    title: 'Lorem impsum dolor de selam',
    disabled: false,
  },
  {
    name: 'DHL 2+D Delivery',
    price: {
      amount: 2940,
      currency: 'CZK',
    },
    title: 'Nelze zvolit lorem ipsum',
    disabled: true,
  },
];

const getTotalPrice = (state: ShipmentFormData): number => {
  let total = 0;

  if (state.cargo && Object.prototype.hasOwnProperty.call(state.cargo, 'units')) {
    const cargo = state.cargo as FormUnitCargo;
    if (cargo.services.cashOnDelivery) {
      total += cargo.services.cashOnDelivery?.price?.amount || 0;
    }
    if (cargo.services.deliveryNote) {
      total += cargo.services.deliveryNote?.price?.amount || 0;
    }
    if (cargo.services.floorDelivery) {
      total += cargo.services.floorDelivery?.price?.amount || 0;
    }
    if (cargo.services.insurance) {
      total += cargo.services.insurance?.price?.amount || 0;
    }
  }

  total += state.provider?.price?.amount || 0;

  return total;
};

const dateFilled = (state: ShipmentFormData): boolean => state.pickup.date !== null;
const providerFilled = (state: ShipmentFormData): boolean => state.provider !== null;

const addressFilled = (state: ShipmentFormData, key: string, except: string[] = []): boolean => {
  let canShow = true;
  if (state[key] === null) {
    canShow = false;
  } else if (!Object.prototype.hasOwnProperty.call(state[key], 'meta')) {
    ['address', 'contact'].forEach((field) => {
      Object.keys(state[key][field]).forEach((subField) => {
        if (!except.includes(subField) && (
          state[key][field][subField] === null
          || state[key][field][subField] === ''
        )) {
          canShow = false;
        }
      });
    });
  }
  return canShow;
};

const cargoFilled = (state: ShipmentFormData): boolean => {
  let canShow = true;
  if (state.cargo === null) {
    canShow = false;
  } else if (Object.prototype.hasOwnProperty.call(state.cargo, 'units')) {
    const cargo = state.cargo as FormUnitCargo;
    if (cargo.units.length === 0) {
      canShow = false;
    } else {
      const hasOneUnit = cargo.units.some((unit) => {
        const dimensionFields = Object.keys(unit.dimensions);
        if (dimensionFields.length < 3) {
          return false;
        }
        return dimensionFields.every((field) => unit.dimensions[field] !== null
          && unit.dimensions[field] !== '');
      });
      if (!hasOneUnit) {
        canShow = false;
      }
    }
  }
  return canShow;
};

const canShowProviders = (state: ShipmentFormData, except: string[] = []): boolean => {
  const canShow = [];
  canShow.push(dateFilled(state));

  ['origin', 'destination'].forEach((key) => {
    canShow.push(addressFilled(state, key, except));
  });

  canShow.push(cargoFilled(state));

  return canShow.every((value) => value);
};

const createError = (
  id: string,
  message: string,
  setError: Dispatch<SetStateAction<Errors>>,
): void => {
  setError((prev) => {
    let errors = prev;
    // filter out first0
    errors = errors.filter((error) => error.id !== id);
    // then "update"
    return [...errors, {
      id,
      message,
    }];
  });
};

const noIssue = (
  id: string,
  setError: Dispatch<SetStateAction<Errors>>,
): void => {
  setError((prev) => {
    let errors = prev;
    errors = errors.filter((error) => error.id !== id);
    return errors;
  });
};

type PossiblePayload = string | number | undefined | null | Date | boolean;
const getPayload = (action: FormActions): PossiblePayload => {
  switch (action.type) {
    case 'SET_SINGLE_VALUE':
      switch (action.key) {
        case 'pickup':
          return action.value;
        case 'origin':
        case 'destination':
          return action.payload;
        case 'root':
          if (typeof action.payload === 'string') {
            return action.payload;
          }
          throw new Error('Invalid payload');
        default:
          throw new Error('Invalid payload');
      }
    case 'SET_UNIT_CARGO':
      switch (action.key) {
        case 'cargo_item':
        case 'cargo_dimension':
          if (typeof action.payload === 'number' || typeof action.payload === 'boolean') {
            return action.payload;
          }
          throw new Error('Invalid payload');
        case 'cargo':
        default:
          throw new Error('Invalid payload');
      }
    case 'SET_VALUE':
      switch (action.key) {
        case 'unitServicesField':
          if (typeof action.payload === 'number' || typeof action.payload === 'string') {
            return action.payload;
          }
          throw new Error('Invalid payload');
        case 'provider':
        case 'origin':
        case 'destination':
        case 'cargo':
        case 'unitServices':
        default:
          throw new Error('Invalid payload');
      }
    case 'TOGGLE_SINGLE_ACCESSIBILITY_VALUE':
      return action.value;
    default:
      throw new Error('Invalid payload');
  }
};

const validate = (
  action: FormActions,
  setErrors: Dispatch<SetStateAction<Errors>>,
): void => {
  if (action.id === null) {
    return;
  }

  try {
    const payload = getPayload(action);

    // TODO add validation rules
    switch (action.id) {
      case 'pickup-date':
        if (payload === null) {
          createError(action.id, 'Vyberte datum', setErrors);
        } else {
          noIssue(action.id, setErrors);
        }
      // eslint-disable-next-line no-fallthrough
      case 'reference':
      case 'tags':
      case 'note':
      case 'origin.street':
      case 'origin.streetNo':
      case 'origin.city':
      case 'origin.zip':
      case 'origin.countryCode':
      case 'origin.name':
      case 'origin.phone':
      case 'origin.email':
      case 'destination.street':
      case 'destination.streetNo':
      case 'destination.city':
      case 'destination.zip':
      case 'destination.countryCode':
      case 'destination.name':
      case 'destination.company':
      case 'destination.phone':
      case 'destination.email':
      case 'unit-cod-amount':
      case 'unit-cod-vs':
      case 'unit-insurance-amount':
      case action.id.match(/unit-\d-count/)?.input:
      case action.id.match(/unit-\d-height/)?.input:
      case action.id.match(/unit-\d-width/)?.input:
      case action.id.match(/unit-\d-length/)?.input:
      case action.id.match(/unit-\d-weight/)?.input:
        if (!payload) {
          createError(action.id, 'Povinné pole', setErrors);
        } else {
          noIssue(action.id, setErrors);
        }
        break;
      case 'origin.company':
      case 'pickup-time-from':
      case 'pickup-time-to':
        break;
      default:
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error', e, action);
  }
};

export const progress = (
  state: ShipmentFormData,
  setMeta: Dispatch<SetStateAction<MetaFormData>>,
  hasError: boolean,
): void => {
  const filled = {
    origin: addressFilled(state, 'origin'),
    pickup: dateFilled(state),
    destination: addressFilled(state, 'destination'),
    cargo: cargoFilled(state),
    provider: providerFilled(state),
    services: false,
    misc: false,
  };

  setMeta((prev) => {
    const newMeta = { ...prev };
    newMeta.filled = filled;
    const index = Object.values(filled).findIndex((value) => !value);
    newMeta.progress.message = Object.keys(filled)[index];
    newMeta.progress.width = ((index + 1) / Object.keys(filled).length) * 100;
    newMeta.progress.hasError = hasError;
    return newMeta;
  });
};

const useShipmentForm = (
  { initialValues, initialMeta }: UseShipmentFormProps,
): UseShipmentFormResult => {
  const [state, dispatch] = useReducer(formReducer, initialValues);

  const [meta, setMeta] = useState<MetaFormData>(initialMeta);

  const [errors, setErrors] = useState<Errors>([]);

  const [totalPrice, setTotalPrice] = useState<Price>({
    amount: 0,
    currency: 'CZK',
  });

  // const isValid = true;
  const stateToQuote: Partial<ShipmentFormData> = {
    pickup: state.pickup,
    origin: state.origin,
    destination: state.destination,
    cargo: state.cargo,
  };

  const providersLoading = false;
  const providers: QuotedUnitProvider[] = mockUpProviders;

  // TODO exportable callback should be defined here (to fetch providers from API)
  useEffect(() => { // useLayoutEffect if it doesnt work
    // todo deselect current provider using dispatch action
    // TODO - get providers from API by calling the callback above
    // pls read todo in useeffect underneath
  }, [JSON.stringify(stateToQuote)]);

  // set meta (show fields)
  useLayoutEffect(() => {
    if (canShowProviders(state, [
      'company',
      'name',
      'phone',
      'email',
      'street',
      'streetNo',
      'city',
    ])) {
      setMeta({
        ...meta,
        show: {
          ...meta.show,
          providers: true,
        },
      });

      // todo providers can be called here (and the stuff above deleted)
      //  -> canShowProviders is exactvly the condition you need to call it
    }
    if (state.provider && (!meta.show.services || !meta.show.misc)) {
      setMeta({
        ...meta,
        show: {
          ...meta.show,
          services: true,
          misc: true,
        },
      });
    }
  }, [state]);

  useLayoutEffect(() => {
    progress(state, setMeta, errors.length > 0);
  }, [state, errors.length]);

  useLayoutEffect(() => {
    setTotalPrice({
      amount: getTotalPrice(state),
      currency: 'CZK',
    });
  }, [state]);

  // TODO might fasten the form but is kinda wonky when typing *really* fast
  // TODO ...so debouncing could be a good idea
  // const dispatchAction: Dispatch<FormActions> = (action: FormActions) => {
  //   let timeout;
  //   clearTimeout(timeout);
  //   timeout = setTimeout(() => {
  //     dispatch(action);
  //   }, 10);
  // };
  // dispatch -> dispatchAction if the above is uncommented
  // TODO merge with validation:
  const dispatchAction: Dispatch<FormActions> = (action: FormActions) => {
    validate(action, setErrors);
    dispatch(action);
  };
  const dispatchMemorized: Dispatch<FormActions> = useCallback(dispatchAction, []);

  return {
    state,
    meta,
    dispatch: dispatchMemorized,
    totalPrice,
    errors,
    quoting: {
      providers,
      loading: providersLoading,
    },
  };
};

export default useShipmentForm;
