import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';

import SelectableFilterPopover from '../../../misc/SelectableFilterPopover';
import useAddressAutocomplete from '../../../../../hooks/filters/useAddressAutocomplete';
import { getActiveValues, useFilterContext } from '../../../../../hooks/filters/useFilters';

type AutocompleteFilterSegmentProps = {
  name: string
};

const AutocompleteFilterSegment = ({ name }: AutocompleteFilterSegmentProps): React.ReactElement => {
  const [searchValue, setSearchValue] = useState('');
  const { res } = useAddressAutocomplete(searchValue);

  const {
    state,
    dispatch,
  } = useFilterContext();
  const activeFilters = getActiveValues(state);
  const active = activeFilters.filter((filter) => filter.name === name);

  const count = active.length;
  let first = '';
  if (Array.isArray(active) && active.length > 0) {
    first = active[0].value;
  }

  return (
    <SelectableFilterPopover name={name} label={count > 0 ? first : name} count={count}>
      <div className="p-4">
        <h4 className="font-semibold">
          Zadejte
          {' '}
          {name}
          <input
            type="text"
            name={`autocomplete-${name}`}
            id={`autocomplete-${name}`}
            className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="Zadejte adresu"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </h4>
        <div>
          {res.map((result) => (
            <div
              role="banner"
              key={JSON.stringify(result)}
              className="cursor-pointer mt-3 px-3 md:px-4 py-2 mb-1.5 border md:rounded bg-white hover:bg-gray-50"
              onClick={() => dispatch({
                name,
                payload: result,
              })}
            >
              <div className="font-semibold">
                <Highlighter searchWords={searchValue.toLowerCase().split(' ')} textToHighlight={result.value.street} />
              </div>
              <div>
                <Highlighter searchWords={searchValue.toLowerCase().split(' ')} textToHighlight={result.value.city} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </SelectableFilterPopover>
  );
};

export default AutocompleteFilterSegment;
