import React from 'react';
import ContentLoader from 'react-content-loader';
import { useNavigate, Link } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { NetworkStatus } from '@apollo/client';
import { PlusSmIcon } from '@heroicons/react/outline';
import Filters from '../../components/wrld/shipment/filters/Filters';
import useShipments from '../../hooks/store/useShipments';
import useFilters, { ContextProvider, getInitialValues, getQueryFilters } from '../../hooks/filters/useFilters';
import filters from './ShipmentsSearch.filters';
import ScrollToTop from '../../components/wrld/misc/ScrollToTop';
import ShipmentCard from '../../components/wrld/shipment/list/ShipmentCard';
import EndHoverSegment from '../../components/wrld/shipment/list/segments/EndHoverSegment';

const ShipmentCardLoader = (props: any): React.ReactElement => (
  <ContentLoader
    speed={1}
    viewBox="0 0 1216 66"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: '100%', border: '1px solid #e5e7eb', borderRadius: '4px' }}
    {...props}
  >
    <rect x="22" y="10" rx="0" ry="0" width="38" height="48" />
    <circle cx="94" cy="34" r="20" />
    <rect x="132" y="11" rx="0" ry="0" width="160" height="21" />
    <rect x="132" y="42" rx="0" ry="0" width="103" height="17" />
    <circle cx="515" cy="22" r="7" />
    <circle cx="515" cy="46" r="7" />
    <rect x="514" y="25" rx="0" ry="0" width="2" height="24" />
    <rect x="530" y="14" rx="0" ry="0" width="260" height="15" />
    <rect x="530" y="38" rx="0" ry="0" width="260" height="15" />
    <rect x="1117" y="13" rx="0" ry="0" width="83" height="22" />
    <rect x="1100" y="42" rx="0" ry="0" width="100" height="12" />
  </ContentLoader>
);

const ShipmentsSearch = (): React.ReactElement => {
  const count = 10;
  const navigate = useNavigate();
  const redirectToShipment = (id: string): void => {
    navigate(`shipments/${id}`);
  };

  // Todo build filters with values (and with values from URL)

  const filtration = useFilters(filters, getInitialValues(filters));

  const {
    shipments, isLoading, networkStatus, endCursor, fetchMore, hasNextPage, error,
  } = useShipments({
    filters: getQueryFilters(filtration.state),
  });

  // eslint-disable-next-line no-console
  console.log(filtration);

  const loadMore = async () => {
    await fetchMore({
      variables: {
        after: endCursor,
      },
    });
  };

  const isRefetching = networkStatus === NetworkStatus.refetch;

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 100px 0px',
  });

  return (
    <div className="min-h-full bg-gray-100">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 pt-20 max-h-full">
        <Link to="shipments/new">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusSmIcon className="h-6 w-6 mr-2" aria-hidden="true" />
            Vytvořit přepravu
          </button>
        </Link>

        <ContextProvider data={filtration}>
          <Filters />
        </ContextProvider>

        {!isRefetching && shipments.map((shipment) => (
          <ShipmentCard
            key={shipment.id}
            cardHover={<EndHoverSegment shipmentId={shipment.id} />}
            onClick={() => {
              const selection = window.getSelection();
              // eslint-disable-next-line no-console
              console.log('selection', selection);
              if (selection?.toString().length === 0 && selection?.focusOffset > 0) {
                redirectToShipment(shipment.id);
              }
            }}
            shipment={shipment}
          />
        ))}

        {(isLoading || isRefetching || hasNextPage) && (
          <div ref={sentryRef} className="space-y-2">
            {Array(count).fill(null).map((_, i) => i).map((i) => <ShipmentCardLoader key={`shipment-skeleton-loader-${i}`} />)}
          </div>
        )}
        <ScrollToTop />
      </div>
    </div>
  );
};

export default ShipmentsSearch;
