import React from 'react';
import { Quote } from '../types/provider';

import { ProviderLogo } from './provider/ProviderSection';

const QuoteCard = ({ quote }: { quote: Quote }): React.ReactElement => (
  <div className="flex justify-between w-full px-5">
    <div className="flex flex-row space-x-2">
      {quote.providers.map((prov, idx) => (
        <>
          <ProviderLogo url={prov.logoUrl} displayName={prov.displayName} />
          {(quote.providers.length !== idx + 1) ? (<span>/</span>) : null}
        </>
      ))}
    </div>
    <div>
      {`${quote.price.total} Kč`}
    </div>
  </div>
);

export default QuoteCard;
