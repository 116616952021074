import React, { Dispatch, useState } from 'react';
import ServiceTemplate from './ServiceTemplate';
import { ServiceItem } from '../types';
import Field from '../control/Field';
import {
  Errors,
  FormActions,
  FormUnitCargo,
  ShipmentFormData,
} from '../../../../hooks/forms/types.d.t';

const cashOnDeliveryOptions: ServiceItem[] = [
  {
    id: 1,
    title: 'Bez dobírky',
    description: 'Nepřeji si dobírku.',
    value: false,
    price: null,
  },
  {
    id: 2,
    title: 'Dobírka',
    description: 'V případě zájmu o dobírku doplňte hodnotu, měnu a variabilní symbol, kterým budete moci poté platbu spárovat.',
    value: true,
    price: {
      amount: 29,
      currency: 'CZK',
    },
  },
];

type CashOnDeliveryProps = {
  dispatch: Dispatch<FormActions>,
  state: ShipmentFormData,
  errors: Errors,
};

const CashOnDelivery = ({ dispatch, state, errors }: CashOnDeliveryProps): React.ReactElement => {
  const [
    selectedCashOnDeliveryOption,
    setSelectedCashOnDeliveryOption,
  ] = useState(cashOnDeliveryOptions[0]);

  const setState = (item: ServiceItem): void => {
    setSelectedCashOnDeliveryOption(item);
    dispatch({
      id: null,
      type: 'SET_VALUE',
      key: 'unitServices',
      field: 'cashOnDelivery',
      payload: cashOnDeliveryOptions.indexOf(item) === 0 ? undefined : {
        price: item.price,
        currency: 'CZK',
      },
    });
  };

  const cargo = state.cargo as FormUnitCargo;

  return (
    <>
      <ServiceTemplate
        setSelectedItem={setState}
        selectedItem={selectedCashOnDeliveryOption}
        items={cashOnDeliveryOptions}
        title="Dobírka"
        iconName="payments"
      />
      {cashOnDeliveryOptions.indexOf(selectedCashOnDeliveryOption) === 1 && (
        <div className="w-full">
          <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-x-4">
            <div className="hidden col-span-1 md:block" />
            <div className="col-span-2 mx-2 mb-4 md:mr-6 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-2">
              <Field
                isRequired
                errors={errors}
                id="unit-cod-amount"
                name="unit-cod-amount"
                label="Hodnota"
                placeholder="0.00"
                type="number"
                value={cargo.services.cashOnDelivery?.amount}
                onNumberChange={(value) => {
                  dispatch({
                    id: 'unit-cod-amount',
                    type: 'SET_VALUE',
                    key: 'unitServicesField',
                    service: 'cashOnDelivery',
                    field: 'amount',
                    payload: value,
                  });
                }}
              >
                <div className="absolute inset-y-0 right-0 flex items-center">
                  <label htmlFor="currency" className="sr-only">
                    Měna
                  </label>
                  <select
                    id="unit-cod-currency"
                    name="unit-cod-currency"
                    className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                    value={cargo.services.cashOnDelivery?.currency || 'CZK'}
                    onChange={(e) => {
                      dispatch({
                        id: null,
                        type: 'SET_VALUE',
                        key: 'unitServicesField',
                        service: 'cashOnDelivery',
                        field: 'currency',
                        payload: e.target.value,
                      });
                    }}
                  >
                    <option value="CZK">Kč</option>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </Field>
              <Field
                isRequired
                errors={errors}
                id="unit-cod-vs"
                name="unit-cod-vs"
                label="Variabilní symbol"
                placeholder="VS"
                type="number"
                value={cargo.services.cashOnDelivery?.vs}
                onNumberChange={(value) => {
                  dispatch({
                    id: 'unit-cod-vs',
                    type: 'SET_VALUE',
                    key: 'unitServicesField',
                    service: 'cashOnDelivery',
                    field: 'vs',
                    payload: value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CashOnDelivery;
