import React from 'react';
// import CloseableBadge from '../../../misc/CloseableBadge';
// import { useFilterContext } from '../../../../../hooks/filters/useFilters';

export type ActiveFiltersSegmentProps = {
  children: React.ReactNode
};

const ActiveFiltersSegment = ({ children }: ActiveFiltersSegmentProps): React.ReactElement => (
  <div className="border-gray-200 py-4 px-4 border-gray-400 bg-gray-200 flex items-center rounded-b-lg border-t">
    <div className="pr-4 border-r border-gray-400 mr-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Filters</div>
    <div className="flex items-center space-x-3">
      {/* {activeFilters.map((filter) => ( */}
      {/*  <CloseableBadge */}
      {/*    data={filter} */}
      {/*    close={close} */}
      {/*    className="mr-2" */}
      {/*    key={`filter-${filter.type}-${filter.value}`} */}
      {/*  /> */}
      {/* ))} */}
      {children}
    </div>
  </div>
);

export default ActiveFiltersSegment;
