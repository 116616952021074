import React from 'react';
import { SimpleCargoType, SimplePriceType } from '../../../types/list';

export type EndSegmentProps = {
  price: SimplePriceType,
  cargo: SimpleCargoType,
  hasHover?: boolean,
  hoverElement: JSX.Element,
  isHovered?: boolean
};

const EndSegment = ({
  price, cargo, hasHover, hoverElement, isHovered,
}: EndSegmentProps): React.ReactElement => {
  const empty = cargo.boxes === 0 && cargo.pallets === 0;

  let text;
  if (empty) {
    text = (
      <div className="font-light text-xs md:text-sm text-red-600">
        Chybí náklad
      </div>
    );
  } else {
    text = (
      <div className="font-light text-xs md:text-sm text-gray-600">
        {cargo.pallets}
        {' '}
        pallet
        {' '}
        {cargo.boxes}
        {' '}
        boxes
      </div>
    );
  }

  if (hasHover && isHovered) {
    return (hoverElement);
  }
  return (
    <div className="text-right">
      <div className="text-md md:text-xl font-bold">
        <span>{price.total}</span>
        {' '}
        <span className="text-sm font-semibold uppercase">{price.currency}</span>
      </div>
      {text}
    </div>
  );
};

EndSegment.defaultProps = {
  hasHover: true,
  isHovered: false,
};

export default EndSegment;
