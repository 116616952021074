import React from 'react';
import LabelsSegment from './LabelsSegment';

export type DescriptionSegmentProps = {
  labels: string[],
  displayName: string,
  reference: string
};

const DescriptionSegment = ({
  labels,
  displayName,
  reference,
}: DescriptionSegmentProps): React.ReactElement => (
  <div className="w-full flex justify-between items-center">
    <div className="flex items-center">
      logo
      <div>
        <div className="mb-1 mt-1.5 md:mt-0 flex flex-col md:flex-row align-middle md:items-baseline">
          <div className="text-xs md:text-base font-medium text-indigo-600 truncate">{displayName}</div>
          <div className="mt-1 md:mt-0 md:ml-2 text-gray-400 font-light text-xs uppercase">{reference}</div>
        </div>
        <div className="mt-1 flex-shrink-0 hidden md:flex">
          <LabelsSegment labels={labels} />
        </div>
      </div>
    </div>
  </div>
);

export default DescriptionSegment;
