import React, { MouseEventHandler } from 'react';
import { DotsHorizontalIcon, PrinterIcon, XIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';

export type EndHoverSegmentProps = {
  shipmentId: string,
};

export type ButtonProps = {
  type: 'detail' | 'print' | 'cancel',
  onClick: MouseEventHandler,
};

const Button = ({ type, onClick }: ButtonProps): React.ReactElement => {
  let icon;
  let color = 'gray';

  switch (type) {
    case 'detail':
      icon = <DotsHorizontalIcon width="14" />;
      break;
    case 'print':
      icon = <PrinterIcon width="14" />;
      break;
    case 'cancel':
      color = 'red';
      icon = <XIcon width="14" />;
      break;
    default:
      icon = <></>;
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={`inline-flex items-center px-2 py-2 border border-${color}-300 shadow-sm text-sm font-medium rounded-md text-${color}-700 bg-white hover:bg-${color}-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500`}
    >
      {icon}
    </div>
  );
};

const EndHoverSegment = ({ shipmentId }: EndHoverSegmentProps): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="absolute top-0 bottom-0 my-auto right-1 h-8 flex space-x-2">
        <Button
          type="detail"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/shipments/${shipmentId}`);
            // eslint-disable-next-line no-console
            console.log('detail', shipmentId);
          }}
        />
        <Button
          type="print"
          onClick={(e) => {
            e.preventDefault();
            // eslint-disable-next-line no-console
            console.log('print', shipmentId);
          }}
        />
        <Button
          type="cancel"
          onClick={(e) => {
            e.preventDefault();
            // eslint-disable-next-line no-console
            console.log('cancel', shipmentId);
          }}
        />
      </div>
    </div>
  );
};

export default EndHoverSegment;
