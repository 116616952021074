import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { FormUnitItemType } from '../../../../hooks/forms/types.d.t';

type CargoTypeOption = {
  title: string,
  image: string,
  description: string,
  value: FormUnitItemType,
  current: boolean,
};

const cargoTypes: CargoTypeOption[] = [
  {
    title: 'Box',
    image: '/assets/cargo/box.png',
    description: 'This job posting can be viewed by anyone who has the link.',
    value: FormUnitItemType.BOX,
    current: true,
  },
  {
    title: 'Pallet',
    image: '/assets/cargo/stack.png',
    description: 'This job posting can be viewed by anyone who has the link.',
    value: FormUnitItemType.PALLET,
    current: true,
  },
  {
    title: 'EURPallet',
    image: '/assets/cargo/eur.png',
    description: 'This job posting will no longer be publicly accessible.',
    value: FormUnitItemType.EUR_PALLET,
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type CargoSelectFieldProps = {
  value: FormUnitItemType,
  onChange: (value: FormUnitItemType) => void,
};

const CargoSelectField = ({ value, onChange }: CargoSelectFieldProps): React.ReactElement => {
  // const [
  //   selected,
  //   setSelected,
  // ] = useState<FormUnitItemType>(value);

  const handleChange = (v: FormUnitItemType) => {
    onChange(v);
  };

  return (
    <Listbox value={value} onChange={handleChange} horizontal>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change published status</Listbox.Label>
          <div className="relative mt-1">
            <div className="inline-flex rounded-md border border-gray-300">
              <div className="relative z-0 inline-flex shadow-sm rounded-md">
                <Listbox.Button className="relative inline-flex items-center bg-white p-2 rounded-md text-sm font-medium hover:bg-gray-300 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">
                  <span>
                    <img src={`/assets/cargo/${value}.png`} alt={value} className="h-5 w-5" />
                  </span>
                  <span className="sr-only">Change published status</span>
                  <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {cargoTypes.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) => classNames(
                      active ? 'text-white bg-indigo-500' : 'text-gray-900',
                      'cursor-pointer select-none relative p-4 text-sm',
                    )}
                    value={option.value}
                  >
                    {({ selected: s, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={s ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                          {s ? (
                            <span className={active ? 'text-white' : 'text-indigo-500'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'mt-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CargoSelectField;
