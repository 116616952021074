import React from 'react';
import { FormUnitCargo, FormUnitItem } from '../../../../hooks/forms/types.d.t';

type UnitCargoProps = {
  cargo: FormUnitCargo;
};

type MappedUnits = {
  [key: string]: FormUnitItem[];
};

const UnitCargo = ({ cargo }: UnitCargoProps): React.ReactElement => {
  const mapped: MappedUnits = cargo.units.reduce((r, a) => {
    // eslint-disable-next-line no-param-reassign
    r[a.type] = r[a.type] || [];
    r[a.type].push(a);
    return r;
  }, Object.create(null));

  let totalCount = 0;
  let totalWeight = 0;
  let totalVolume = 0;

  cargo.units.forEach((unit) => {
    totalCount += unit.count;
    totalWeight += ((unit.weight || 0) * unit.count) || 0;
    if (unit
      && unit.dimensions
      && unit.dimensions.length
      && unit.dimensions.height
      && unit.dimensions.width
    ) {
      totalVolume += (
        (unit.dimensions.length
          * unit.dimensions.height
          * unit.dimensions.width)
        * unit.count
      ) || 0;
    }
  });

  totalVolume /= 1000000;

  return (
    <>
      <div className="flex grid grid-cols-3 text-center border border-gray-200">
        <div className="bg-gray-200 font-semibold">
          Počet:
        </div>
        <div className="bg-gray-200 font-semibold">
          Objem:
        </div>
        <div className="bg-gray-200 font-semibold">
          Hmotnost:
        </div>
        <div>
          {totalCount}
          ks
        </div>
        <div>
          {totalVolume}
          m³
        </div>
        <div>
          {totalWeight}
          kg
        </div>
      </div>
      <div className="pt-2">
        {Object.keys(mapped).map((type) => (
          <div key={`recapitulation-${type}-units`}>
            {mapped[type].map((unit, id) => (
              <div
              // eslint-disable-next-line react/no-array-index-key
                key={`recapitulation-item-unit-${id}`}
                className="grid grid-cols-12 items-baseline"
              >
                <div className="col-span-1">
                  <div>{`${unit.count}x`}</div>
                </div>
                <div className="col-span-3">
                  <div>{unit.type}</div>
                </div>
                <div className="col-span-6 text-xs">
                  {
                  unit.dimensions.length && unit.dimensions.height && unit.dimensions.width
                  && <div>{`(${unit.dimensions.length}cm x ${unit.dimensions.height}cm x ${unit.dimensions.width}cm)`}</div>
                }
                </div>
                <div className="col-span-2 text-right">
                  {
                  unit.weight && <div>{`${unit.weight * unit.count}kg`}</div>
                }
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default UnitCargo;
