import React from 'react';
import {
  RouteObject,
  useRoutes,
  Link,
} from 'react-router-dom';
import ShipmentDetail from './pages/shipments/detail/ShipmentDetail';
import Navbar from './components/Navbar';
import ShipmentsCreate from './pages/shipments/ShipmentsCreate';
import ShipmentsSearch from './pages/shipments/ShipmentsSearch';
import ShipmentsTimeline from './pages/shipments/ShipmentsTimeline';

const Home = () => (
  <div>
    <ul>
      <li><Link to="/shipments">list</Link></li>
      <li>
        <Link to="/shipments/61d97b163ca1fca4c1b84e0a">detail</Link>
        {' '}
      </li>
    </ul>
  </div>
);

const routes: RouteObject[] = [
  { path: '/', element: <Home /> },
  { path: '/shipments/', element: <ShipmentsSearch /> },
  { path: '/timeline/', element: <ShipmentsTimeline /> },
  { path: '/shipments/new', element: <ShipmentsCreate /> },
  { path: '/shipments/:id', element: <ShipmentDetail /> },
];

const App = (): React.ReactElement => {
  const element = useRoutes(routes);
  return (
    <div>
      <div className="h-screen">
        <header className="absolute top-0 z-50 w-full">
          <Navbar />
        </header>
        {element}
      </div>
    </div>
  );
};

export default App;
