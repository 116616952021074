import { MessageTimestamp, StreamMessage, useMessageContext } from 'stream-chat-react';
import React, { useState } from 'react';
import {
  ArchiveIcon,
  ClockIcon,
  CurrencyEuroIcon,
  OfficeBuildingIcon,
  PencilIcon,
  StatusOnlineIcon,
  TagIcon,
} from '@heroicons/react/solid';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { Price, ProviderKind, ProviderStatus } from '../../components/wrld/types/provider';
import { Location } from '../../components/wrld/types/location';
import { ProviderLogo } from '../../components/wrld/shipment/provider/ProviderSection';
import { PriceSection } from '../../components/wrld/shipment/SelectedProviderSection';
import displayPrice from '../../components/wrld/shipment/provider/displayPrice';

type MessageSilent_ShipmentCreated = {
  kind: 'ShipmentCreated',
  before: never,
  after: never,
};
type MessageSilent_NameChanged = {
  kind: 'NameChanged',
  before: string,
  after: string,
};
type MessageSilent_PickupLocationChanged = {
  kind: 'PickupLocationChanged',
  before: Location,
  after: Location,
};
type MessageSilent_DeliveryLocationChanged = {
  kind: 'DeliveryLocationChanged',
  before: Location,
  after: Location,
};
type Time = {
  from: string,
  to: string,
};
type MessageSilent_PickupTimeChanged = {
  kind: 'PickupTimeChanged',
  before: Time,
  after: Time,
};
type MessageSilent_DeliveryTimeChanged = {
  kind: 'DeliveryTimeChanged',
  before: Time,
  after: Time,
};
type MessageSilent_StatusChanged = {
  kind: 'StatusChanged',
  status: ProviderStatus,
};
type MessageSilent_ProviderStatusChanged = {
  kind: 'ProviderStatusChanged',
  provider: ProviderKind,
  before: ProviderStatus,
  after: ProviderStatus,
};
type MessageSilent_PriceChanged = {
  kind: 'PriceChanged',
  before: Price,
  after: Price,
  reason: string,
};

type MessageSilentType = MessageSilent_ShipmentCreated
| MessageSilent_NameChanged
| MessageSilent_PickupLocationChanged
| MessageSilent_DeliveryLocationChanged
| MessageSilent_PickupTimeChanged
| MessageSilent_DeliveryTimeChanged
| MessageSilent_StatusChanged
| MessageSilent_ProviderStatusChanged
| MessageSilent_PriceChanged;

type BasicMessageProps = {
  userName: string,
  message: string,
  human?: boolean,
  quotes?: boolean,
  userId?: string,
  image?: string,
  from?: string,
  to?: string,
};

const BaseMessage = ({
  userName,
  message,
  userId,
  quotes,
  image,
  human,
  from,
  to,
}: BasicMessageProps): React.ReactElement => (
  <span className="items-center">
    {human ? 'Uživatel ' : ''}
    {image && (
    <span className="mr-2">
      <ProviderLogo url={image} inline />
    </span>
    )}
    <span className="font-bold">
      {userId ? (
        <a href={`#${userId}`}>{userName}</a>
      ) : (
        <>{userName}</>
      )}
    </span>
    {' '}
    {message}
    {
        from && (
          <>
            {' '}
            z
            {' '}
            {quotes && (<>&quot;</>)}
            <span className="font-bold">{from}</span>
            {quotes && (<>&quot;</>)}
            {' '}
          </>
        )
      }
    {
        to && (
          <>
            {from ? '' : ' '}
            na
            {' '}
            {quotes && (<>&quot;</>)}
            <span className="font-bold">{to}</span>
            {quotes && (<>&quot;</>)}
          </>
        )
      }
    .
  </span>
);

BaseMessage.defaultProps = {
  userId: null,
  from: null,
  image: null,
  human: true,
  quotes: true,
  to: null,
};

type MessageSilentProps = {
  element: JSX.Element,
  icon: JSX.Element,
  detail?: JSX.Element,
};

const MessageSilent = ({
  element, detail, icon,
}: MessageSilentProps): React.ReactElement => {
  const { message } = useMessageContext();
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
            {icon}
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-gray-500">
          <div>
            <span className="mr-0.5">
              {element}
            </span>
          </div>
          {showMore && (
            <div>
              <span className="mr-0.5">
                {detail}
              </span>
            </div>
          )}
          <div className="text-gray-400">
            <span className="whitespace-nowrap">
              <MessageTimestamp calendar />
            </span>
            {detail && (
              <>
                {' '}
                •
                {' '}
                <span
                  role="button"
                  tabIndex={0}
                  className="text-sm text-gray-400 cursor-pointer underline decoration-1 underline-offset-4 decoration-dashed decoration-gray-400 hover:text-gray-700 hover:decoration-gray-700"
                  onClick={(): void => setShowMore(!showMore)}
                >
                  {showMore ? 'skrýt' : 'zobrazit'}
                  {' '}
                  detail
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

MessageSilent.defaultProps = {
  detail: null,
};

type PriceChangedDetailProps = {
  before?: Price,
  after?: Price,
  reason?: string,
};

const PriceChangedDetail = ({
  after,
  before,
  reason,
}: PriceChangedDetailProps): React.ReactElement => {
  if (!after || !before) {
    return <></>;
  }

  return (
    <div className="mt-2">
      <div className="grid grid-cols-2 space-x-1">
        <div className="bg-red-50 rounded-md p-2">
          <div className="font-bold flex justify-between items-center">
            <div>Cena před:</div>
            <div><ArrowRightIcon className="h-6 w-6" /></div>
          </div>
          <div className="text-xs">
            <PriceSection price={before} variant="large" />
          </div>
        </div>
        <div className="p-2">
          <div className="font-bold">Cena po:</div>
          <div className="text-xs">
            <PriceSection price={after} variant="large" />
          </div>
        </div>
      </div>
      {reason !== null && (
        <div className="mt-2">
          <span className="font-bold">Důvod:</span>
          <span className="ml-2">{reason}</span>
        </div>
      )}
    </div>
  );
};

PriceChangedDetail.defaultProps = {
  before: null,
  after: null,
  reason: null,
};

const LocationChangedDetail = (): React.ReactElement => (
  <div>
    zmenena lokace z x na y
  </div>
);

type useSilentMessageResult = {
  silent: React.ReactElement,
  ignore: boolean,
  kind?: string,
};

function useSilentMessage(message: StreamMessage): useSilentMessageResult {
  const { data, user } = message;
  const typedData = data as MessageSilentType;
  const className = 'h-5 w-5 text-gray-500';

  // eslint-disable-next-line no-console
  // console.log(message);

  const name = user?.name ?? 'Anonymní';
  const image = user?.image;
  const id = user?.id;

  let element = <></>;
  let detail;
  let icon = <TagIcon className={className} aria-hidden="true" />;

  if (!data) {
    return {
      silent: element,
      ignore: true,
    };
  }

  let ignore = false;
  let msg;

  switch (typedData.kind) {
    case 'ShipmentCreated':
      break;
    case 'NameChanged':
      icon = <PencilIcon className={className} aria-hidden="true" />;
      // eslint-disable-next-line no-console
      // console.log('NameChanged', typedData);
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message="změnil název rozvozu"
          from={typedData.before}
          to={typedData.after}
        />
      );
      break;
    case 'PickupLocationChanged':
      icon = <OfficeBuildingIcon className={className} aria-hidden="true" />;
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message="změnil lokaci vyzvednutí"
        />
      );
      detail = <LocationChangedDetail />;
      break;
    case 'DeliveryLocationChanged':
      icon = <OfficeBuildingIcon className={className} aria-hidden="true" />;
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message="změnil lokaci doručení"
        />
      );
      detail = <LocationChangedDetail />;
      break;
    case 'PickupTimeChanged':
      icon = <ClockIcon className={className} aria-hidden="true" />;
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message="změnil čas doručení"
        />
      );
      break;
    case 'DeliveryTimeChanged':
      icon = <ClockIcon className={className} aria-hidden="true" />;
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message="změnil čas doručení"
        />
      );
      break;
    case 'StatusChanged':
      icon = <StatusOnlineIcon className={className} aria-hidden="true" />;
      // todo switch status type and set message accordingly
      // eslint-disable-next-line no-console
      console.log(message);
      switch (typedData.status) {
        case ProviderStatus.Booked:
          msg = 'zadal objednávku';
          break;
        case ProviderStatus.Canceled:
          msg = 'zrušil rozvoz';
          break;
        default:
          msg = `změnil status rozvozu (${typedData.status})`;
      }
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message={msg}
        />
      );
      break;
    case 'ProviderStatusChanged':
      icon = <ArchiveIcon className={className} aria-hidden="true" />;
      switch (typedData.after) {
        case ProviderStatus.Prepared:
          msg = 'převzal objednávku';
          break;
        case ProviderStatus.Delivered:
          msg = 'doručil objednávku';
          break;
        default:
          msg = `změnil status rozvozu (${typedData.after})`;
      }
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message={msg}
          human={false}
          image="https://www.dpdhl-brands.com/dhl/en/guides/design-basics/logo-and-claim/_jcr_content/parsys/imagetext/imagetextcontainer/image.coreimg.100.1024.png/1591965147742/versions-01.png"
        />
      );
      break;
    case 'PriceChanged':
      icon = <CurrencyEuroIcon className={className} aria-hidden="true" />;
      element = (
        <BaseMessage
          userName={name}
          userId={id}
          message="změnil cenu rozvozu"
          from={displayPrice(typedData?.before?.total, typedData?.before?.currency)}
          to={displayPrice(typedData?.after?.total, typedData?.after?.currency)}
          quotes={false}
        />
      );
      detail = (
        <PriceChangedDetail
          before={typedData?.before}
          after={typedData?.after}
          reason={typedData.reason}
        />
      );
      break;
    default:
      ignore = true;
  }

  return {
    silent: <MessageSilent
      element={element}
      detail={detail}
      icon={icon}
    />,
    ignore,
    kind: typedData.kind,
  };
}

export default useSilentMessage;
