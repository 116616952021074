import React, { useEffect, useState } from 'react';
import { ChevronDoubleUpIcon } from '@heroicons/react/solid';

const ScrollToTop = (): React.ReactElement => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => toggleVisibility());
    return window.removeEventListener('scroll', () => toggleVisibility());
  }, []);

  return (
    <div className="fixed bottom-12 md:bottom-4 right-0 md:right-4 z-50 cursor-pointer">
      {isVisible
      && (
      <div role="dialog" onClick={scrollToTop}>
        <ChevronDoubleUpIcon height={45} className="text-gray-300" />
      </div>
      )}
    </div>
  );
};

export default ScrollToTop;
