import React from 'react';

type AnotherButtonProps = {
  textColor: string,
  children: React.ReactNode,
};

const AnotherButton = ({ textColor, children }: AnotherButtonProps) => (
  <button type="button" style={{ color: textColor }}>
    { children }
  </button>
);

export default AnotherButton;
