import React from 'react';
import { RadioGroup } from '@headlessui/react';
// import Undraw from 'react-undraw';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ServiceItem } from '../types';

type ServiceProps = {
  iconName?: string;
  items: ServiceItem[],
  title: string,
  selectedItem: ServiceItem | null,
  setSelectedItem: (item: ServiceItem) => void,
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ServiceTemplate = (props: ServiceProps): React.ReactElement => {
  const {
    iconName, items, title, selectedItem, setSelectedItem,
  } = props;

  return (
    <div className="bg-white border-t py-6 px-4 space-x-6 sm:p-6">
      <RadioGroup value={selectedItem} onChange={setSelectedItem}>
        <RadioGroup.Label className="text-base font-medium text-gray-900">{title}</RadioGroup.Label>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {items.map((item, idx) => (
            <RadioGroup.Option
              key={item.id}
              value={item}
              className={({ checked, active }) => classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-gray-500' : '',
                idx === 0 ? 'sm:col-span-1' : 'sm:col-span-2',
                'relative bg-white border rounded-lg shadow-sm hover:shadow-md p-4 flex cursor-pointer focus:outline-none',
              )}
            >
              {({ checked, active }) => (
                <>
                  <div className="flex-1 flex">
                    <div className="flex flex-col w-full">
                      <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                        {item.title}
                      </RadioGroup.Label>
                      {iconName && (
                      <RadioGroup.Description
                        as="span"
                        className={classNames(
                          idx === 0 ? 'opacity-25' : '',
                          'mt-1 flex items-center text-sm text-gray-500 py-5',
                        )}
                      >
                        {/* <Undraw name={iconName} height="100px" primaryColor={idx === 0 ? 'black' : undefined} /> */}
                      </RadioGroup.Description>
                      )}
                      <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                        {item.description}
                      </RadioGroup.Description>
                      {item.price && (
                        <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                          <span className={classNames(
                            !checked ? 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800' : '',
                          )}
                          >
                            {checked ? '' : '+ '}
                            {item.price.amount}
                            {' '}
                            {item.price.currency}
                          </span>
                        </RadioGroup.Description>
                      )}
                    </div>
                  </div>
                  <CheckCircleIcon
                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-gray-600')}
                    aria-hidden="true"
                  />
                  <div
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-gray-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none',
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

ServiceTemplate.defaultProps = {
  iconName: undefined,
};

export default ServiceTemplate;
