import { ProviderStatus } from '../../components/wrld/types/provider';
import { ProviderStatus as GQLProviderStatus } from '../../__generated__/globalTypes.d';

const gqlMapping: Record<GQLProviderStatus, ProviderStatus> = {
  [GQLProviderStatus.PREPARED]: ProviderStatus.Prepared,
  [GQLProviderStatus.ON_THE_WAY]: ProviderStatus.OnTheWay,
  [GQLProviderStatus.BOOKED]: ProviderStatus.Booked,
  [GQLProviderStatus.DELIVERED]: ProviderStatus.Delivered,
  [GQLProviderStatus.CANCELED]: ProviderStatus.Canceled,
} as const;

const modelMapping: Record<ProviderStatus, GQLProviderStatus> = {
  [ProviderStatus.Prepared]: GQLProviderStatus.PREPARED,
  [ProviderStatus.OnTheWay]: GQLProviderStatus.ON_THE_WAY,
  [ProviderStatus.Booked]: GQLProviderStatus.BOOKED,
  [ProviderStatus.Delivered]: GQLProviderStatus.DELIVERED,
  [ProviderStatus.Canceled]: GQLProviderStatus.CANCELED,
  [ProviderStatus.BookingRequested]: GQLProviderStatus.BOOKED,
} as const;

export function convertGQLProviderStatus(status: GQLProviderStatus): ProviderStatus {
  return gqlMapping[status];
}

export function convertProviderStatus(status: ProviderStatus): GQLProviderStatus {
  return modelMapping[status];
}
