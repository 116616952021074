import React, { useEffect, useState } from 'react';

import Select, { OnChangeValue, StylesConfig } from 'react-select';
import countryList from '../../../../utils/countryList';
import { Errors, Error } from '../../../../hooks/forms/types.d.t';

type Country = {
  label: string,
  alpha_2: string,
  value: string,
  id: number,
  avg_lat: number,
  avg_lng: number,
};

type IsMulti = true;
const customStyles: StylesConfig<Country, IsMulti> = {
  control: (provided) => ({
    ...provided,
    borderWidth: '1px',
    borderColor: 'rgba(209, 213, 219, 1)',
    borderRadius: '0.375rem',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    padding: '0rem 0.2rem 0rem 0.2rem',
  }),
};

type CountrySelectListProps = {
  label: string,
  id: string,
  name: string,
  wrapperClass: string,
  placeholder: string,
  value: string,
  isRequired: boolean,
  errors: Errors,
  onChange: (value: string) => void,
};

const CountrySelectList = ({
  id, name, label, wrapperClass, placeholder, onChange, isRequired, errors, value: val,
}: CountrySelectListProps): React.ReactElement => {
  const handleChange = (
    newValue: OnChangeValue<Country, true>,
  ) => {
    const { value } = newValue as unknown as Country;
    onChange(value);
  };

  const [error, setError] = useState<Error | undefined>(undefined);
  useEffect(() => {
    setError(errors.find((e) => e.id === id));
  }, [errors]);

  const countryValue = countryList.find((country) => country.value === val);

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="flex justify-between">
          <label htmlFor={id} className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <span
            className="text-red-600 leading-4"
            id={`${id}-hint`}
          >
            {isRequired ? '*' : ''}
          </span>
        </div>
      )}
      <Select
        id={id}
        name={name}
        isSearchable
        className="mt-1"
        value={countryValue}
        onChange={handleChange}
        options={countryList}
        styles={customStyles}
        placeholder={placeholder}
        noOptionsMessage={() => 'Žádné možnosti'}
      />
      {error && (
        <p className="mt-1 text-sm text-red-600" id={`${id}-error`}>
          {error.message}
        </p>
      )}
    </div>
  );
};

export default CountrySelectList;
