import React, { Dispatch } from 'react';
import RadioBooleanRow from '../control/RadioBooleanRow';
import { FormActions, FormLocation } from '../../../../hooks/forms/types.d.t';

type AccessibilityProps = {
  dispatch: Dispatch<FormActions>,
  state: FormLocation,
  type: 'origin' | 'destination',
};

const Accessibility = ({ state, dispatch, type }: AccessibilityProps): React.ReactElement => (
  <div className="space-y-3">
    <RadioBooleanRow
      id={`${type}-accessibility-forklift`}
      label="Vysokozdvižný vozík"
      description="Je třeba na lokaci vysokozdvižný vozík?"
      value={state.forklift}
      onChange={(value) => {
        dispatch({
          id: `${type}-accessibility-forklift`,
          type: 'TOGGLE_SINGLE_ACCESSIBILITY_VALUE',
          field: 'forklift',
          address: type,
          value,
        });
      }}
    />
    <RadioBooleanRow
      id={`${type}-accessibility-tailgate`}
      label="Hydraulické čelo"
      description="Je třeba na lokaci hydraulické čelo?"
      value={state.tailgate}
      onChange={(value) => {
        dispatch({
          id: `${type}-accessibility-tailgate`,
          type: 'TOGGLE_SINGLE_ACCESSIBILITY_VALUE',
          field: 'tailgate',
          address: type,
          value,
        });
      }}
    />
  </div>
);

export default Accessibility;
