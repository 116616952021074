export enum ProviderStatus {
  Prepared,
  BookingRequested,
  Booked,
  OnTheWay,
  Delivered,
  Canceled,
}

export enum ProviderKind {
  Wereldo,
  Wedo,
  DhlFreight,
}

export type WereldoProvider = {
  kind: ProviderKind.Wereldo,
  vehicle?: string,
  driverName?: string,
  driverPhone?: string,
  logoUrl: string,
  displayName: string,
};

export type ExternalProvider = {
  kind: ProviderKind.DhlFreight | ProviderKind.Wedo,
  trackUrl?: string,
  // TODO carrier id
  logoUrl: string,
  displayName: string,
};

export type Provider = WereldoProvider | ExternalProvider;

export enum Currency {
  CZK,
  EUR,
}

export function parseCurrency(input: string): Currency {
  switch (input) {
    case 'CZK':
      return Currency.CZK;
    case 'EUR':
      return Currency.EUR;
    default:
      return Currency.CZK;
  }
}

// TODO prices for the service
export type Price = {
  base: number,
  fuel: number,
  tolls: number,
  total: number,
  currency: Currency,
};

export type CashOnDeliveryService = {
  value: number,
  symbol: string,
  currency: Currency,
};

export type Services = {
  cod?: CashOnDeliveryService,
};

export type QuoteProvider = {
  kind: ProviderKind,
  displayName: string,
  logoUrl: string,
};

export type Quote = {
  idx: number,
  providers: QuoteProvider[],
  price: Price,
  // available services + prices?
};

export type InvalidQuote = {
  providers: QuoteProvider[],
  reason: string,
};

export type SelectedProvider = {
  status: ProviderStatus,
  segments: Provider[],
  price: Price,

  quotes: Quote[],
  invalidQuotes: InvalidQuote[],
};

export type SelectedProviderOverview = {
  status: ProviderStatus,
  price: number,
  currency: Currency,
  segments: ProviderKind[],
};

export type ProviderData = {
  displayName: string,
  logoUrl: string,
};

export const getMockProvider = (): SelectedProvider => (
  {
    status: ProviderStatus.OnTheWay,
    segments: [{
      kind: ProviderKind.Wereldo,
      logoUrl: 'https://www.wereldo.com/favicon.ico',
      displayName: 'Wereldo',
    }, {
      kind: ProviderKind.DhlFreight,
      logoUrl: 'https://www.dpdhl-brands.com/dhl/en/guides/design-basics/logo-and-claim/_jcr_content/parsys/imagetext/imagetextcontainer/image.coreimg.100.1024.png/1591965147742/versions-01.png',
      displayName: 'DHL Freight',
    }],
    price: {
      base: 10,
      fuel: 10,
      tolls: 10,
      total: 3030,
      currency: Currency.CZK,
    },
    quotes: [{
      idx: 0,
      providers: [{
        kind: ProviderKind.Wereldo,
        logoUrl: 'https://www.wereldo.com/favicon.ico',
        displayName: 'Wereldo',
      }, {
        kind: ProviderKind.DhlFreight,
        logoUrl: 'https://www.dpdhl-brands.com/dhl/en/guides/design-basics/logo-and-claim/_jcr_content/parsys/imagetext/imagetextcontainer/image.coreimg.100.1024.png/1591965147742/versions-01.png',
        displayName: 'DHL Freight',
      }],
      price: {
        base: 2800,
        fuel: 200,
        tolls: 30,
        total: 3030,
        currency: Currency.CZK,
      },
    },
    {
      idx: 1,
      providers: [{
        kind: ProviderKind.Wereldo,
        logoUrl: 'https://www.wereldo.com/favicon.ico',
        displayName: 'Wereldo',
      }, {
        kind: ProviderKind.Wedo,
        logoUrl: 'https://www.lekarna.cz/upload/cms/images/wedologo.jpg',
        displayName: 'WE|DO',
      }],
      price: {
        base: 3570,
        fuel: 230,
        tolls: 200,
        total: 4000,
        currency: Currency.CZK,
      },
    },
    {
      idx: 2,
      providers: [{
        kind: ProviderKind.Wedo,
        logoUrl: 'https://www.lekarna.cz/upload/cms/images/wedologo.jpg',
        displayName: 'WE|DO',
      }],
      price: {
        base: 4570,
        fuel: 230,
        tolls: 200,
        total: 4000,
        currency: Currency.CZK,
      },
    }],
    invalidQuotes: [],
  }
);
