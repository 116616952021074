import React from 'react';
import { SearchIcon } from '@heroicons/react/solid';

export type SearchFilterSegmentProps = {
  nothing?: null,
};

const SearchFilterSegment = (): React.ReactElement => (
  <div>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor="account-number" className="sr-only block text-sm font-medium text-gray-700">
      Search
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <input
        type="text"
        name="search-query"
        id="search-query"
        className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
        placeholder="Vyhledat podle čísla rezervace"
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
    </div>
  </div>
);

export default SearchFilterSegment;
