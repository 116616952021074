import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import 'stream-chat-react/dist/css/index.css';
import './index.css';
import { Chat, LoadingIndicator, Streami18n } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
// import Undraw from 'react-undraw'; // todo not supported anymore in React 18
import ReactTooltip from 'react-tooltip';

// eslint-disable-next-line
import 'moment/min/locales';
// eslint-disable-next-line
import Moment from 'moment';
import ruToCz from './getstream_cz.json';
import App from './App';
import reportWebVitals from './reportWebVitals';

// optionally override default props
// Undraw.defaultProps.primaryColor = 'red';

const apollo = new ApolloClient({
  uri: 'http://localhost:8000/graphql',
  headers: {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ3cmxkLXRlc3QiLCJpYXQiOjE2Mzk2NDczODYsImV4cCI6MTY3MTE4MzM4NiwiYXVkIjoiYXBpLndlcmVsZG8uY29tIiwic3ViIjoid3JsZCIsInVpZCI6IjYxYmIwOGI3NGViMDRhNzhhOTE5NDM5YyIsImFpZCI6IjYxYTEzZTdmYzg4YjAxZDIwOGM3MjhkZCJ9.YevZZMqSopEe-dhB9PJE_8tZf2cCza6Xgb6CB38kOGA',
  },
  cache: new InMemoryCache(),
});

const Root = (): React.ReactElement => {
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  // const { userToken } = useUserContext(); TODO here ill get the token (chat token)
  // const { image, userChatId, userChatName } = useUserContext(); TODO here ill get user info
  // <delete>

  const i18nInstance = new Streami18n({
    language: 'cs',
    translationsForLanguage: ruToCz,
    DateTimeParser: Moment,
  });

  const userToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZmY5NTc2NjgtZjNkYi00NDhiLWIxMmItMTQ4MjgzYmI0ODE2In0.LuPj6FEwGgryZ_8eePP7Pi-KAQCZUEdktlqQH62aCzk';
  const image = 'https://i.imgur.com/fR9Jz14.png';
  const userChatId = 'ff957668-f3db-448b-b12b-148283bb4816';
  const userChatName = 'Joe Doe';
  // </delete>

  useEffect(() => {
    const initChat = async () => {
      const client = StreamChat.getInstance('d5na4gjzr73m');

      // await client.connectUser(
      //   {
      //     id: userChatId,
      //     name: userChatName,
      //     image,
      //   },
      //   userToken,
      // );

      await client.connectUser(
        {
          id: 'misty-boat-1',
          name: 'Jim Lahey',
          image: 'https://i.imgur.com/fR9Jz14.png',
        },
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoibWlzdHktYm9hdC0xIn0.MZ6S8CO_bxvaZ5UWDnsd8Qw4cc19LiZVxGpXnN-juZw',
      );

      // todo UpdateApp failed with error: "this endpoint can only be called server side"
      // await client.updateAppSettings({
      //   multi_tenant_enabled: true,
      // });

      setChatClient(client);
    };

    initChat();
  }, []);

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <Chat
      client={chatClient}
      i18nInstance={i18nInstance}
      theme="team light max-h-full"
    >
      <App />
    </Chat>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <AuthContext> TODO here comes the user context */}
      <ApolloProvider client={apollo}>
        {/* <UserContext> TODO here comes the user context */}
        <App />
        {/* //TODO untill getStream works ... <Root /> */}
        <ReactTooltip effect="solid" />
        {/* </UserContext> */}
      </ApolloProvider>
      {/* </AuthContext> */}
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
