import React, { Dispatch, useState } from 'react';
import ServiceTemplate from './ServiceTemplate';
import { ServiceItem } from '../types';
import { FormActions } from '../../../../hooks/forms/types.d.t';

const deliveryNoteOptions: ServiceItem[] = [
  {
    id: 1,
    title: 'Bez dodacích listů',
    description: 'Nepřeji si vygenerovat dodací listy.',
    value: false,
    price: null,
  },
  {
    id: 2,
    title: 'Dodací listy',
    description: 'Zvolte, pokud si přejete vygenerovat dodací listy pro vybrané zboží. Řidič je bude mít s sebou.',
    value: true,
    price: {
      amount: 49,
      currency: 'CZK',
    },
  },
];

type DeliveryNoteProps = {
  dispatch: Dispatch<FormActions>,
};

const DeliveryNote = ({ dispatch }: DeliveryNoteProps): React.ReactElement => {
  const [
    selectedDeliveryNoteOption,
    setSelectedDeliveryNoteOption,
  ] = useState(deliveryNoteOptions[0]);

  const setState = (item: ServiceItem): void => {
    setSelectedDeliveryNoteOption(item);
    dispatch({
      id: null,
      type: 'SET_VALUE',
      key: 'unitServices',
      field: 'deliveryNote',
      payload: deliveryNoteOptions.indexOf(item) === 0 ? undefined : {
        price: item.price,
      },
    });
  };

  return (
    <ServiceTemplate
      setSelectedItem={setState}
      selectedItem={selectedDeliveryNoteOption}
      items={deliveryNoteOptions}
      title="Dodací listy"
      iconName="save_to_bookmarks"
    />
  );
};

export default DeliveryNote;
