import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';

type RadioSwitcherOption = {
  id: string;
  value: string;
  disabled?: boolean;
  showBadge?: boolean;
  slot: JSX.Element;
};

type RadioSwitcherProps = {
  options: RadioSwitcherOption[];
  defaultIndex?: null | number;
  wrapperClass?: string;
  itemClass?: string;
  filterOptions?: (option: RadioSwitcherOption) => boolean;
  onChange?: (option: RadioSwitcherOption) => void;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const RadioSwitcher = (props: RadioSwitcherProps): React.ReactElement => {
  let { options } = props;
  const {
    defaultIndex, wrapperClass, itemClass, filterOptions, onChange,
  } = props;

  if (filterOptions) {
    options = options.filter(filterOptions);
  }

  const [selected, setSelected] = useState(
    (defaultIndex || defaultIndex === 0)
    && (defaultIndex <= options.length - 1 && defaultIndex >= 0)
      ? options[defaultIndex]
      : null,
  );

  const handleChange = (option: RadioSwitcherOption) => {
    setSelected(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <RadioGroup
      value={selected?.id}
      onChange={(option: string) => handleChange(
        options.find((id) => id.id === option) || options[0],
      )}
    >
      <div className={wrapperClass}>
        {options.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option.id}
            className={({ checked, active }) => classNames(
              checked ? 'border-transparent' : 'border-gray-300',
              active ? 'ring-2 ring-gray-500' : '',
              !option.disabled ? 'cursor-pointer' : 'cursor-not-allowed',
              'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
              itemClass || '',
            )}
            disabled={option.disabled}
          >
            {({ checked, active }) => (
              <>
                {option.disabled && option.showBadge && (
                  <span className="absolute top-2 right-2 items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                    Již brzy
                  </span>
                )}
                <div className={classNames(
                  !option.disabled ? 'focus:outline-none' : 'opacity-25',
                  'flex-1 flex',
                )}
                >
                  <div className="flex flex-col">
                    {option.slot}
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-gray-600')}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-gray-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none',
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

RadioSwitcher.defaultProps = {
  defaultIndex: null,
  wrapperClass: undefined,
  itemClass: undefined,
  filterOptions: undefined,
  onChange: undefined,
};

export default RadioSwitcher;
