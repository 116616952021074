import React, { Dispatch, useState } from 'react';
import ServiceTemplate from './ServiceTemplate';
import { ServiceItem } from '../types';
import { FormActions } from '../../../../hooks/forms/types.d.t';

const floorDeliveryOptions: ServiceItem[] = [
  {
    id: 1,
    title: 'Bez výnosu do patra',
    description: 'Nepřeji si vynést do patra.',
    value: false,
    price: null,
  },
  {
    id: 2,
    title: 'Výnos do patra',
    description: 'Výnos do patra je základním záručním záležitostí, které se vztahuje na celkový výnos za objednávku.',
    value: true,
    price: {
      amount: 499,
      currency: 'CZK',
    },
  },
];

type FloorDeliveryProps = {
  dispatch: Dispatch<FormActions>,
};

const FloorDelivery = ({ dispatch }: FloorDeliveryProps): React.ReactElement => {
  const [
    selectedFloorDeliveryOption,
    setSelectedFloorDeliveryOption,
  ] = useState(floorDeliveryOptions[0]);

  const setState = (item: ServiceItem): void => {
    setSelectedFloorDeliveryOption(item);
    dispatch({
      id: null,
      type: 'SET_VALUE',
      key: 'unitServices',
      field: 'floorDelivery',
      payload: floorDeliveryOptions.indexOf(item) === 0 ? undefined : {
        price: item.price,
      },
    });
  };

  return (
    <ServiceTemplate
      setSelectedItem={setState}
      selectedItem={selectedFloorDeliveryOption}
      items={floorDeliveryOptions}
      title="Výnos do patra"
      iconName="order_delivered"
    />
  );
};

export default FloorDelivery;
