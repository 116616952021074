import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import moment from 'moment-timezone';

import cs from 'date-fns/locale/cs'; // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../react-datepicker-override.css';
import { setHours, setMinutes } from 'date-fns';
import { Errors, Error } from '../../../../hooks/forms/types.d.t';

registerLocale('cs', cs);

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type ReactDatePickerInputProps = {
  label: string;
  cornerHint?: string,
  autoComplete?: string,
  description?: string,
  isRequired?: boolean,
  error?: Error,
};

const ReactDatePickerInput = React.forwardRef<
HTMLInputElement,
React.DetailedHTMLProps<
React.InputHTMLAttributes<HTMLInputElement> & ReactDatePickerInputProps,
HTMLInputElement
>
>(({
  value,
  onClick,
  onChange,
  isRequired,
  error,
  ...rest
}, ref) => {
  const id = `${rest.id}-input`;
  const name = `${rest.name}-input`;

  const {
    label, placeholder, cornerHint, autoComplete,
  } = rest;

  return (
    <div>
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <span
          className={classNames(
            cornerHint && !isRequired ? 'text-sm text-gray-500' : 'text-red-600 leading-4',
          )}
          id={`${id}-hint`}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {cornerHint && !isRequired ? cornerHint : (isRequired ? '*' : '')}
        </span>
      </div>
      <div className="mt-1 relative rounded-md shadow-sm flex">
        <input
          type="text"
          name={name}
          id={id}
          value={value}
          onClick={onClick}
          onChange={onChange}
          autoComplete={autoComplete || 'off'}
          placeholder={placeholder}
          ref={ref as React.LegacyRef<HTMLInputElement>}
          className={classNames(
            'focus:ring-blue-500 focus:border-blue-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
          )}
        />
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-600" id={`${id}-error`}>
          {error.message}
        </p>
      )}
    </div>
  );
});

ReactDatePickerInput.displayName = 'ReactDatePickerInput';

type FormDatePickerProps = {
  label: string;
  time?: boolean,
  cornerHint?: string,
  wrapperClassName?: string,
  autoComplete?: string,
  description?: string,
  isRequired?: boolean,
  errors: Errors,
  minTime?: Date,
};

// eslint-disable-next-line react/function-component-definition
const FormDatePicker: FunctionComponent<ReactDatePickerProps & FormDatePickerProps> = ({
  name,
  id,
  minTime,
  time,
  wrapperClassName,
  label,
  cornerHint,
  autoComplete,
  errors,
  isRequired,
  ...props
}) => {
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    setError(errors.find((e) => e.id === id));
  }, [errors]);

  return (
    <ReactDatePicker
      {...props}
      wrapperClassName={wrapperClassName}
      locale="cs"
      dateFormat={time ? 'HH:mm' : 'dd.MM.yyyy'}
      enableTabLoop={false}
      showTimeSelect={time ? true : undefined}
      showTimeSelectOnly={time ? true : undefined}
      timeIntervals={time ? 15 : undefined}
      timeCaption={time ? label : undefined}
      minTime={
      time && minTime
        ? setHours(setMinutes(Date.now(), minTime.getMinutes() || 0), minTime.getHours() || 0)
        : undefined
    }
      maxTime={
      time && minTime
        ? setHours(setMinutes(Date.now(), 59), 23)
        : undefined
    }
      minDate={moment().toDate()}
      name={name}
      customInput={(
        <ReactDatePickerInput
          id={id}
          name={name}
          label={label}
          cornerHint={cornerHint}
          autoComplete={autoComplete}
          error={error}
          isRequired={isRequired}
        />
    )}
    />
  );
};

FormDatePicker.defaultProps = {
  cornerHint: undefined,
  autoComplete: 'off',
  time: false,
  wrapperClassName: undefined,
  description: undefined,
  minTime: undefined,
  isRequired: undefined,
};

export default FormDatePicker;
