import React from 'react';
import CheckboxFilterSegment from './segments/CheckboxStatusFilter';
// import DateRangeFilterSegment from './segments/DateRangeFilterSegment';
// import AddressFilterSegment from './segments/AddressFilterSegment';
import SearchFilterSegment from './segments/SearchFilterSegment';
import ActiveFiltersSegment from './segments/ActiveFiltersSegment';
import AutocompleteFilterSegment from './segments/AutocompleteFilterSegment';

const Filters = (): React.ReactElement => (
  <div className="pb-4">
    <div className="flex justify-between items-end text-sm font-medium text-gray-700 tracking-wider">
      <div className="p-2 pl-0" style={{ width: 300 }}>
        <SearchFilterSegment />
      </div>
      <div className="flex p-2 px-4 pr-0 space-x-4 z-20">
        {/* <DateRangeFilterSegment /> */}
        {/* <AddressFilterSegment /> */}
        <input
          type="text"
          name="search-query"
          id="search-query"
          className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Date range"
        />
      </div>
    </div>
    <ActiveFiltersSegment>
      <CheckboxFilterSegment dense={false} providerIcon name="providers" />
      <CheckboxFilterSegment name="status" />
      <AutocompleteFilterSegment name="pickup" />
      <AutocompleteFilterSegment name="delivery" />
    </ActiveFiltersSegment>
  </div>
);

export default Filters;
