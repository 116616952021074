import React, { Dispatch } from 'react';
import { Action, getActiveValues, useFilterContext } from '../../../../../hooks/filters/useFilters';
import SelectableFilterPopover from '../../../misc/SelectableFilterPopover';
import { EnumCheckboxFilterValue } from '../../../types/form';

export type CheckboxFilterSegmentProps = {
  name: string,
  dense?: boolean,
  providerIcon?: boolean,
};

type CheckboxInputProps = {
  value: EnumCheckboxFilterValue,
  name: string,
  dispatch: Dispatch<Action>,
  data: Action,
  defaultChecked: boolean
};

const CheckboxInput = ({
  value, name, dispatch, data, defaultChecked,
}: CheckboxInputProps): React.ReactElement => (
  <input
    id={`${name}-${value.name}`}
    name={`${name}-select`}
    type="checkbox"
    onClick={() => dispatch(data)}
    defaultChecked={defaultChecked}
    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
  />
);

type ListValueProps = {
  name: string,
  value: EnumCheckboxFilterValue,
  dense: boolean,
  providerIcon: boolean,
  dispatch: Dispatch<Action>,
  data: Action,
  defaultChecked: boolean
};

const ListValue = (props: ListValueProps): React.ReactElement => {
  const {
    dense, providerIcon, name, value,
  } = props;
  if (dense && !providerIcon) {
    return (
      <>
        <div className="min-w-0 flex-1 text-sm">
          <label htmlFor={`${name}-${value.name}`} className="text-xs font-medium text-gray-500 uppercase select-none">
            {value.name}
          </label>
        </div>
        <div className="ml-3 flex items-center h-5">
          <CheckboxInput {...props} />
        </div>
      </>
    );
  }
  return (
    <div className="min-w-0 flex justify-between items-center text-left align-middle text-xs font-medium text-gray-500 uppercase">
      <div className="flex items-center space-x-4">
        logo
        <label htmlFor={`${name}-${value.name}`} className="font-medium text-gray-700 select-none">
          {value.name}
        </label>
      </div>
      <div className="ml-8 flex items-center h-5">
        <CheckboxInput {...props} />
      </div>
    </div>
  );
};

const CheckboxFilterSegment = ({ name, dense = true, providerIcon = false }
: CheckboxFilterSegmentProps): React.ReactElement => {
  const {
    state, filters, dispatch,
  } = useFilterContext();
  const activeFilters = getActiveValues(state);
  const active = activeFilters.filter((filter) => filter.name === name);
  const count = active.length;
  let first = '';
  if (Array.isArray(active) && active.length > 0) {
    first = active[0].value;
  }
  const { values }: { values: EnumCheckboxFilterValue[] } = filters[name as never];

  return (
    <SelectableFilterPopover label={count > 0 ? first : name} name={name} count={count}>
      <fieldset>
        <div className={`px-4 ${dense ? '' : 'h-auto max-h-72 overflow-scroll border-t border-b border-gray-200 divide-y divide-gray-200'}`}>
          {values.map((value) => (
            <div key={value.name} className="relative flex items-start py-4">
              <ListValue
                value={value}
                name={name}
                dense={dense}
                providerIcon={providerIcon}
                dispatch={dispatch}
                data={{
                  name,
                  payload: value,
                }}
                defaultChecked={!!active.find((af) => af.originalValue === value)}
              />
            </div>
          ))}
        </div>
      </fieldset>
    </SelectableFilterPopover>
  );
};

CheckboxFilterSegment.defaultProps = {
  dense: true,
  providerIcon: false,
};

export default CheckboxFilterSegment;
