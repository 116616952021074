import React, { Dispatch, useEffect } from 'react';
import { BackspaceIcon, DuplicateIcon, PlusSmIcon } from '@heroicons/react/outline';
import CargoSelectField from '../control/CargoSelectField';
import NumberField from '../control/NumberField';
import Field from '../control/Field';
import {
  Errors,
  FormActions,
  FormUnitCargo, FormUnitItemType,
  ShipmentFormData,
} from '../../../../hooks/forms/types.d.t'; // this is really weird

type UnitsProps = {
  dispatch: Dispatch<FormActions>,
  state: ShipmentFormData,
  errors: Errors,
};

const Units = ({ dispatch, state, errors }: UnitsProps): React.ReactElement => {
  const defaultCargo: FormUnitCargo = {
    units: [
      {
        type: FormUnitItemType.BOX,
        dimensions: {},
        count: 1,
        stackable: false,
      },
    ],
    services: {},
  };

  useEffect(() => {
    dispatch({
      id: null,
      type: 'SET_VALUE',
      key: 'cargo',
      payload: defaultCargo,
    });
  }, []);

  const handleAnotherUnit = (): void => {
    dispatch({
      id: null,
      type: 'SET_UNIT_CARGO',
      key: 'cargo',
      payload: defaultCargo.units[0],
    });
  };

  const handleDuplicate = (index: number): void => {
    dispatch({
      id: null,
      type: 'SET_UNIT_CARGO',
      key: 'cargo_duplicate',
      index,
    });
  };

  const handleRemove = (index: number): void => {
    dispatch({
      id: null,
      type: 'SET_UNIT_CARGO',
      key: 'cargo_remove',
      index,
    });
  };

  const cargo = state?.cargo as FormUnitCargo;
  const units = cargo?.units as FormUnitCargo['units'];

  const stackableTypes = [
    FormUnitItemType.EUR_PALLET,
    FormUnitItemType.PALLET,
  ];
  const hasStackable = units?.filter(
    (unit) => stackableTypes.includes(unit.type),
  ).length > 0;

  return units ? (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className={!hasStackable ? 'col-span-3' : 'col-span-2'}>
          <span className="block text-sm font-medium text-gray-700">
            Typ
          </span>
        </div>
        {hasStackable && (
        <div className="col-span-1">
          <span className="block text-sm font-medium text-gray-700">
            Stackable
          </span>
        </div>
        )}
        <div className="col-span-2">
          <span className="block text-sm font-medium text-gray-700">
            Hmotnost [kg/ks]
          </span>
        </div>
        <div className="col-span-4">
          <div className="grid grid-cols-3 gap-3">
            <span className="block text-sm font-medium text-gray-700">
              Délka [cm]
            </span>
            <span className="block text-sm font-medium text-gray-700">
              Šířka [cm]
            </span>
            <span className="block text-sm font-medium text-gray-700">
              Výška [cm]
            </span>
          </div>
        </div>
        <div className="col-span-1">
          <span className="block text-sm font-medium text-gray-700">
            Počet
          </span>
        </div>
        <div className="col-span-2 text-right">
          <span className="block text-sm font-medium text-gray-700">
            Akce
          </span>
        </div>
      </div>
      {units.map((unit, unitIdx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`unit-${unitIdx}`}>
          <div className="grid grid-cols-12 gap-3">
            <div className={!stackableTypes.includes(unit.type) ? 'col-span-3' : 'col-span-2'}>
              <CargoSelectField
                value={unit.type}
                onChange={(value) => dispatch({
                  id: null,
                  type: 'SET_UNIT_CARGO',
                  key: 'cargo_item',
                  index: unitIdx,
                  field: 'type',
                  payload: value,
                })}
              />
            </div>
            {stackableTypes.includes(unit.type) && (
            <div className="col-span-1">
              <Field
                errors={errors}
                wrapperClass="flex items-center justify-center"
                name={`unit-${unitIdx}-stackable`}
                id={`unit-${unitIdx}-stackable`}
                autoComplete="off"
                type="checkbox"
                checked={unit.stackable}
                inputClass="mt-2.5 focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded"
                onCheck={(value) => dispatch({
                  id: `unit-${unitIdx}-stackable`,
                  type: 'SET_UNIT_CARGO',
                  key: 'cargo_item',
                  index: unitIdx,
                  field: 'stackable',
                  payload: value,
                })}
              />
            </div>
            )}
            <div className="col-span-2">
              <Field
                errors={errors}
                name={`unit-${unitIdx}-weight`}
                id={`unit-${unitIdx}-weight`}
                autoComplete="off"
                placeholder="Hmotnost"
                type="number"
                min={0}
                value={unit.weight}
                onNumberChange={(value) => dispatch({
                  id: `unit-${unitIdx}-weight`,
                  type: 'SET_UNIT_CARGO',
                  key: 'cargo_item',
                  index: unitIdx,
                  field: 'weight',
                  payload: value,
                })}
              />
            </div>
            <div className="col-span-4">
              <div className="grid grid-cols-3 gap-3">
                <Field
                  errors={errors}
                  name={`unit-${unitIdx}-length`}
                  id={`unit-${unitIdx}-length`}
                  autoComplete="off"
                  placeholder="Délka"
                  type="number"
                  min={0}
                  value={unit.dimensions.length}
                  onNumberChange={(value) => dispatch({
                    id: `unit-${unitIdx}-length`,
                    type: 'SET_UNIT_CARGO',
                    key: 'cargo_dimension',
                    index: unitIdx,
                    field: 'length',
                    payload: value,
                  })}
                />
                <Field
                  errors={errors}
                  name={`unit-${unitIdx}-width`}
                  id={`unit-${unitIdx}-width`}
                  autoComplete="off"
                  placeholder="Šířka"
                  type="number"
                  min={0}
                  value={unit.dimensions.width}
                  onNumberChange={(value) => dispatch({
                    id: `unit-${unitIdx}-width`,
                    type: 'SET_UNIT_CARGO',
                    key: 'cargo_dimension',
                    index: unitIdx,
                    field: 'width',
                    payload: value,
                  })}
                />
                <Field
                  errors={errors}
                  name={`unit-${unitIdx}-height`}
                  id={`unit-${unitIdx}-height`}
                  autoComplete="off"
                  placeholder="Výška"
                  type="number"
                  min={0}
                  value={unit.dimensions.height}
                  onNumberChange={(value) => dispatch({
                    id: `unit-${unitIdx}-height`,
                    type: 'SET_UNIT_CARGO',
                    key: 'cargo_dimension',
                    index: unitIdx,
                    field: 'height',
                    payload: value,
                  })}
                />
              </div>
            </div>
            <div className="col-span-1">
              <NumberField
                name={`unit-${unitIdx}-count`}
                id={`unit-${unitIdx}-count`}
                value={unit.count}
                increment={() => dispatch({
                  id: `unit-${unitIdx}-count`,
                  type: 'SET_UNIT_CARGO',
                  key: 'cargo_item',
                  index: unitIdx,
                  field: 'count',
                  payload: unit.count + 1,
                })}
                decrement={() => dispatch({
                  id: `unit-${unitIdx}-count`,
                  type: 'SET_UNIT_CARGO',
                  key: 'cargo_item',
                  index: unitIdx,
                  field: 'count',
                  payload: unit.count - 1,
                })}
                onChange={(value) => dispatch({
                  id: `unit-${unitIdx}-count`,
                  type: 'SET_UNIT_CARGO',
                  key: 'cargo_item',
                  index: unitIdx,
                  field: 'count',
                  payload: value,
                })}
              />
            </div>
            <div className="col-span-2 text-right">
              <div className="mt-1">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    onClick={() => handleDuplicate(unitIdx)}
                    type="button"
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-green-200 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <span className="sr-only">Duplikovat</span>
                    <DuplicateIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    onClick={() => handleRemove(unitIdx)}
                    type="button"
                    className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-red-200 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <span className="sr-only">Odstranit</span>
                    <BackspaceIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAnotherUnit}
        className="mt-4 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
        {' '}
        <span className="text-sm font-medium px-2">
          Přidat další
        </span>
      </button>
    </>
  ) : <></>;
};

export default Units;
