import React from 'react';
import { CursorClickIcon } from '@heroicons/react/outline';
import { Provider, ProviderKind } from '../../types/provider';

export type ProviderLogoProps = {
  url: string,
  displayName?: string,
  inline?: boolean,
};

export const ProviderLogo = ({ url, displayName, inline }: ProviderLogoProps): React.ReactElement => {
  if (inline) {
    return (
      <span>
        <img
          className="inline-block h-6 w-6 rounded-md"
          src={url}
          alt="Provider logo"
        />
      </span>
    );
  }
  return (
    <div className="flex flex-row gap-2">
      <img
        className="inline-block h-6 w-6 rounded-md"
        src={url}
        alt="Provider logo"
      />
      {displayName && <span>{displayName}</span>}
    </div>
  );
};

ProviderLogo.defaultProps = {
  inline: false,
  displayName: undefined,
};

export type ProviderSectionProps = {
  provider: Provider,
  variant: 'small' | 'large',
};

const ProviderSection = ({ provider, variant }: ProviderSectionProps): React.ReactElement => ((variant === 'small') ? (
  <ProviderLogo url={provider.logoUrl} displayName={provider.displayName} />
) : (
  <div>
    <div className="my-2">
      <ProviderLogo url={provider.logoUrl} displayName={provider.displayName} />
    </div>
    {(provider.kind === ProviderKind.Wereldo) ? (
      <div className="flex flex-row justify-between">
        <span className="font-semibold">Vozidlo</span>
        <span className="tracking-widest">Sólo</span>
      </div>
    ) : null}

    {(provider.kind === ProviderKind.Wereldo) ? (
      <div className="flex flex-row justify-between">
        <span className="font-semibold">Jméno řidiče</span>
        <span className="tracking-widest">Jan Novák</span>
      </div>
    ) : null}

    {(provider.kind === ProviderKind.Wereldo) ? (
      <div className="flex flex-row justify-between">
        <span className="font-semibold">Kontakt na řidiče</span>
        <span className="tracking-widest">666 777 888</span>
      </div>
    ) : null}

    {(provider.kind !== ProviderKind.Wereldo) ? (
      <div className="flex flex-row justify-between">
        <span className="font-semibold">Číslo zásilky</span>
        <span className="tracking-widest">0123456789</span>
      </div>
    ) : null}

    {(provider.kind !== ProviderKind.Wereldo) ? (
      <div className="flex flex-row justify-between">
        <span className="font-semibold">Sledování zásilky</span>
        <span className="tracking-widest underline decoration-1 underline-offset-4 decoration-dashed decoration-gray-400">
          <a href="#">zde</a>
          <CursorClickIcon className="inline-block ml-1 h-4 w-4" />
        </span>
      </div>
    ) : null}

  </div>
));

export default ProviderSection;
