import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';

type useGetStreamFeedProps = {
  channelId: string;
  team: string;
  image: string;
  name: string;
};

type useGetStreamFeedResult = {
  channel: Channel | undefined,
  isLoading: boolean,
};

function useGetStreamFeed({
  channelId,
  team,
  image,
  name,
}: useGetStreamFeedProps): useGetStreamFeedResult {
  const { client } = useChatContext();

  const channel = client.channel('team', `${channelId}`, {
    image,
    name,
    team,
  });

  return {
    channel,
    isLoading: !client,
  };
}

export default useGetStreamFeed;
