export default [
  {
    label: 'Afghanistan',
    alpha_2: 'AF',
    value: 'AFG',
    id: 4,
    avg_lat: 33,
    avg_lng: 65,
  },
  {
    label: 'Albania',
    alpha_2: 'AL',
    value: 'ALB',
    id: 8,
    avg_lat: 41,
    avg_lng: 20,
  },
  {
    label: 'Algeria',
    alpha_2: 'DZ',
    value: 'DZA',
    id: 12,
    avg_lat: 28,
    avg_lng: 3,
  },
  {
    label: 'American Samoa',
    alpha_2: 'AS',
    value: 'ASM',
    id: 16,
    avg_lat: -14.3333,
    avg_lng: -170,
  },
  {
    label: 'Andorra',
    alpha_2: 'AD',
    value: 'AND',
    id: 20,
    avg_lat: 42.5,
    avg_lng: 1.6,
  },
  {
    label: 'Angola',
    alpha_2: 'AO',
    value: 'AGO',
    id: 24,
    avg_lat: -12.5,
    avg_lng: 18.5,
  },
  {
    label: 'Anguilla',
    alpha_2: 'AI',
    value: 'AIA',
    id: 660,
    avg_lat: 18.25,
    avg_lng: -63.1667,
  },
  {
    label: 'Antarctica',
    alpha_2: 'AQ',
    value: 'ATA',
    id: 10,
    avg_lat: -90,
    avg_lng: 0,
  },
  {
    label: 'Antigua and Barbuda',
    alpha_2: 'AG',
    value: 'ATG',
    id: 28,
    avg_lat: 17.05,
    avg_lng: -61.8,
  },
  {
    label: 'Argentina',
    alpha_2: 'AR',
    value: 'ARG',
    id: 32,
    avg_lat: -34,
    avg_lng: -64,
  },
  {
    label: 'Armenia',
    alpha_2: 'AM',
    value: 'ARM',
    id: 51,
    avg_lat: 40,
    avg_lng: 45,
  },
  {
    label: 'Aruba',
    alpha_2: 'AW',
    value: 'ABW',
    id: 533,
    avg_lat: 12.5,
    avg_lng: -69.9667,
  },
  {
    label: 'Australia',
    alpha_2: 'AU',
    value: 'AUS',
    id: 36,
    avg_lat: -27,
    avg_lng: 133,
  },
  {
    label: 'Austria',
    alpha_2: 'AT',
    value: 'AUT',
    id: 40,
    avg_lat: 47.3333,
    avg_lng: 13.3333,
  },
  {
    label: 'Azerbaijan',
    alpha_2: 'AZ',
    value: 'AZE',
    id: 31,
    avg_lat: 40.5,
    avg_lng: 47.5,
  },
  {
    label: 'Bahamas',
    alpha_2: 'BS',
    value: 'BHS',
    id: 44,
    avg_lat: 24.25,
    avg_lng: -76,
  },
  {
    label: 'Bahrain',
    alpha_2: 'BH',
    value: 'BHR',
    id: 48,
    avg_lat: 26,
    avg_lng: 50.55,
  },
  {
    label: 'Bangladesh',
    alpha_2: 'BD',
    value: 'BGD',
    id: 50,
    avg_lat: 24,
    avg_lng: 90,
  },
  {
    label: 'Barbados',
    alpha_2: 'BB',
    value: 'BRB',
    id: 52,
    avg_lat: 13.1667,
    avg_lng: -59.5333,
  },
  {
    label: 'Belarus',
    alpha_2: 'BY',
    value: 'BLR',
    id: 112,
    avg_lat: 53,
    avg_lng: 28,
  },
  {
    label: 'Belgium',
    alpha_2: 'BE',
    value: 'BEL',
    id: 56,
    avg_lat: 50.8333,
    avg_lng: 4,
  },
  {
    label: 'Belize',
    alpha_2: 'BZ',
    value: 'BLZ',
    id: 84,
    avg_lat: 17.25,
    avg_lng: -88.75,
  },
  {
    label: 'Benin',
    alpha_2: 'BJ',
    value: 'BEN',
    id: 204,
    avg_lat: 9.5,
    avg_lng: 2.25,
  },
  {
    label: 'Bermuda',
    alpha_2: 'BM',
    value: 'BMU',
    id: 60,
    avg_lat: 32.3333,
    avg_lng: -64.75,
  },
  {
    label: 'Bhutan',
    alpha_2: 'BT',
    value: 'BTN',
    id: 64,
    avg_lat: 27.5,
    avg_lng: 90.5,
  },
  {
    label: 'Bolivia, Plurinational State of',
    alpha_2: 'BO',
    value: 'BOL',
    id: 68,
    avg_lat: -17,
    avg_lng: -65,
  },
  {
    label: 'Bolivia',
    alpha_2: 'BO',
    value: 'BOL',
    id: 68,
    avg_lat: -17,
    avg_lng: -65,
  },
  {
    label: 'Bosnia and Herzegovina',
    alpha_2: 'BA',
    value: 'BIH',
    id: 70,
    avg_lat: 44,
    avg_lng: 18,
  },
  {
    label: 'Botswana',
    alpha_2: 'BW',
    value: 'BWA',
    id: 72,
    avg_lat: -22,
    avg_lng: 24,
  },
  {
    label: 'Bouvet Island',
    alpha_2: 'BV',
    value: 'BVT',
    id: 74,
    avg_lat: -54.4333,
    avg_lng: 3.4,
  },
  {
    label: 'Brazil',
    alpha_2: 'BR',
    value: 'BRA',
    id: 76,
    avg_lat: -10,
    avg_lng: -55,
  },
  {
    label: 'British Indian Ocean Territory',
    alpha_2: 'IO',
    value: 'IOT',
    id: 86,
    avg_lat: -6,
    avg_lng: 71.5,
  },
  {
    label: 'Brunei Darussalam',
    alpha_2: 'BN',
    value: 'BRN',
    id: 96,
    avg_lat: 4.5,
    avg_lng: 114.6667,
  },
  {
    label: 'Brunei',
    alpha_2: 'BN',
    value: 'BRN',
    id: 96,
    avg_lat: 4.5,
    avg_lng: 114.6667,
  },
  {
    label: 'Bulgaria',
    alpha_2: 'BG',
    value: 'BGR',
    id: 100,
    avg_lat: 43,
    avg_lng: 25,
  },
  {
    label: 'Burkina Faso',
    alpha_2: 'BF',
    value: 'BFA',
    id: 854,
    avg_lat: 13,
    avg_lng: -2,
  },
  {
    label: 'Burundi',
    alpha_2: 'BI',
    value: 'BDI',
    id: 108,
    avg_lat: -3.5,
    avg_lng: 30,
  },
  {
    label: 'Cambodia',
    alpha_2: 'KH',
    value: 'KHM',
    id: 116,
    avg_lat: 13,
    avg_lng: 105,
  },
  {
    label: 'Cameroon',
    alpha_2: 'CM',
    value: 'CMR',
    id: 120,
    avg_lat: 6,
    avg_lng: 12,
  },
  {
    label: 'Canada',
    alpha_2: 'CA',
    value: 'CAN',
    id: 124,
    avg_lat: 60,
    avg_lng: -95,
  },
  {
    label: 'Cape Verde',
    alpha_2: 'CV',
    value: 'CPV',
    id: 132,
    avg_lat: 16,
    avg_lng: -24,
  },
  {
    label: 'Cayman Islands',
    alpha_2: 'KY',
    value: 'CYM',
    id: 136,
    avg_lat: 19.5,
    avg_lng: -80.5,
  },
  {
    label: 'Central African Republic',
    alpha_2: 'CF',
    value: 'CAF',
    id: 140,
    avg_lat: 7,
    avg_lng: 21,
  },
  {
    label: 'Chad',
    alpha_2: 'TD',
    value: 'TCD',
    id: 148,
    avg_lat: 15,
    avg_lng: 19,
  },
  {
    label: 'Chile',
    alpha_2: 'CL',
    value: 'CHL',
    id: 152,
    avg_lat: -30,
    avg_lng: -71,
  },
  {
    label: 'China',
    alpha_2: 'CN',
    value: 'CHN',
    id: 156,
    avg_lat: 35,
    avg_lng: 105,
  },
  {
    label: 'Christmas Island',
    alpha_2: 'CX',
    value: 'CXR',
    id: 162,
    avg_lat: -10.5,
    avg_lng: 105.6667,
  },
  {
    label: 'Cocos (Keeling) Islands',
    alpha_2: 'CC',
    value: 'CCK',
    id: 166,
    avg_lat: -12.5,
    avg_lng: 96.8333,
  },
  {
    label: 'Colombia',
    alpha_2: 'CO',
    value: 'COL',
    id: 170,
    avg_lat: 4,
    avg_lng: -72,
  },
  {
    label: 'Comoros',
    alpha_2: 'KM',
    value: 'COM',
    id: 174,
    avg_lat: -12.1667,
    avg_lng: 44.25,
  },
  {
    label: 'Congo',
    alpha_2: 'CG',
    value: 'COG',
    id: 178,
    avg_lat: -1,
    avg_lng: 15,
  },
  {
    label: 'Congo, the Democratic Republic of the',
    alpha_2: 'CD',
    value: 'COD',
    id: 180,
    avg_lat: 0,
    avg_lng: 25,
  },
  {
    label: 'Cook Islands',
    alpha_2: 'CK',
    value: 'COK',
    id: 184,
    avg_lat: -21.2333,
    avg_lng: -159.7667,
  },
  {
    label: 'Costa Rica',
    alpha_2: 'CR',
    value: 'CRI',
    id: 188,
    avg_lat: 10,
    avg_lng: -84,
  },
  {
    label: "Côte d'Ivoire",
    alpha_2: 'CI',
    value: 'CIV',
    id: 384,
    avg_lat: 8,
    avg_lng: -5,
  },
  {
    label: 'Ivory Coast',
    alpha_2: 'CI',
    value: 'CIV',
    id: 384,
    avg_lat: 8,
    avg_lng: -5,
  },
  {
    label: 'Croatia',
    alpha_2: 'HR',
    value: 'HRV',
    id: 191,
    avg_lat: 45.1667,
    avg_lng: 15.5,
  },
  {
    label: 'Cuba',
    alpha_2: 'CU',
    value: 'CUB',
    id: 192,
    avg_lat: 21.5,
    avg_lng: -80,
  },
  {
    label: 'Cyprus',
    alpha_2: 'CY',
    value: 'CYP',
    id: 196,
    avg_lat: 35,
    avg_lng: 33,
  },
  {
    label: 'Czech Republic',
    alpha_2: 'CZ',
    value: 'CZE',
    id: 203,
    avg_lat: 49.75,
    avg_lng: 15.5,
  },
  {
    label: 'Denmark',
    alpha_2: 'DK',
    value: 'DNK',
    id: 208,
    avg_lat: 56,
    avg_lng: 10,
  },
  {
    label: 'Djibouti',
    alpha_2: 'DJ',
    value: 'DJI',
    id: 262,
    avg_lat: 11.5,
    avg_lng: 43,
  },
  {
    label: 'Dominica',
    alpha_2: 'DM',
    value: 'DMA',
    id: 212,
    avg_lat: 15.4167,
    avg_lng: -61.3333,
  },
  {
    label: 'Dominican Republic',
    alpha_2: 'DO',
    value: 'DOM',
    id: 214,
    avg_lat: 19,
    avg_lng: -70.6667,
  },
  {
    label: 'Ecuador',
    alpha_2: 'EC',
    value: 'ECU',
    id: 218,
    avg_lat: -2,
    avg_lng: -77.5,
  },
  {
    label: 'Egypt',
    alpha_2: 'EG',
    value: 'EGY',
    id: 818,
    avg_lat: 27,
    avg_lng: 30,
  },
  {
    label: 'El Salvador',
    alpha_2: 'SV',
    value: 'SLV',
    id: 222,
    avg_lat: 13.8333,
    avg_lng: -88.9167,
  },
  {
    label: 'Equatorial Guinea',
    alpha_2: 'GQ',
    value: 'GNQ',
    id: 226,
    avg_lat: 2,
    avg_lng: 10,
  },
  {
    label: 'Eritrea',
    alpha_2: 'ER',
    value: 'ERI',
    id: 232,
    avg_lat: 15,
    avg_lng: 39,
  },
  {
    label: 'Estonia',
    alpha_2: 'EE',
    value: 'EST',
    id: 233,
    avg_lat: 59,
    avg_lng: 26,
  },
  {
    label: 'Ethiopia',
    alpha_2: 'ET',
    value: 'ETH',
    id: 231,
    avg_lat: 8,
    avg_lng: 38,
  },
  {
    label: 'Falkland Islands (Malvinas)',
    alpha_2: 'FK',
    value: 'FLK',
    id: 238,
    avg_lat: -51.75,
    avg_lng: -59,
  },
  {
    label: 'Faroe Islands',
    alpha_2: 'FO',
    value: 'FRO',
    id: 234,
    avg_lat: 62,
    avg_lng: -7,
  },
  {
    label: 'Fiji',
    alpha_2: 'FJ',
    value: 'FJI',
    id: 242,
    avg_lat: -18,
    avg_lng: 175,
  },
  {
    label: 'Finland',
    alpha_2: 'FI',
    value: 'FIN',
    id: 246,
    avg_lat: 64,
    avg_lng: 26,
  },
  {
    label: 'France',
    alpha_2: 'FR',
    value: 'FRA',
    id: 250,
    avg_lat: 46,
    avg_lng: 2,
  },
  {
    label: 'French Guiana',
    alpha_2: 'GF',
    value: 'GUF',
    id: 254,
    avg_lat: 4,
    avg_lng: -53,
  },
  {
    label: 'French Polynesia',
    alpha_2: 'PF',
    value: 'PYF',
    id: 258,
    avg_lat: -15,
    avg_lng: -140,
  },
  {
    label: 'French Southern Territories',
    alpha_2: 'TF',
    value: 'ATF',
    id: 260,
    avg_lat: -43,
    avg_lng: 67,
  },
  {
    label: 'Gabon',
    alpha_2: 'GA',
    value: 'GAB',
    id: 266,
    avg_lat: -1,
    avg_lng: 11.75,
  },
  {
    label: 'Gambia',
    alpha_2: 'GM',
    value: 'GMB',
    id: 270,
    avg_lat: 13.4667,
    avg_lng: -16.5667,
  },
  {
    label: 'Georgia',
    alpha_2: 'GE',
    value: 'GEO',
    id: 268,
    avg_lat: 42,
    avg_lng: 43.5,
  },
  {
    label: 'Germany',
    alpha_2: 'DE',
    value: 'DEU',
    id: 276,
    avg_lat: 51,
    avg_lng: 9,
  },
  {
    label: 'Ghana',
    alpha_2: 'GH',
    value: 'GHA',
    id: 288,
    avg_lat: 8,
    avg_lng: -2,
  },
  {
    label: 'Gibraltar',
    alpha_2: 'GI',
    value: 'GIB',
    id: 292,
    avg_lat: 36.1833,
    avg_lng: -5.3667,
  },
  {
    label: 'Greece',
    alpha_2: 'GR',
    value: 'GRC',
    id: 300,
    avg_lat: 39,
    avg_lng: 22,
  },
  {
    label: 'Greenland',
    alpha_2: 'GL',
    value: 'GRL',
    id: 304,
    avg_lat: 72,
    avg_lng: -40,
  },
  {
    label: 'Grenada',
    alpha_2: 'GD',
    value: 'GRD',
    id: 308,
    avg_lat: 12.1167,
    avg_lng: -61.6667,
  },
  {
    label: 'Guadeloupe',
    alpha_2: 'GP',
    value: 'GLP',
    id: 312,
    avg_lat: 16.25,
    avg_lng: -61.5833,
  },
  {
    label: 'Guam',
    alpha_2: 'GU',
    value: 'GUM',
    id: 316,
    avg_lat: 13.4667,
    avg_lng: 144.7833,
  },
  {
    label: 'Guatemala',
    alpha_2: 'GT',
    value: 'GTM',
    id: 320,
    avg_lat: 15.5,
    avg_lng: -90.25,
  },
  {
    label: 'Guernsey',
    alpha_2: 'GG',
    value: 'GGY',
    id: 831,
    avg_lat: 49.5,
    avg_lng: -2.56,
  },
  {
    label: 'Guinea',
    alpha_2: 'GN',
    value: 'GIN',
    id: 324,
    avg_lat: 11,
    avg_lng: -10,
  },
  {
    label: 'Guinea-Bissau',
    alpha_2: 'GW',
    value: 'GNB',
    id: 624,
    avg_lat: 12,
    avg_lng: -15,
  },
  {
    label: 'Guyana',
    alpha_2: 'GY',
    value: 'GUY',
    id: 328,
    avg_lat: 5,
    avg_lng: -59,
  },
  {
    label: 'Haiti',
    alpha_2: 'HT',
    value: 'HTI',
    id: 332,
    avg_lat: 19,
    avg_lng: -72.4167,
  },
  {
    label: 'Heard Island and McDonald Islands',
    alpha_2: 'HM',
    value: 'HMD',
    id: 334,
    avg_lat: -53.1,
    avg_lng: 72.5167,
  },
  {
    label: 'Holy See (Vatican City State)',
    alpha_2: 'VA',
    value: 'VAT',
    id: 336,
    avg_lat: 41.9,
    avg_lng: 12.45,
  },
  {
    label: 'Honduras',
    alpha_2: 'HN',
    value: 'HND',
    id: 340,
    avg_lat: 15,
    avg_lng: -86.5,
  },
  {
    label: 'Hong Kong',
    alpha_2: 'HK',
    value: 'HKG',
    id: 344,
    avg_lat: 22.25,
    avg_lng: 114.1667,
  },
  {
    label: 'Hungary',
    alpha_2: 'HU',
    value: 'HUN',
    id: 348,
    avg_lat: 47,
    avg_lng: 20,
  },
  {
    label: 'Iceland',
    alpha_2: 'IS',
    value: 'ISL',
    id: 352,
    avg_lat: 65,
    avg_lng: -18,
  },
  {
    label: 'India',
    alpha_2: 'IN',
    value: 'IND',
    id: 356,
    avg_lat: 20,
    avg_lng: 77,
  },
  {
    label: 'Indonesia',
    alpha_2: 'ID',
    value: 'IDN',
    id: 360,
    avg_lat: -5,
    avg_lng: 120,
  },
  {
    label: 'Iran, Islamic Republic of',
    alpha_2: 'IR',
    value: 'IRN',
    id: 364,
    avg_lat: 32,
    avg_lng: 53,
  },
  {
    label: 'Iraq',
    alpha_2: 'IQ',
    value: 'IRQ',
    id: 368,
    avg_lat: 33,
    avg_lng: 44,
  },
  {
    label: 'Ireland',
    alpha_2: 'IE',
    value: 'IRL',
    id: 372,
    avg_lat: 53,
    avg_lng: -8,
  },
  {
    label: 'Isle of Man',
    alpha_2: 'IM',
    value: 'IMN',
    id: 833,
    avg_lat: 54.23,
    avg_lng: -4.55,
  },
  {
    label: 'Israel',
    alpha_2: 'IL',
    value: 'ISR',
    id: 376,
    avg_lat: 31.5,
    avg_lng: 34.75,
  },
  {
    label: 'Italy',
    alpha_2: 'IT',
    value: 'ITA',
    id: 380,
    avg_lat: 42.8333,
    avg_lng: 12.8333,
  },
  {
    label: 'Jamaica',
    alpha_2: 'JM',
    value: 'JAM',
    id: 388,
    avg_lat: 18.25,
    avg_lng: -77.5,
  },
  {
    label: 'Japan',
    alpha_2: 'JP',
    value: 'JPN',
    id: 392,
    avg_lat: 36,
    avg_lng: 138,
  },
  {
    label: 'Jersey',
    alpha_2: 'JE',
    value: 'JEY',
    id: 832,
    avg_lat: 49.21,
    avg_lng: -2.13,
  },
  {
    label: 'Jordan',
    alpha_2: 'JO',
    value: 'JOR',
    id: 400,
    avg_lat: 31,
    avg_lng: 36,
  },
  {
    label: 'Kazakhstan',
    alpha_2: 'KZ',
    value: 'KAZ',
    id: 398,
    avg_lat: 48,
    avg_lng: 68,
  },
  {
    label: 'Kenya',
    alpha_2: 'KE',
    value: 'KEN',
    id: 404,
    avg_lat: 1,
    avg_lng: 38,
  },
  {
    label: 'Kiribati',
    alpha_2: 'KI',
    value: 'KIR',
    id: 296,
    avg_lat: 1.4167,
    avg_lng: 173,
  },
  {
    label: "Korea, Democratic People's Republic of",
    alpha_2: 'KP',
    value: 'PRK',
    id: 408,
    avg_lat: 40,
    avg_lng: 127,
  },
  {
    label: 'Korea, Republic of',
    alpha_2: 'KR',
    value: 'KOR',
    id: 410,
    avg_lat: 37,
    avg_lng: 127.5,
  },
  {
    label: 'South Korea',
    alpha_2: 'KR',
    value: 'KOR',
    id: 410,
    avg_lat: 37,
    avg_lng: 127.5,
  },
  {
    label: 'Kuwait',
    alpha_2: 'KW',
    value: 'KWT',
    id: 414,
    avg_lat: 29.3375,
    avg_lng: 47.6581,
  },
  {
    label: 'Kyrgyzstan',
    alpha_2: 'KG',
    value: 'KGZ',
    id: 417,
    avg_lat: 41,
    avg_lng: 75,
  },
  {
    label: "Lao People's Democratic Republic",
    alpha_2: 'LA',
    value: 'LAO',
    id: 418,
    avg_lat: 18,
    avg_lng: 105,
  },
  {
    label: 'Latvia',
    alpha_2: 'LV',
    value: 'LVA',
    id: 428,
    avg_lat: 57,
    avg_lng: 25,
  },
  {
    label: 'Lebanon',
    alpha_2: 'LB',
    value: 'LBN',
    id: 422,
    avg_lat: 33.8333,
    avg_lng: 35.8333,
  },
  {
    label: 'Lesotho',
    alpha_2: 'LS',
    value: 'LSO',
    id: 426,
    avg_lat: -29.5,
    avg_lng: 28.5,
  },
  {
    label: 'Liberia',
    alpha_2: 'LR',
    value: 'LBR',
    id: 430,
    avg_lat: 6.5,
    avg_lng: -9.5,
  },
  {
    label: 'Libyan Arab Jamahiriya',
    alpha_2: 'LY',
    value: 'LBY',
    id: 434,
    avg_lat: 25,
    avg_lng: 17,
  },
  {
    label: 'Libya',
    alpha_2: 'LY',
    value: 'LBY',
    id: 434,
    avg_lat: 25,
    avg_lng: 17,
  },
  {
    label: 'Liechtenstein',
    alpha_2: 'LI',
    value: 'LIE',
    id: 438,
    avg_lat: 47.1667,
    avg_lng: 9.5333,
  },
  {
    label: 'Lithuania',
    alpha_2: 'LT',
    value: 'LTU',
    id: 440,
    avg_lat: 56,
    avg_lng: 24,
  },
  {
    label: 'Luxembourg',
    alpha_2: 'LU',
    value: 'LUX',
    id: 442,
    avg_lat: 49.75,
    avg_lng: 6.1667,
  },
  {
    label: 'Macao',
    alpha_2: 'MO',
    value: 'MAC',
    id: 446,
    avg_lat: 22.1667,
    avg_lng: 113.55,
  },
  {
    label: 'Macedonia, the former Yugoslav Republic of',
    alpha_2: 'MK',
    value: 'MKD',
    id: 807,
    avg_lat: 41.8333,
    avg_lng: 22,
  },
  {
    label: 'Madagascar',
    alpha_2: 'MG',
    value: 'MDG',
    id: 450,
    avg_lat: -20,
    avg_lng: 47,
  },
  {
    label: 'Malawi',
    alpha_2: 'MW',
    value: 'MWI',
    id: 454,
    avg_lat: -13.5,
    avg_lng: 34,
  },
  {
    label: 'Malaysia',
    alpha_2: 'MY',
    value: 'MYS',
    id: 458,
    avg_lat: 2.5,
    avg_lng: 112.5,
  },
  {
    label: 'Maldives',
    alpha_2: 'MV',
    value: 'MDV',
    id: 462,
    avg_lat: 3.25,
    avg_lng: 73,
  },
  {
    label: 'Mali',
    alpha_2: 'ML',
    value: 'MLI',
    id: 466,
    avg_lat: 17,
    avg_lng: -4,
  },
  {
    label: 'Malta',
    alpha_2: 'MT',
    value: 'MLT',
    id: 470,
    avg_lat: 35.8333,
    avg_lng: 14.5833,
  },
  {
    label: 'Marshall Islands',
    alpha_2: 'MH',
    value: 'MHL',
    id: 584,
    avg_lat: 9,
    avg_lng: 168,
  },
  {
    label: 'Martinique',
    alpha_2: 'MQ',
    value: 'MTQ',
    id: 474,
    avg_lat: 14.6667,
    avg_lng: -61,
  },
  {
    label: 'Mauritania',
    alpha_2: 'MR',
    value: 'MRT',
    id: 478,
    avg_lat: 20,
    avg_lng: -12,
  },
  {
    label: 'Mauritius',
    alpha_2: 'MU',
    value: 'MUS',
    id: 480,
    avg_lat: -20.2833,
    avg_lng: 57.55,
  },
  {
    label: 'Mayotte',
    alpha_2: 'YT',
    value: 'MYT',
    id: 175,
    avg_lat: -12.8333,
    avg_lng: 45.1667,
  },
  {
    label: 'Mexico',
    alpha_2: 'MX',
    value: 'MEX',
    id: 484,
    avg_lat: 23,
    avg_lng: -102,
  },
  {
    label: 'Micronesia, Federated States of',
    alpha_2: 'FM',
    value: 'FSM',
    id: 583,
    avg_lat: 6.9167,
    avg_lng: 158.25,
  },
  {
    label: 'Moldova, Republic of',
    alpha_2: 'MD',
    value: 'MDA',
    id: 498,
    avg_lat: 47,
    avg_lng: 29,
  },
  {
    label: 'Monaco',
    alpha_2: 'MC',
    value: 'MCO',
    id: 492,
    avg_lat: 43.7333,
    avg_lng: 7.4,
  },
  {
    label: 'Mongolia',
    alpha_2: 'MN',
    value: 'MNG',
    id: 496,
    avg_lat: 46,
    avg_lng: 105,
  },
  {
    label: 'Montenegro',
    alpha_2: 'ME',
    value: 'MNE',
    id: 499,
    avg_lat: 42,
    avg_lng: 19,
  },
  {
    label: 'Montserrat',
    alpha_2: 'MS',
    value: 'MSR',
    id: 500,
    avg_lat: 16.75,
    avg_lng: -62.2,
  },
  {
    label: 'Morocco',
    alpha_2: 'MA',
    value: 'MAR',
    id: 504,
    avg_lat: 32,
    avg_lng: -5,
  },
  {
    label: 'Mozambique',
    alpha_2: 'MZ',
    value: 'MOZ',
    id: 508,
    avg_lat: -18.25,
    avg_lng: 35,
  },
  {
    label: 'Myanmar',
    alpha_2: 'MM',
    value: 'MMR',
    id: 104,
    avg_lat: 22,
    avg_lng: 98,
  },
  {
    label: 'Burma',
    alpha_2: 'MM',
    value: 'MMR',
    id: 104,
    avg_lat: 22,
    avg_lng: 98,
  },
  {
    label: 'Namibia',
    alpha_2: 'NA',
    value: 'NAM',
    id: 516,
    avg_lat: -22,
    avg_lng: 17,
  },
  {
    label: 'Nauru',
    alpha_2: 'NR',
    value: 'NRU',
    id: 520,
    avg_lat: -0.5333,
    avg_lng: 166.9167,
  },
  {
    label: 'Nepal',
    alpha_2: 'NP',
    value: 'NPL',
    id: 524,
    avg_lat: 28,
    avg_lng: 84,
  },
  {
    label: 'Netherlands',
    alpha_2: 'NL',
    value: 'NLD',
    id: 528,
    avg_lat: 52.5,
    avg_lng: 5.75,
  },
  {
    label: 'Netherlands Antilles',
    alpha_2: 'AN',
    value: 'ANT',
    id: 530,
    avg_lat: 12.25,
    avg_lng: -68.75,
  },
  {
    label: 'New Caledonia',
    alpha_2: 'NC',
    value: 'NCL',
    id: 540,
    avg_lat: -21.5,
    avg_lng: 165.5,
  },
  {
    label: 'New Zealand',
    alpha_2: 'NZ',
    value: 'NZL',
    id: 554,
    avg_lat: -41,
    avg_lng: 174,
  },
  {
    label: 'Nicaragua',
    alpha_2: 'NI',
    value: 'NIC',
    id: 558,
    avg_lat: 13,
    avg_lng: -85,
  },
  {
    label: 'Niger',
    alpha_2: 'NE',
    value: 'NER',
    id: 562,
    avg_lat: 16,
    avg_lng: 8,
  },
  {
    label: 'Nigeria',
    alpha_2: 'NG',
    value: 'NGA',
    id: 566,
    avg_lat: 10,
    avg_lng: 8,
  },
  {
    label: 'Niue',
    alpha_2: 'NU',
    value: 'NIU',
    id: 570,
    avg_lat: -19.0333,
    avg_lng: -169.8667,
  },
  {
    label: 'Norfolk Island',
    alpha_2: 'NF',
    value: 'NFK',
    id: 574,
    avg_lat: -29.0333,
    avg_lng: 167.95,
  },
  {
    label: 'Northern Mariana Islands',
    alpha_2: 'MP',
    value: 'MNP',
    id: 580,
    avg_lat: 15.2,
    avg_lng: 145.75,
  },
  {
    label: 'Norway',
    alpha_2: 'NO',
    value: 'NOR',
    id: 578,
    avg_lat: 62,
    avg_lng: 10,
  },
  {
    label: 'Oman',
    alpha_2: 'OM',
    value: 'OMN',
    id: 512,
    avg_lat: 21,
    avg_lng: 57,
  },
  {
    label: 'Pakistan',
    alpha_2: 'PK',
    value: 'PAK',
    id: 586,
    avg_lat: 30,
    avg_lng: 70,
  },
  {
    label: 'Palau',
    alpha_2: 'PW',
    value: 'PLW',
    id: 585,
    avg_lat: 7.5,
    avg_lng: 134.5,
  },
  {
    label: 'Palestinian Territory, Occupied',
    alpha_2: 'PS',
    value: 'PSE',
    id: 275,
    avg_lat: 32,
    avg_lng: 35.25,
  },
  {
    label: 'Panama',
    alpha_2: 'PA',
    value: 'PAN',
    id: 591,
    avg_lat: 9,
    avg_lng: -80,
  },
  {
    label: 'Papua New Guinea',
    alpha_2: 'PG',
    value: 'PNG',
    id: 598,
    avg_lat: -6,
    avg_lng: 147,
  },
  {
    label: 'Paraguay',
    alpha_2: 'PY',
    value: 'PRY',
    id: 600,
    avg_lat: -23,
    avg_lng: -58,
  },
  {
    label: 'Peru',
    alpha_2: 'PE',
    value: 'PER',
    id: 604,
    avg_lat: -10,
    avg_lng: -76,
  },
  {
    label: 'Philippines',
    alpha_2: 'PH',
    value: 'PHL',
    id: 608,
    avg_lat: 13,
    avg_lng: 122,
  },
  {
    label: 'Pitcairn',
    alpha_2: 'PN',
    value: 'PCN',
    id: 612,
    avg_lat: -24.7,
    avg_lng: -127.4,
  },
  {
    label: 'Poland',
    alpha_2: 'PL',
    value: 'POL',
    id: 616,
    avg_lat: 52,
    avg_lng: 20,
  },
  {
    label: 'Portugal',
    alpha_2: 'PT',
    value: 'PRT',
    id: 620,
    avg_lat: 39.5,
    avg_lng: -8,
  },
  {
    label: 'Puerto Rico',
    alpha_2: 'PR',
    value: 'PRI',
    id: 630,
    avg_lat: 18.25,
    avg_lng: -66.5,
  },
  {
    label: 'Qatar',
    alpha_2: 'QA',
    value: 'QAT',
    id: 634,
    avg_lat: 25.5,
    avg_lng: 51.25,
  },
  {
    label: 'Réunion',
    alpha_2: 'RE',
    value: 'REU',
    id: 638,
    avg_lat: -21.1,
    avg_lng: 55.6,
  },
  {
    label: 'Romania',
    alpha_2: 'RO',
    value: 'ROU',
    id: 642,
    avg_lat: 46,
    avg_lng: 25,
  },
  {
    label: 'Russian Federation',
    alpha_2: 'RU',
    value: 'RUS',
    id: 643,
    avg_lat: 60,
    avg_lng: 100,
  },
  {
    label: 'Russia',
    alpha_2: 'RU',
    value: 'RUS',
    id: 643,
    avg_lat: 60,
    avg_lng: 100,
  },
  {
    label: 'Rwanda',
    alpha_2: 'RW',
    value: 'RWA',
    id: 646,
    avg_lat: -2,
    avg_lng: 30,
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha_2: 'SH',
    value: 'SHN',
    id: 654,
    avg_lat: -15.9333,
    avg_lng: -5.7,
  },
  {
    label: 'Saint Kitts and Nevis',
    alpha_2: 'KN',
    value: 'KNA',
    id: 659,
    avg_lat: 17.3333,
    avg_lng: -62.75,
  },
  {
    label: 'Saint Lucia',
    alpha_2: 'LC',
    value: 'LCA',
    id: 662,
    avg_lat: 13.8833,
    avg_lng: -61.1333,
  },
  {
    label: 'Saint Pierre and Miquelon',
    alpha_2: 'PM',
    value: 'SPM',
    id: 666,
    avg_lat: 46.8333,
    avg_lng: -56.3333,
  },
  {
    label: 'Saint Vincent and the Grenadines',
    alpha_2: 'VC',
    value: 'VCT',
    id: 670,
    avg_lat: 13.25,
    avg_lng: -61.2,
  },
  {
    label: 'Saint Vincent & the Grenadines',
    alpha_2: 'VC',
    value: 'VCT',
    id: 670,
    avg_lat: 13.25,
    avg_lng: -61.2,
  },
  {
    label: 'St. Vincent and the Grenadines',
    alpha_2: 'VC',
    value: 'VCT',
    id: 670,
    avg_lat: 13.25,
    avg_lng: -61.2,
  },
  {
    label: 'Samoa',
    alpha_2: 'WS',
    value: 'WSM',
    id: 882,
    avg_lat: -13.5833,
    avg_lng: -172.3333,
  },
  {
    label: 'San Marino',
    alpha_2: 'SM',
    value: 'SMR',
    id: 674,
    avg_lat: 43.7667,
    avg_lng: 12.4167,
  },
  {
    label: 'Sao Tome and Principe',
    alpha_2: 'ST',
    value: 'STP',
    id: 678,
    avg_lat: 1,
    avg_lng: 7,
  },
  {
    label: 'Saudi Arabia',
    alpha_2: 'SA',
    value: 'SAU',
    id: 682,
    avg_lat: 25,
    avg_lng: 45,
  },
  {
    label: 'Senegal',
    alpha_2: 'SN',
    value: 'SEN',
    id: 686,
    avg_lat: 14,
    avg_lng: -14,
  },
  {
    label: 'Serbia',
    alpha_2: 'RS',
    value: 'SRB',
    id: 688,
    avg_lat: 44,
    avg_lng: 21,
  },
  {
    label: 'Seychelles',
    alpha_2: 'SC',
    value: 'SYC',
    id: 690,
    avg_lat: -4.5833,
    avg_lng: 55.6667,
  },
  {
    label: 'Sierra Leone',
    alpha_2: 'SL',
    value: 'SLE',
    id: 694,
    avg_lat: 8.5,
    avg_lng: -11.5,
  },
  {
    label: 'Singapore',
    alpha_2: 'SG',
    value: 'SGP',
    id: 702,
    avg_lat: 1.3667,
    avg_lng: 103.8,
  },
  {
    label: 'Slovakia',
    alpha_2: 'SK',
    value: 'SVK',
    id: 703,
    avg_lat: 48.6667,
    avg_lng: 19.5,
  },
  {
    label: 'Slovenia',
    alpha_2: 'SI',
    value: 'SVN',
    id: 705,
    avg_lat: 46,
    avg_lng: 15,
  },
  {
    label: 'Solomon Islands',
    alpha_2: 'SB',
    value: 'SLB',
    id: 90,
    avg_lat: -8,
    avg_lng: 159,
  },
  {
    label: 'Somalia',
    alpha_2: 'SO',
    value: 'SOM',
    id: 706,
    avg_lat: 10,
    avg_lng: 49,
  },
  {
    label: 'South Africa',
    alpha_2: 'ZA',
    value: 'ZAF',
    id: 710,
    avg_lat: -29,
    avg_lng: 24,
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    alpha_2: 'GS',
    value: 'SGS',
    id: 239,
    avg_lat: -54.5,
    avg_lng: -37,
  },
  {
    label: 'South Sudan',
    alpha_2: 'SS',
    value: 'SSD',
    id: 728,
    avg_lat: 8,
    avg_lng: 30,
  },
  {
    label: 'Spain',
    alpha_2: 'ES',
    value: 'ESP',
    id: 724,
    avg_lat: 40,
    avg_lng: -4,
  },
  {
    label: 'Sri Lanka',
    alpha_2: 'LK',
    value: 'LKA',
    id: 144,
    avg_lat: 7,
    avg_lng: 81,
  },
  {
    label: 'Sudan',
    alpha_2: 'SD',
    value: 'SDN',
    id: 736,
    avg_lat: 15,
    avg_lng: 30,
  },
  {
    label: 'Suriname',
    alpha_2: 'SR',
    value: 'SUR',
    id: 740,
    avg_lat: 4,
    avg_lng: -56,
  },
  {
    label: 'Svalbard and Jan Mayen',
    alpha_2: 'SJ',
    value: 'SJM',
    id: 744,
    avg_lat: 78,
    avg_lng: 20,
  },
  {
    label: 'Swaziland',
    alpha_2: 'SZ',
    value: 'SWZ',
    id: 748,
    avg_lat: -26.5,
    avg_lng: 31.5,
  },
  {
    label: 'Sweden',
    alpha_2: 'SE',
    value: 'SWE',
    id: 752,
    avg_lat: 62,
    avg_lng: 15,
  },
  {
    label: 'Switzerland',
    alpha_2: 'CH',
    value: 'CHE',
    id: 756,
    avg_lat: 47,
    avg_lng: 8,
  },
  {
    label: 'Syrian Arab Republic',
    alpha_2: 'SY',
    value: 'SYR',
    id: 760,
    avg_lat: 35,
    avg_lng: 38,
  },
  {
    label: 'Taiwan, Province of China',
    alpha_2: 'TW',
    value: 'TWN',
    id: 158,
    avg_lat: 23.5,
    avg_lng: 121,
  },
  {
    label: 'Taiwan',
    alpha_2: 'TW',
    value: 'TWN',
    id: 158,
    avg_lat: 23.5,
    avg_lng: 121,
  },
  {
    label: 'Tajikistan',
    alpha_2: 'TJ',
    value: 'TJK',
    id: 762,
    avg_lat: 39,
    avg_lng: 71,
  },
  {
    label: 'Tanzania, United Republic of',
    alpha_2: 'TZ',
    value: 'TZA',
    id: 834,
    avg_lat: -6,
    avg_lng: 35,
  },
  {
    label: 'Thailand',
    alpha_2: 'TH',
    value: 'THA',
    id: 764,
    avg_lat: 15,
    avg_lng: 100,
  },
  {
    label: 'Timor-Leste',
    alpha_2: 'TL',
    value: 'TLS',
    id: 626,
    avg_lat: -8.55,
    avg_lng: 125.5167,
  },
  {
    label: 'Togo',
    alpha_2: 'TG',
    value: 'TGO',
    id: 768,
    avg_lat: 8,
    avg_lng: 1.1667,
  },
  {
    label: 'Tokelau',
    alpha_2: 'TK',
    value: 'TKL',
    id: 772,
    avg_lat: -9,
    avg_lng: -172,
  },
  {
    label: 'Tonga',
    alpha_2: 'TO',
    value: 'TON',
    id: 776,
    avg_lat: -20,
    avg_lng: -175,
  },
  {
    label: 'Trinidad and Tobago',
    alpha_2: 'TT',
    value: 'TTO',
    id: 780,
    avg_lat: 11,
    avg_lng: -61,
  },
  {
    label: 'Tunisia',
    alpha_2: 'TN',
    value: 'TUN',
    id: 788,
    avg_lat: 34,
    avg_lng: 9,
  },
  {
    label: 'Turkey',
    alpha_2: 'TR',
    value: 'TUR',
    id: 792,
    avg_lat: 39,
    avg_lng: 35,
  },
  {
    label: 'Turkmenistan',
    alpha_2: 'TM',
    value: 'TKM',
    id: 795,
    avg_lat: 40,
    avg_lng: 60,
  },
  {
    label: 'Turks and Caicos Islands',
    alpha_2: 'TC',
    value: 'TCA',
    id: 796,
    avg_lat: 21.75,
    avg_lng: -71.5833,
  },
  {
    label: 'Tuvalu',
    alpha_2: 'TV',
    value: 'TUV',
    id: 798,
    avg_lat: -8,
    avg_lng: 178,
  },
  {
    label: 'Uganda',
    alpha_2: 'UG',
    value: 'UGA',
    id: 800,
    avg_lat: 1,
    avg_lng: 32,
  },
  {
    label: 'Ukraine',
    alpha_2: 'UA',
    value: 'UKR',
    id: 804,
    avg_lat: 49,
    avg_lng: 32,
  },
  {
    label: 'United Arab Emirates',
    alpha_2: 'AE',
    value: 'ARE',
    id: 784,
    avg_lat: 24,
    avg_lng: 54,
  },
  {
    label: 'United Kingdom',
    alpha_2: 'GB',
    value: 'GBR',
    id: 826,
    avg_lat: 54,
    avg_lng: -2,
  },
  {
    label: 'United States',
    alpha_2: 'US',
    value: 'USA',
    id: 840,
    avg_lat: 38,
    avg_lng: -97,
  },
  {
    label: 'United States Minor Outlying Islands',
    alpha_2: 'UM',
    value: 'UMI',
    id: 581,
    avg_lat: 19.2833,
    avg_lng: 166.6,
  },
  {
    label: 'Uruguay',
    alpha_2: 'UY',
    value: 'URY',
    id: 858,
    avg_lat: -33,
    avg_lng: -56,
  },
  {
    label: 'Uzbekistan',
    alpha_2: 'UZ',
    value: 'UZB',
    id: 860,
    avg_lat: 41,
    avg_lng: 64,
  },
  {
    label: 'Vanuatu',
    alpha_2: 'VU',
    value: 'VUT',
    id: 548,
    avg_lat: -16,
    avg_lng: 167,
  },
  {
    label: 'Venezuela, Bolivarian Republic of',
    alpha_2: 'VE',
    value: 'VEN',
    id: 862,
    avg_lat: 8,
    avg_lng: -66,
  },
  {
    label: 'Venezuela',
    alpha_2: 'VE',
    value: 'VEN',
    id: 862,
    avg_lat: 8,
    avg_lng: -66,
  },
  {
    label: 'Viet Nam',
    alpha_2: 'VN',
    value: 'VNM',
    id: 704,
    avg_lat: 16,
    avg_lng: 106,
  },
  {
    label: 'Vietnam',
    alpha_2: 'VN',
    value: 'VNM',
    id: 704,
    avg_lat: 16,
    avg_lng: 106,
  },
  {
    label: 'Virgin Islands, British',
    alpha_2: 'VG',
    value: 'VGB',
    id: 92,
    avg_lat: 18.5,
    avg_lng: -64.5,
  },
  {
    label: 'Virgin Islands, U.S.',
    alpha_2: 'VI',
    value: 'VIR',
    id: 850,
    avg_lat: 18.3333,
    avg_lng: -64.8333,
  },
  {
    label: 'Wallis and Futuna',
    alpha_2: 'WF',
    value: 'WLF',
    id: 876,
    avg_lat: -13.3,
    avg_lng: -176.2,
  },
  {
    label: 'Western Sahara',
    alpha_2: 'EH',
    value: 'ESH',
    id: 732,
    avg_lat: 24.5,
    avg_lng: -13,
  },
  {
    label: 'Yemen',
    alpha_2: 'YE',
    value: 'YEM',
    id: 887,
    avg_lat: 15,
    avg_lng: 48,
  },
  {
    label: 'Zambia',
    alpha_2: 'ZM',
    value: 'ZMB',
    id: 894,
    avg_lat: -15,
    avg_lng: 30,
  },
  {
    label: 'Zimbabwe',
    alpha_2: 'ZW',
    value: 'ZWE',
    id: 716,
    avg_lat: -20,
    avg_lng: 30,
  },
];
