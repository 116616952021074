import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment-timezone';

import useShipmentForm from '../../hooks/forms/useShipmentForm';
import FormAddress from '../../components/wrld/form/FormAddress';
import FormProviders from '../../components/wrld/form/FormProviders';
import FormCargo from '../../components/wrld/form/FormCargo';
import CashOnDelivery from '../../components/wrld/form/services/CashOnDelivery';
import DeliveryNote from '../../components/wrld/form/services/DeliveryNote';
import FloorDelivery from '../../components/wrld/form/services/FloorDelivery';
import Insurance from '../../components/wrld/form/services/Insurance';
import DatepickerField from '../../components/wrld/form/control/DatepickerField';
// eslint-disable-next-line max-len
// import CreatableSelectField from '../../components/wrld/form/control/CreatableSelectField';
import Field from '../../components/wrld/form/control/Field';
import Recapitulation from '../../components/wrld/form/recapitulation/Recapitulation';
import { ShipmentFormData } from '../../hooks/forms/types.d.t';
import ScrollToTop from '../../components/wrld/misc/ScrollToTop';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const ShipmentsCreate = (): React.ReactElement => {
  const initialState: ShipmentFormData = {
    pickup: {
      date: moment().toDate(),
      timeFrom: moment().toDate(),
      timeTo: moment().add(1, 'hour').toDate(),
    },
    origin: null,
    destination: null,
    cargo: null,
    provider: null,
  };

  // initial values may be passed in

  const {
    state, meta, dispatch, quoting, totalPrice, errors,
  } = useShipmentForm({
    initialValues: initialState,
    initialMeta: {
      filled: {
        origin: false,
        pickup: false,
        destination: false,
        cargo: false,
        provider: false,
        services: false,
        misc: false,
      },
      show: {
        providers: false,
        services: false,
        misc: false,
      },
      progress: {
        width: 0,
        message: '',
        hasError: false,
      },
    },
  });

  useEffect(() => () => {
    dispatch({
      id: null, // TODO might be useful to have an id here
      type: 'SET_SINGLE_VALUE',
      key: 'pickup',
      field: 'timeTo',
      value: undefined,
    });
  }, [state.pickup.timeFrom]);

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    // TODO work with errors array - should be empty on submit!
    // eslint-disable-next-line no-console
    console.log(state);
  };

  return (
    <div className="bg-gray-100 min-h-full pt-24 pb-10">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <form
          onSubmit={handleSubmitForm}
        >
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <div id="form-origin">
                <div className="shadow sm:rounded-md">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Nakládka</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Vyplňte místo vyzvednutí.
                      </p>
                    </div>
                    <div>
                      <FormAddress
                        errors={errors}
                        type="origin"
                        dispatch={dispatch}
                        state={state}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div id="form-pickup">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Datum a čas odjezdu</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Vyplňte informace o nakládce.
                      </p>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <DatepickerField
                        errors={errors}
                        isRequired
                        label="Datum odjezdu"
                        placeholderText="Zadejte datum odjezdu"
                        wrapperClassName="col-span-3 sm:col-span-2"
                        id="pickup-date"
                        name="pickup-date"
                        selected={state.pickup.date}
                        onChange={(value) => dispatch({
                          id: 'pickup-date',
                          type: 'SET_SINGLE_VALUE',
                          key: 'pickup',
                          field: 'date',
                          value: value as Date,
                        })}
                      />

                      <DatepickerField
                        errors={errors}
                        label="Nakládkové okno"
                        wrapperClassName="col-span-3 sm:col-span-2"
                        id="pickup-time-from"
                        name="pickup-time-from"
                        cornerHint="Od"
                        selected={state.pickup.timeFrom}
                        time
                        onChange={(value) => dispatch({
                          id: 'pickup-time-from',
                          type: 'SET_SINGLE_VALUE',
                          key: 'pickup',
                          field: 'timeFrom',
                          value: value as Date,
                        })}
                      />

                      <DatepickerField
                        errors={errors}
                        label=""
                        cornerHint="Do"
                        wrapperClassName="col-span-3 sm:col-span-2"
                        id="pickup-time-to"
                        name="pickup-time-to"
                        selected={state.pickup.timeTo}
                        minTime={state.pickup.timeFrom}
                        time
                        onChange={(value) => dispatch({
                          id: 'pickup-time-to',
                          type: 'SET_SINGLE_VALUE',
                          key: 'pickup',
                          field: 'timeTo',
                          value: value as Date,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div id="form-destination">
                <div className="shadow sm:rounded-md">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Vykládka</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Vyplňte místo doručení.
                      </p>
                    </div>
                    <div>
                      <FormAddress
                        errors={errors}
                        type="destination"
                        dispatch={dispatch}
                        state={state}
                        filterId={state?.origin?.id}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div id="form-cargo" className="relative">
                <div className="shadow sm:rounded-md">
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Zboží k přepravě</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Vyplňte co chcete přepravit.
                      </p>
                    </div>

                    <div>
                      <FormCargo
                        dispatch={dispatch}
                        state={state}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {meta.show.providers && (
                <div>
                  <div className="shadow sm:rounded-md">
                    <div className="bg-white">
                      <div className="py-6 px-4 sm:p-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Vyberte vhodného přepravce</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Nabídky jsou seřazeny sestupně podle ceny.
                        </p>
                      </div>
                      <FormProviders
                        dispatch={dispatch}
                        loading={quoting?.loading}
                        providers={quoting?.providers}
                      />
                    </div>
                  </div>
                </div>
              )}

              {meta.show.services && (
                <div>
                  <div className="shadow sm:rounded-md">
                    <div className="bg-white">
                      <div className="py-6 px-4 sm:p-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Příplatkové služby</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Vyberte si z nabídky příplatkových služeb.
                        </p>
                      </div>
                      <div>
                        <CashOnDelivery
                          dispatch={dispatch}
                          state={state}
                          errors={errors}
                        />
                        <DeliveryNote
                          dispatch={dispatch}
                        />
                        <FloorDelivery
                          dispatch={dispatch}
                        />
                        <Insurance
                          dispatch={dispatch}
                          state={state}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {meta.show.misc && (
                <div>
                  <div className="shadow sm:rounded-md">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Ostatní</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Doplňte ostatní informace o zásilce.
                        </p>
                      </div>

                      <Field
                        errors={errors}
                        wrapperClass="col-span-3"
                        label="Název (referenční číslo)"
                        name="reference"
                        id="reference"
                        autoComplete="reference"
                        value={state.name}
                        onChange={(value) => dispatch({
                          id: 'reference',
                          type: 'SET_SINGLE_VALUE',
                          key: 'root',
                          field: 'name',
                          payload: value,
                        })}
                      />

                      {/* <CreatableSelectField */}
                      {/*  errors={errors} */}
                      {/*  id="tags" */}
                      {/*  name="tags" */}
                      {/*  wrapperClass="col-span-3" */}
                      {/*  label="Štítky" */}
                      {/*  placeholder="Vyberte štítky" */}
                      {/*  onChange={(value) => dispatch({ */}
                      {/*    id: 'tags', */}
                      {/*    type: 'SET_SINGLE_VALUE', */}
                      {/*    key: 'root', */}
                      {/*    field: 'labels', */}
                      {/*    payload: value, */}
                      {/*  })} */}
                      {/* /> */}

                      {/* <Field */}
                      {/*  errors={errors} */}
                      {/*  wrapperClass="col-span-3" */}
                      {/*  label="Poznámka" */}
                      {/*  description="Napište něco zajímvého do poznámky." */}
                      {/*  isTextarea */}
                      {/*  textareaRows={3} */}
                      {/*  name="note" */}
                      {/*  id="note" */}
                      {/*  autoComplete="off" */}
                      {/*  value={state.note} */}
                      {/*  onChange={(value) => dispatch({ */}
                      {/*    id: 'note', */}
                      {/*    type: 'SET_SINGLE_VALUE', */}
                      {/*    key: 'root', */}
                      {/*    field: 'note', */}
                      {/*    payload: value, */}
                      {/*  })} */}
                      {/* /> */}
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
                      >
                        Uložit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Recapitulation state={state} meta={meta} errors={errors} totalPrice={totalPrice} />
          </div>
        </form>
        <ScrollToTop />
      </div>
    </div>
  );
};

export default ShipmentsCreate;
