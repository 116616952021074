import React from 'react';

type DividerProps = {
  shade?: number;
};

const Divider = ({ shade = 300 }: DividerProps): React.ReactElement => (
  <div className="relative">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className={`w-full border-t border-gray-${shade}`} />
    </div>
  </div>
);

Divider.defaultProps = {
  shade: 300,
};

export default Divider;
