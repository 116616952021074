import React, {
  useEffect, useRef, Fragment, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Channel,
  ChannelHeader, messageHasReactions,
  MessageInput,
  MessageList,
  SendButton, MessageRepliesCountButton,
  Thread, useMessageContext, useChannelStateContext,
  MessageTimestamp, MessageOptions, Attachment, MessageStatus, SimpleReactionsList,
  Window, Avatar, DateSeparator, ReactionSelector, ReactionsList, StreamMessage, useChatContext,
} from 'stream-chat-react';

// import Undraw from 'react-undraw';
import {
  TagIcon, CurrencyEuroIcon, TruckIcon, OfficeBuildingIcon,
} from '@heroicons/react/solid';
import { Message } from 'stream-chat';
import useShipment from '../../../hooks/store/useShipment';

import ShipmentNotFound from '../../ShipmentNotFound';
import ShipmentHeading from '../../../components/wrld/shipment/ShipmentHeading';

import SelectedProviderSection from '../../../components/wrld/shipment/SelectedProviderSection';
import ShipmentUnitCard from '../../../components/wrld/shipment/ShipmentUnitCard';
import ShipmentLocationSection from '../../../components/wrld/shipment/ShipmentLocationSection';
import {
  Cargo, Stop, Shipment, Unit,
} from '../../../components/wrld/types/shipment';
import useGetStreamFeed from '../../../hooks/activity/useGetStreamFeed';
import Divider from '../../../components/wrld/general/Divider';
import useSilentMessage from '../../../hooks/activity/useSilentMessage';
import Button from '../../../components/wrld/Button';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const UnitsSection = ({ cargo }: { cargo: Cargo | undefined }): React.ReactElement => (
  <div>
    <div className="grid text-xs font-semibold grid-cols-12 px-4 pb-1 text-gray-400">
      <div className="col-span-1" />
      <div className="col-span-2">Typ</div>
      <div className="col-span-2">Hmotnost</div>
      <div className="col-span-3">Rozměry</div>
      <div className="col-span-3 text-center">Status</div>
      <div className="col-span-1 text-center">Tisk</div>
    </div>
    <div className="bg-white border overflow-hidden sm:rounded-lg mb-16">
      <div className="px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <ul className="rounded-md divide-y divide-gray-200">
            {cargo?.units.map((unit, idx) => (
              <li
                key={`unit-${unit.kind}-${idx}`}
                className="px-4 py-3 flex items-center justify-between text-sm hover:bg-gray-50"
              >
                <ShipmentUnitCard
                  unit={unit}
                  idx={idx + 1}
                  total={cargo?.units.length}
                />
              </li>
            ))}
          </ul>
        </dl>
      </div>
    </div>
  </div>
);

type LocationSectionProps = {
  pickup?: Stop,
  delivery?: Stop,
};

const LocationSection = ({ pickup, delivery }: LocationSectionProps): JSX.Element => (
  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
      <ShipmentLocationSection variant="pickup" stop={pickup} />
      <ShipmentLocationSection variant="delivery" stop={delivery} />
    </div>
  </div>
);

LocationSection.defaultProps = {
  pickup: undefined,
  delivery: undefined,
};

type ShipmentContentProps = {
  shipment: Shipment,
  cancel: () => void,
};

const ShipmentContent = ({ shipment, cancel }: ShipmentContentProps): JSX.Element => (
  <div className="py-5 px-10 flex flex-col space-y-10">
    <ShipmentHeading
      name={shipment.name}
      reference={shipment.reference}
      status={shipment.selectedProvider?.status}
      created={{ name: 'Joe Doe', at: new Date('2022-01-05') }}
      cancel={cancel}
      getLabels={() => { }}
    />
    <div className="bg-white border overflow-hidden sm:rounded-lg mb-8">
      <SelectedProviderSection provider={shipment?.selectedProvider} />
      <LocationSection pickup={shipment?.pickup} delivery={shipment?.delivery} />
    </div>
    <UnitsSection cargo={shipment?.cargo} />
  </div>
);

const Welcome = (): React.ReactElement => {
  const { messages } = useChannelStateContext();
  const length = messages?.length ?? 0;
  const className = length > 0 ? 'ul' : 'list';

  useEffect(() => {
    const el = document.getElementById('welcome_message');
    const ul = document.getElementsByClassName(`str-chat__${className}`).item(0);
    if (el && ul) {
      // eslint-disable-next-line no-console
      // console.log('welcome!');
      if (el.offsetParent === null) {
        el.classList.toggle('hidden');
      }
      ul?.parentNode?.insertBefore(el, ul);
    }
  }, [length > 0]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

const MessageText = (): React.ReactElement => {
  const { message } = useMessageContext();
  return (
    <div className="message-text">
      <p>{message.text}</p>
      <div>
        {message.attachments && <Attachment attachments={message.attachments} />}
      </div>
    </div>
  );
};

const MessageUser = ({ isAdmin }: { isAdmin: boolean }): React.ReactElement => {
  const {
    message,
    showDetailedReactions,
    isReactionEnabled,
    reactionSelectorRef,
  } = useMessageContext();

  const userImage = message.user?.image;
  const userGeneratedImage = `https://getstream.io/random_png/?id=${
    message.user?.id || 'a'
  }&name=${
    message.user?.name || 'a'
  }`;
  const image = userImage || userGeneratedImage;
  const name = message.user?.name || 'Wereldo support';

  return (
    <>
      <div className="relative">
        <img
          className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
          src={image}
          alt=""
        />
        {isAdmin && (
        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
          <img src="/favicon.ico" className="h-5 w-5 text-gray-400" aria-hidden="true" alt="Wereldo" />
        </span>
        )}
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm">
            <a
              href="#"
              className={classNames(
                isAdmin ? 'text-red-600' : 'text-gray-900',
                'font-medium',
              )}
            >
              {name}
            </a>
            <div className="flex justify-between">
              <div className="mt-0.5 text-sm text-gray-500"><MessageTimestamp calendar /></div>
              <div className="mt-0.5 text-sm text-gray-500"><MessageStatus /></div>
            </div>
          </div>
          <div>
            <div>
              {showDetailedReactions && <ReactionSelector />}
              {message.latest_reactions && <ReactionsList reactions={message.latest_reactions} />}
            </div>
          </div>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          <MessageText />
        </div>
      </div>
    </>
  );
};

const CustomMessage = (): React.ReactElement => {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
    lastReceivedId,
    groupStyles,
  } = useMessageContext();

  const messageWrapperRef = useRef(null);
  const hasReactions = messageHasReactions(message);
  const isAdmin = (message.user?.role === 'admin' && !message.silent) || false;

  const { silent, ignore } = useSilentMessage(message);

  if (message.silent && ignore) {
    return <></>;
  }

  return (
    <div key={message.id}>
      <div className={classNames(
        'relative',
        !message.silent ? 'pb-3' : 'pb-6',
      )}
      >
        {message.id !== lastReceivedId ? (
          <span
            className={classNames(
              'absolute top-5 left-5 -ml-px h-full w-0.5',
              isAdmin ? 'bg-red-600' : 'bg-gray-200',
            )}
            aria-hidden="true"
          />
        ) : null}
        {message.silent ? (
          <div className="relative flex items-start space-x-3">
            {silent}
          </div>
        ) : (
          <div className="relative flex items-start space-x-3">
            <MessageUser isAdmin={isAdmin} />
          </div>
        )}
      </div>
    </div>
  );
};

type SupportButtonProps = {
  onClick: () => void;
};

const SupportButton = ({ onClick }: SupportButtonProps) => (
  <div className="pb-[8px] pt-[8px] pr-[40px] pl-[40px]">
    <button
      type="button"
      onClick={onClick}
      className="w-full flex justify-center px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Kontaktujte podporu
    </button>
  </div>
);

const ShipmentActivity = (): JSX.Element => {
  const { channel, isLoading } = useGetStreamFeed({
    channelId: `shipment${13}`,
    image: '/favicon.ico',
    name: 'shipment-15 - DHL Freight (by Wereldo)',
    team: 'organization-id',
  });

  const [inputHandler, setInputHandler] = useState<boolean>(false);

  const buttonClick = ():void => {
    setInputHandler(!inputHandler);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Channel channel={channel}>
      <Window>
        {/* <ChannelHeader /> */}
        <div />
        <MessageList Message={CustomMessage} disableDateSeparator />
        <Welcome />
        <div
          id="welcome_message"
          className="my-6 hidden flex flex-col items-center justify-center"
        >
          {/* <Undraw name="support" height="150px" /> */}
          <div className="my-3 w-[70%] text-center text-sm">
            Toto je chytrý Wereldo asistent.
            Bude vás informovat o všech změnách a skrze chat můžete rychle
            a jednoduše komunikovat s naším týmem,
            který je tu pro vás k dispozici každý den od
            {' '}
            <span className="font-bold">
              08:00 - 17:00
            </span>
            .
          </div>
          <div className="my-3 w-full text-xs text-center">
            <div className="my-2">
              Uživatel
              {' '}
              <span className="font-bold">John Doe</span>
              {' '}
              vytvořil rozvoz minulý čtvrtek v 13:15
            </div>
            <Divider shade={200} />
          </div>
        </div>
        {inputHandler ? (
          <MessageInput />
        ) : (
          <SupportButton onClick={buttonClick} />
        )}
      </Window>
    </Channel>
  );
};

// test id: 61d97b163ca1fca4c1b84e0a
const ShipmentDetail = (): JSX.Element => {
  const { id } = useParams<'id'>();
  const { shipment, cancel } = useShipment(id || '');
  return (shipment === null) ? (<ShipmentNotFound />) : (
    <div className="mx-auto pt-16 max-h-full flex flex-row">
      <div className="w-full flex overflow-hidden bg-gray-100 pl-6 lg:pl-8">
        <div className="flex-1 overflow-y-scroll">
          <ShipmentContent shipment={shipment} cancel={cancel} />
        </div>
      </div>
      <div className="w-[800px] flex overflow-hidden border-l ">
        <div className="flex-1 overflow-y-scroll">
          <ShipmentActivity />
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetail;
