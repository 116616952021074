import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GetShipment, GetShipment_shipment_provider, GetShipmentVariables, GetShipment_shipment,
  GetShipment_shipment_cargo_units,
  GetShipment_shipment_pickup_location,
  GetShipment_shipment_provider_providers,
  GetShipment_shipment_provider_price,
} from '../__generated__/GetShipment';

import {
  parseCurrency, Price, Provider, ProviderKind, SelectedProvider,
} from '../../components/wrld/types/provider';

import { Shipment, Unit, UnitKind } from '../../components/wrld/types/shipment';
import { Location } from '../../components/wrld/types/location';
import { convertGQLProviderStatus } from './convertProviderStatus';

const STOP_DETAIL_FRAGMENT = gql`fragment StopDetail on Stop {
  at{
    from
    to
  }
  location{
    companyName
    address{
        street
        streetNo
        city
        postalCode
        countryCode
    }
    contact{
        name
        phone
        email
    }
    requirements{
      forklift
      taillift
    }
  }
}`;

const GET_SHIPMENT = gql`
${STOP_DETAIL_FRAGMENT}

query GetShipment($id: ID!) {
  shipment(id: $id){
    id
    name
    reference
    pickup{
       ... StopDetail
    }
    delivery{
      ... StopDetail
    }
    cargo{
        totalWeight
        totalVolume
        units{
            ... on BoxUnit{
                id
                weight
                volume
                width
                height
                length
            }
        }
    }
    provider{
        price{
            base
            fuel
            tolls
            total
            currency
        }
        status
        providers{
          ... on WereldoProvider{
            vehicle
          }
          ... on DhlFreightProvider {
            trackUrl
            carrierId
          }
          ... on WedoProvider {
            trackUrl
            carrierId
          }
        }
    }
  }
}`;

const CANCEL_SHIPMENT = gql`mutation CancelShipment($id: ID!){
  cancelShipment(id: $id)
}`;

function convertLocation(location: GetShipment_shipment_pickup_location): Location {
  return {
    company: location.companyName || undefined,
    address: {
      street: location.address.street || undefined,
      streetNo: location.address.streetNo || undefined,
      city: location.address.city || undefined,
      postal: location.address.postalCode || undefined,
      country: location.address.countryCode || undefined,
    },
    contact: {
      name: location.contact.name || undefined,
      phone: location.contact.phone || undefined,
      email: location.contact.email || undefined,
    },
    requiredEquipment: {
      taillift: location.requirements.taillift,
      forklift: location.requirements.forklift,
    },
  };
}

function convertUnits(unit: GetShipment_shipment_cargo_units): Unit {
  switch (unit.__typename) {
    case 'BoxUnit':
      return {
        kind: UnitKind.Box,
        weight: unit.weight,
        volume: unit.length * unit.height * unit.width,
        dimensions: {
          length: unit.length,
          height: unit.height,
          width: unit.width,
        },
      };
    default:
      throw new Error('unsupported unit kind');
  }
}

function convertSegment(segment: GetShipment_shipment_provider_providers): Provider {
  switch (segment.__typename) {
    case 'WereldoProvider':
      return {
        kind: ProviderKind.Wereldo,
        displayName: 'Wereldo',
        logoUrl: 'https://www.wereldo.com/favicon.ico',
        vehicle: segment.vehicle || undefined,
      };
    case 'WedoProvider':
      return {
        kind: ProviderKind.Wedo,
        displayName: 'WE|DO',
        logoUrl: 'https://www.lekarna.cz/upload/cms/images/wedologo.jpg',
        trackUrl: segment.trackUrl || undefined,
      };
    case 'DhlFreightProvider':
      return {
        kind: ProviderKind.DhlFreight,
        displayName: 'DHL Freight',
        logoUrl: 'https://www.dpdhl-brands.com/dhl/en/guides/design-basics/logo-and-claim/_jcr_content/parsys/imagetext/imagetextcontainer/image.coreimg.100.1024.png/1591965147742/versions-01.png',
        trackUrl: segment.trackUrl || undefined,
        // carrierId: '',
      };
    default:
      throw new Error('Unsupported provider');
  }
}

// TODO would be better to receive enum for currency
function convertPrice(price: GetShipment_shipment_provider_price): Price {
  return {
    base: price.base,
    fuel: price.fuel,
    tolls: price.tolls,
    total: price.total,
    currency: parseCurrency(price.currency),
  };
}

function convertProvider(selectedProvider: GetShipment_shipment_provider): SelectedProvider {
  const segments = selectedProvider.providers.map((segment) => convertSegment(segment));

  return {
    status: convertGQLProviderStatus(selectedProvider.status),
    segments,
    price: convertPrice(selectedProvider.price),
    quotes: [],
    invalidQuotes: [],
  };
}

function convertShipment(shipment: GetShipment_shipment): Shipment {
  const pickupLocation = convertLocation(shipment.pickup.location);
  const pickupAt = {
    from: new Date(shipment.pickup.at.from),
    to: new Date(shipment.pickup.at.to),
  };

  const deliveryLocation = convertLocation(shipment.delivery.location);
  const deliveryAt = {
    from: new Date(shipment.delivery.at.from),
    to: new Date(shipment.delivery.at.to),
  };

  const cargo = {
    totalWeight: shipment.cargo.totalWeight,
    totalVolume: shipment.cargo.totalVolume,
    units: shipment.cargo.units.map((unit) => convertUnits(unit)),
  };

  return {
    id: shipment.id,
    name: shipment.name || undefined,
    reference: shipment.reference,
    pickup: { at: pickupAt, location: pickupLocation },
    delivery: { at: deliveryAt, location: deliveryLocation },
    cargo,
    selectedProvider: shipment.provider ? convertProvider(shipment.provider) : undefined,

    // TODO change createdBy to User type
    createdBy: 'user123',
    createdAt: new Date('2022-01-17T14:00:00+01:00'),
  };
}

export type UseShipmentResult = {
  // Null shipment means shipment has not been found
  shipment: Shipment | null,
  isLoading: boolean,
  refetch: () => void,
  cancel: () => void,
  getLables: () => string,
};

function useShipment(id: string): UseShipmentResult {
  const {
    loading: shipmentIsLoading,
    data,
    refetch,
  } = useQuery<GetShipment, GetShipmentVariables>(GET_SHIPMENT, { variables: { id } });
  const [
    cancelShipment,
    { loading: cancelIsLoading },
  ] = useMutation(CANCEL_SHIPMENT, { variables: { id } });

  // eslint-disable-next-line no-console
  console.log(cancelIsLoading);

  // TODO recognize when shipment can't be canceled
  const cancel = () => {
    cancelShipment();
    // Refetch is cheap op, so for now it's easiest way to handle shipment update
    refetch();
  };

  return {
    shipment: data?.shipment ? convertShipment(data.shipment) : null,
    isLoading: shipmentIsLoading,
    refetch: () => refetch(),
    cancel,
    // TODO generate link
    getLables: () => 'https://pdf.balikobot.cz/dhlfreightec/eNqztDA31zWxMDIHXDAJiQHi',
  };
}

export default useShipment;
