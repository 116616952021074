import React from 'react';

export type DateSegmentProps = {
  date: {
    from: string,
    to: string,
  },
  kind: 'departure' | 'arrival',
  show?: boolean,
};

const DateSegment = ({ date, kind, show = true }: DateSegmentProps): React.ReactElement => {
  // const day = date === '' ? 27 : 26;
  // const month = date === '' ? 'Jun' : 'Sep';
  // eslint-disable-next-line no-console
  console.log(date, kind);
  if (!show) return <></>;
  return (
    <div className="md:pl-2 md:pr-6 md:text-center flex items-center md:block">
      <div className="text-sm md:text-xl font-bold">
        fix
        <span className="md:hidden">&nbsp;</span>
      </div>
      <div className="text-sm uppercase">fix</div>
    </div>
  );
};

DateSegment.defaultProps = {
  show: false,
};

export default DateSegment;
