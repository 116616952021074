import React from 'react';
import AsyncSelect from 'react-select/async';
import { SingleValue, StylesConfig } from 'react-select';
import { LocationAccessibility } from '../../../../hooks/forms/types.d.t';

type Address = {
  id: string,
  address: {
    street: string,
    streetNo: string,
    city: string,
    zip: string,
    countryCode: string,
  },
  contact: {
    name: string,
    phone: string,
    email: string,
    company: string,
  },
  type: string,
} & LocationAccessibility;

type GroupedAddresses = {
  label: string,
  options: Address[],
}[];

const addresses: Address[] = [
  {
    id: '1',
    address: {
      street: 'Nadrazni',
      streetNo: '1',
      zip: '60200',
      city: 'Brno',
      countryCode: 'CZE',
    },
    contact: {
      name: 'John Doe',
      phone: '123456789',
      company: 'Example',
      email: 'john.doe@example.com',
    },
    tailgate: true,
    forklift: true,
    type: 'saved',
  },
  {
    id: '2',
    address: {
      street: 'Nadraznii',
      streetNo: '2',
      zip: '60200',
      city: 'Brno',
      countryCode: 'CZE',
    },
    contact: {
      name: 'John Doe',
      phone: '123456789',
      company: 'Example',
      email: 'john.doe@example.com',
    },
    tailgate: true,
    forklift: true,
    type: 'saved',
  },
  {
    id: '3',
    address: {
      street: 'Nadrazni',
      streetNo: '3',
      zip: '60200',
      city: 'Brno',
      countryCode: 'CZE',
    },
    contact: {
      name: 'John Doe',
      phone: '123456789',
      company: 'Example',
      email: 'john.doe@example.com',
    },
    tailgate: true,
    forklift: true,
    type: 'search',
  },
  {
    id: '4',
    address: {
      street: 'Nadrazni',
      streetNo: '4',
      zip: '60200',
      city: 'Brno',
      countryCode: 'CZE',
    },
    contact: {
      name: 'John Doe',
      phone: '123456789',
      company: 'Example',
      email: 'john.doe@example.com',
    },
    tailgate: true,
    forklift: true,
    type: 'search',
  },
];

type IsMulti = false;
const customStyles: StylesConfig<Address, IsMulti> = {
  control: (provided) => ({
    ...provided,
    borderWidth: '1px',
    borderColor: 'rgba(209, 213, 219, 1)',
    borderRadius: '0.375rem',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    padding: '0.1rem 0.2rem 0.1rem 0.2rem',
  }),
};

const groupOptions = (options: Address[]): GroupedAddresses | Address[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const types = [...new Set(options.map((item) => item.type))];
  const grouped: GroupedAddresses = [];
  types.forEach((type) => {
    grouped.push({
      label: type,
      options: options.filter((item) => item.type === type),
    });
  });
  return grouped.length > 1 ? grouped : options;
};

const filterValues = (inputValue: string) => groupOptions(addresses.filter(
  (i) => i.address.street.toLowerCase().includes(inputValue.toLowerCase()),
));

const promiseOptions = (
  inputValue: string,
) => new Promise<Address[] | GroupedAddresses>((resolve) => {
  setTimeout(() => {
    resolve(filterValues(inputValue));
  }, 1000);
});

type LocationAutocompleteFieldProps = {
  onChange: (value: SingleValue<Address>) => void,
  id: string,
};

const LocationAutocompleteField = ({
  onChange,
  id,
}: LocationAutocompleteFieldProps): React.ReactElement => {
  const placeholder = 'Vyhledejte adresu';

  return (
    <AsyncSelect
      inputId={id}
      cacheOptions
      placeholder={placeholder}
      noOptionsMessage={() => 'Žádné výsledky'}
      defaultOptions={groupOptions(addresses)}
      styles={customStyles}
      onChange={(item) => onChange(item)}
      getOptionValue={(option) => option.id}
      formatOptionLabel={(option) => (
        <div className="text-sm cursor-pointer">
          <div className="font-semibold">
            {option.address.street}
            {' '}
            {option.address.streetNo}
            {', '}
            {option.address.city}
            {' '}
            {option.address.zip}
            {' '}
            {option.address.countryCode}
          </div>
          <div>
            {option.contact.company}
            {' '}
            {option.contact.name}
            {', '}
            {option.contact.phone}
            {' '}
            {option.contact.email}
          </div>
        </div>
      )}
      loadOptions={promiseOptions}
    />
  );
};

export default LocationAutocompleteField;
