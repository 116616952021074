import React, { Dispatch, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import RadioSwitcher from './control/RadioSwitcher';
import Units from './cargo/Units';
import Ltl from './cargo/Ltl';
import Ftl from './cargo/Ftl';
import { Errors, FormActions, ShipmentFormData } from '../../../hooks/forms/types.d.t';

type CargoType = {
  id: number,
  title: string,
  description: string,
  disabled: boolean,
};

const cargoTypes: CargoType[] = [
  {
    id: 0, title: 'Konkrétní položky', description: 'Nacenit podle jednotlivých položek', disabled: false,
  },
  {
    id: 1, title: 'Objem a obsah', description: 'Nacenit podle velikosti zboží', disabled: true,
  },
  {
    id: 2, title: 'Celé auto', description: 'Poptejte si celé auto pro váš náklad', disabled: true,
  },
];

type FormCargoProps = {
  dispatch: Dispatch<FormActions>,
  state: ShipmentFormData,
  errors: Errors,
};

const FormCargo = ({ dispatch, state, errors }: FormCargoProps): React.ReactElement => {
  const [type, setType] = useState(0);

  return (
    <>
      <RadioSwitcher
        onChange={(option) => setType(+option.id)}
        options={cargoTypes.map((cargo) => ({
          id: cargo.id.toString(),
          title: cargo.title,
          description: cargo.description,
          disabled: cargo.disabled,
          value: cargo.id.toString(),
          showBadge: cargo.disabled,
          slot: (
            <>
              <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                {cargo.title}
              </RadioGroup.Label>
              <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                {cargo.description}
              </RadioGroup.Description>
            </>
          ),
        }))}
        wrapperClass="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
        defaultIndex={type}
      />

      <div className="my-4 border-t" />

      {type === 0 && <Units dispatch={dispatch} state={state} errors={errors} />}
      {type === 1 && <Ltl dispatch={dispatch} />}
      {type === 2 && <Ftl dispatch={dispatch} />}

    </>
  );
};

export default FormCargo;
