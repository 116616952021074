import { SearchFilters } from '../../components/wrld/types/form';

const filters: SearchFilters = {
  status: {
    values: [
      { count: 2, name: 'CREATED' },
      { count: 18, name: 'PREPARED' },
      { count: 3, name: 'BOOKING_REQUESTED' },
      { count: 1, name: 'BOOKED' },
      { count: 8, name: 'ON_THE_WAY' },
      { count: 8, name: 'DELIVERED' },
      { count: 8, name: 'CANCELED' },
    ],
    defaultValue: [],
  },
  providers: {
    values: [
      { count: 88, name: 'DHL' },
      { count: 12, name: 'PPL' },
    ],
    defaultValue: [],
  },
  pickup: { },
  delivery: { },
};

// const filters: FilterDefinition[] = [
//   {
//     name: 'status',
//     kind: 'CHECKBOX',
//     actionType: 'TOGGLE',
//     values: [
//       { id: 1, name: 'Created' },
//       { id: 2, name: 'Booked' },
//       { id: 3, name: 'Delivered' },
//       { id: 4, name: 'Cancelled' },
//       { id: 5, name: 'Unknown' },
//     ],
//     defaultValue: null,
//   },
//   {
//     name: 'providers',
//     kind: 'CHECKBOX',
//     actionType: 'TOGGLE',
//     values: [
//       { id: 1, name: 'DHL' },
//       { id: 2, name: 'PPL' },
//     ],
//     defaultValue: null,
//   },
//   {
//     name: 'pickup',
//     kind: 'AUTOCOMPLETE',
//     actionType: 'ADD',
//     values: [],
//     defaultValue: null,
//   },
//   {
//     name: 'delivery',
//     kind: 'AUTOCOMPLETE',
//     actionType: 'ADD',
//     values: [],
//     defaultValue: null,
//   },
// ];

export default filters;
