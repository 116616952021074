import React, { MouseEventHandler } from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type ButtonVariant = 'primary' | 'secondary' | 'white';

export interface ButtonPropsBase {
  disabled?: boolean,
}

export interface ButtonProps extends ButtonPropsBase {
  variant?: ButtonVariant,
  onClick?: MouseEventHandler,
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
}

type VariantItem = {
  button: string,
  icon: string,
};

// TODO define disabled style with optional message?
const variantMap: Record<ButtonVariant, VariantItem> = {
  primary: {
    button: 'border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
    icon: '',
  },
  secondary: {
    button: 'border-transparent text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500',
    icon: '',
  },
  white: {
    button: 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500',
    icon: '',
  },
};

const buttonBase = 'inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2';
const iconBase = '-ml-1 mr-2 h-5 w-5';

// TODO switch behavior based on the type
// TODO disable button if callback is undefined
const Button = (props: React.PropsWithChildren<ButtonProps>): React.ReactElement => {
  const {
    children, onClick, Icon, variant, disabled,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const variantProperties = variantMap[variant || 'primary'];

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(buttonBase, variantProperties.button)}
      disabled={disabled || undefined}
    >
      {Icon && (<Icon className={classNames(iconBase, variantProperties.icon)} aria-hidden="true" />)}
      {children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  variant: 'primary',
  onClick: () => {},
  Icon: '',
};

export default Button;
